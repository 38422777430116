import { ErrorMessage, Field, FormikProvider, useFormik,Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from 'react-select';
import Swal from "sweetalert2";
import CommonPost from "../../../../CommonUtils/CommonPost";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { config } from "../../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import allowNumbersOnly, { AADHAR_VALIDATION, IFSC_VALIDATION, PAN_VALIDATION, allowAlphabetAndSpacesOnly } from "../../../../CommonUtils/CommonValidations";
export default function EmployeeEntryFormNew() {
    const [banskDetails,setBankDetails]=useState([])
    const validation = Yup.object().shape({
        empId: Yup.string().required("Please Enter Employee ID").nullable(),
        empName: Yup.string().required("Please Enter Employee Name").nullable(),
        dob: Yup.string().required("Please select Employee Date of Birth").nullable(),
        doj: Yup.string().required("Please select Employee Date of Joining").nullable(),
        gender: Yup.string().required("Please select gender").nullable(),
        email: Yup.string().required("Please Enter Email").nullable(),
        mobileNo: Yup.string().required("Please Enter Mobile No").nullable(),
        zoneId: Yup.string().required("Please select ZoneID").nullable(),
        designationId: Yup.string().required("Please select Designation Id").nullable(),
        roEmpId: Yup.string().required("Please select RO Employee Id").nullable(),
        renewaldate: Yup.string().required("Please select Renewal Date").nullable(),
        category: Yup.string().required("Please select Category").nullable(),
        department: Yup.string().required("Please select Department").nullable(),
        level: Yup.string().required("Please select Level").nullable(),
        projectwise: Yup.string().required("Please select Project Wise").nullable(),
        band: Yup.string().required("Please select Band").nullable(),
        qualification: Yup.string().required("Please select Qualification").nullable(),
        aadhar:Yup.string().min(12).matches(AADHAR_VALIDATION, "Invalid Aadhar Number").nullable(),
        married:Yup.string().required("Required").nullable(),
        pan_no:Yup.string().max(10).matches(PAN_VALIDATION, "Invalid PAN no").nullable(),
        bank_account_number:Yup.string().required("Required").nullable(),
        bankifsccode:Yup.string().required("Required").matches(IFSC_VALIDATION, "Invalid PAN no").nullable(),
    });
    const showEntryInitialValues = {
        empId: "",
        empName: "",
        dob: "",
        doj: "",
        gender: "",
        email: "",
        mobileNo: "",
        zoneId: "",
        designationId: "",
        ioEmpId: "",
        roEmpId: "",
        renewaldate: "",
        category: "",
        department: "",
        level: "",
        projectwise: "",
        toatalexperience: "",
        band: "",
        qualification: "",
        aadhar:"",
        married:"",
        pan_no:"",
        bank_account_number:"",
        bankifsccode:"",
        uan_no:"",
        pf_no:"",
        gpf_cps_no:"",
        apgli_no:"",
        gis_category:"",
        bankname_branchname:banskDetails?.bankName !== "" && banskDetails?.bankName !== undefined && banskDetails?.bankName !== null ? 
             banskDetails?.bankName + "-" + banskDetails?.branchName:null
    };
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: showEntryInitialValues,
        onSubmit: (values) => {EmpEntryFormAlert(values)},
        validationSchema: validation,
    });
    const EmpEntryFormAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                CommonPost.empEntryPostNew(values).then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {window.location.reload();}});}
                        else if (res.data.scode === "02") {Sweetalert(res.data.sdesc, 'warning')}
                        else {Swal.fire('Canceled', 'Something went wrong,Please check', 'error');}
                    }).catch(() => {console.log("Exception Occured 71 ")});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }})
    };
    const [showZoneMaster, setZoneMaster] = useState([]);
    const [showDesignationsData, setDesignationsData] = useState([]);
    const [showCategoryMaster, setCategoryMaster] = useState([]);
    const [showDepartmentMaster, setDepartmentMaster] = useState([]);
    const [showLevelsMaster, setLevelsMaster] = useState([]);
    const [showProjectWiseMaster, setProjectWiseMaster] = useState([]);
    const [showBandMaster, setBandMaster] = useState([]);
    const [showQualificationMaster, setQualificationMaster] = useState([]);
    function GetAllMasters() {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((response) => {
            if (response.data.scode === "01") {setZoneMaster(response.data.data);} else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/categories").then((response) => {
            if (response.data.scode === "01") {
                setCategoryMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/departments").then((response) => {
            if (response.data.scode === "01") {
                setDepartmentMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/levels").then((response) => {
            if (response.data.scode === "01") {
                setLevelsMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/project-wise").then((response) => {
            if (response.data.scode === "01") {
                setProjectWiseMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/bands").then((response) => {
            if (response.data.scode === "01") {
                setBandMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/qualifications").then((response) => {
            if (response.data.scode === "01") {
                setQualificationMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    function Getdesignationsdata(zoneid) {
        if (zoneid != null && zoneid != "" && zoneid != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneid).then((res) => {
                if (res.data.scode === "01") {
                    setDesignationsData(res.data.data)
                }
                else if (res.data.scode === "02") {
                    Sweetalert(res.data.sdesc, 'warning')
                    setDesignationsData([])
                }
            })
        }
    }
    const [showemployeelist, setemployeeList] = useState([]);
    function Getemployeesdata() {
        CommonAxiosGet(config.url.COMMON_URL + "employeedata").then((response) => {
            setemployeeList(response.data.emp_data);
        });
    }
    useEffect(() => {
        Getemployeesdata();
        GetAllMasters();
    }, [])

    const handleAllMasters = (filename, selectedOption, id) => {
        if (selectedOption !== null) {
            formIk.setFieldValue(filename, id)
        }
        else {
            formIk.setFieldValue(filename, "")
        }
    }
    const [showTotalExperienceInMonths, setTotalExperienceInMonths] = useState(0);
    const [showTotalExperienceInYears, setTotalExperienceInYears] = useState(0);
    function calculateTotalExperience(doj) {
        const currentFormattedDate = new Date().toISOString().split('T')[0].split('-');
        const dojFormattedDate = new Date(doj).toISOString().split('T')[0].split('-');
        setTotalExperienceInYears((parseInt(currentFormattedDate[0]) - parseInt(dojFormattedDate[0])))
        setTotalExperienceInMonths((parseInt(currentFormattedDate[1]) - parseInt(dojFormattedDate[1])))
    }
   
    const currentDate = new Date();
    const currentFormattedDate = currentDate.toISOString().split('T')[0];
    function fetchBankDetails(ifscCode) {
        if (ifscCode == undefined || ifscCode == "") {
          setBankDetails([]);
        } else {
          let banksUrl = config.url.BANK_URL + "/auth/getRbibanksDetails?ifscCode=" + ifscCode;
          CommonAxiosGet(banksUrl).then((response) => {
            console.log("response>>>>>>>>>",response)
            if (response !== undefined) {
              setBankDetails(response.data[0]);
            } else {
              Swal.fire('', 'Something went Wrong', 'error');
              setBankDetails('');
            }
          });
        }
      }
      function clearbankName(){
        formIk.setFieldValue("bankName", "");
      }

    
      const navigate = useNavigate()
      function NavigateToDashboard(){
        navigate("/employeedashboard")
      }
      function clearFunction(value){
        console.log("value>>>>>>>",value,"typeof",typeof(value));
        if(value === 5){
          
            formIk.setFieldValue("gpf_cps_no", "");
            formIk.setFieldValue("apgli_no", "");
            formIk.setFieldValue("gis_category", "");
        }
        else if(value ===1){
           
            formIk.setFieldValue("uan_no", "");
            formIk.setFieldValue("pf_no", "");
        }
        
    }
    console.log("showemployeelist",showemployeelist)
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee Entry Form</li>
                </ol>
            </div>
        </div>
        
        <jnb.Row className="pt-0">
        <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
        <div className="head-text  mt-4"> <h1> Employee Entry Form </h1> </div></jnb.Col>
           
                  <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} >
                  <button type="button" class="btn btn-success  btn-sm float-end mt-4"
                   onClick={NavigateToDashboard}>Back</button>
        </jnb.Col>
              
      
        </jnb.Row>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
            <div className="border px-3 pt-4">
                <jnb.Row className="px-3 pt-2">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee ID:<font style={{ color: "red" }}>*</font></b></span>
                            <Field type="text" name="empId" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }} maxLength="8" />
                            <ErrorMessage name="empId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee Name:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="empName" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            <ErrorMessage name="empName" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style" maxLength="12"><b>Aadhar: </b></span>
                            <Field type="text" name="aadhar" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }} maxLength="12"/>
                            <ErrorMessage name="aadhar" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>DOB:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="date" name="dob" className="form-control"
                                max={currentFormattedDate} />
                            <ErrorMessage name="dob" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>DOJ:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="date" name="doj" className="form-control"
                                onChange={(e) => { calculateTotalExperience(e.target.value) }}
                                max={currentFormattedDate} />
                            <ErrorMessage name="doj" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Gender:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="gender" value="M" />
                            &nbsp;Male  &nbsp;
                            <Field type="radio" name="gender" value="F" />
                            &nbsp;Female
                            <ErrorMessage name="gender" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Married:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="married" value="true" />
                            &nbsp;Yes  &nbsp;
                            <Field type="radio" name="married" value="false" />
                            &nbsp;No
                            <ErrorMessage name="married" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Email:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="email" name="email" className="form-control" />
                            <ErrorMessage name="email" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Mobile No:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="mobileNo" className="form-control" maxLength="10" onKeyPress={(e) => { allowNumbersOnly(e); }} />
                            <ErrorMessage name="mobileNo" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Pan No:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="pan_no" className="form-control" maxLength="10" 
                             onChange={(e) => {
                                e.target.value = e.target.value.toUpperCase(); 
                            }}/>
                            <ErrorMessage name="pan_no" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Bank A/c Number:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="bank_account_number" className="form-control"/>
                            <ErrorMessage name="bank_account_number" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                  <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style">
                      <b>IFSC Code:<font style={{ color: "red" }}>*</font></b></span>
                    <Field type="text" className="form-control" name="bankifsccode" maxLength="11"
                      onChange={(e) => { if (e?.target?.value?.length === 11) { fetchBankDetails(e?.target?.value); } }}
                      onKeyUp={(e) => { formIk.setFieldValue("bankifsccode", e?.target?.value?.toUpperCase()); }}
                      onClick={() => { clearbankName(); }}
                    />
                    <ErrorMessage name="bankifsccode" component="div" className="text-error" />
                  </jnb.InputGroup>
                </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                  <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style">
                      <b>Bank Name & Branch</b>
                    </span>
                    <Field  type="hidden" name="bankname_branchname"/>
                    {banskDetails?.bankName !== "" && banskDetails?.bankName !== undefined && banskDetails?.bankName !== null ? (<> 
                    {banskDetails?.bankName + "-" + banskDetails?.branchName}</>) : null}
                  </jnb.InputGroup>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Category of Employement:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="category" isSearchable={true} isClearable={true}
                                options={showCategoryMaster}
                                getOptionLabel={option => option.category_name} getOptionValue={option => option.category_id}
                                value={showCategoryMaster?.find(it => it?.category_id === formIk?.values?.category) || null}
                                onChange={(selectedOption) => { handleAllMasters("category", selectedOption, selectedOption?.category_id);
                                clearFunction(selectedOption?.category_id) }}
                            />
                            <ErrorMessage name="category" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                  {formIk.values?.category === 5 ? (<>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>GPF/CPS No.: </b></span>
                            <Field type="text" name="gpf_cps_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>APGLI No:</b></span>
                            <Field type="text" name="apgli_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>GIS Category:</b></span>
                            <Field type="text" name="gis_category" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                </>):(<></>)}
                {formIk.values?.category === 1 ?(<>
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>UAN No: </b></span>
                            <Field type="text" name="uan_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>

                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>PF No:</b></span>
                            <Field type="text" name="pf_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    </>):null}
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Total Experience in APCFSS:<font style={{ color: "red" }}>*</font> </b></span>
                            <span><b style={{ color: "darkgreen" }}>{showTotalExperienceInYears} Years {showTotalExperienceInMonths}  Months</b></span>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b> Renewal Date:</b></span>
                            <Field type="date" name="renewaldate" className="form-control" />
                            <ErrorMessage name="renewaldate" component="div" className="text-error" />

                        </jnb.InputGroup>
                    </jnb.Col>
                   
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Zone:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="zoneId" isSearchable={true} isClearable={true}
                                options={showZoneMaster}
                                getOptionLabel={option => option.zone_name} getOptionValue={option => option.zone_id}
                                value={showZoneMaster?.find(it => it?.zone_id === formIk?.values?.zoneId) || null}
                                onChange={(selectedOption) => {
                                    if (selectedOption !== null) {
                                        formIk.setFieldValue("zoneId", selectedOption.zone_id)
                                        formIk.setFieldValue("designationId", "")
                                        Getdesignationsdata(selectedOption.zone_id);}
                                    else {formIk.setFieldValue("zoneId", "")}}}/>
                            <ErrorMessage name="zoneId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Designation:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="designationId" isSearchable={true} isClearable={true}
                                options={showDesignationsData}
                                getOptionLabel={option => option.designation_name} getOptionValue={option => option.designation_id}
                                value={showDesignationsData?.find(it => it?.designation_id === formIk?.values?.designationId) || null}
                                onChange={(selectedOption) => { handleAllMasters("designationId", selectedOption, selectedOption?.designation_id) }}
                            />
                            <ErrorMessage name="designationId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>RO Emp:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="roEmpId" isSearchable={true} isClearable={true}
                                options={showemployeelist}
                                getOptionLabel={option => option.emp_id + "-" + option.emp_name} getOptionValue={option => option.emp_id}
                                value={showemployeelist?.find(it => it?.emp_id === formIk?.values?.roEmpId) || null}
                                onChange={(selectedOption) => { handleAllMasters("roEmpId", selectedOption, selectedOption?.emp_id) }}
                            />
                            <ErrorMessage name="roEmpId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Department:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="department" isSearchable={true} isClearable={true}
                                options={showDepartmentMaster}
                                getOptionLabel={option => option.department_name} getOptionValue={option => option.department_id}
                                value={showDepartmentMaster?.find(it => it?.department_id === formIk?.values?.department) || null}
                                onChange={(selectedOption) => { handleAllMasters("department", selectedOption, selectedOption?.department_id) }}
                            />
                            <ErrorMessage name="department" component="div" className="text-error" />
                        </jnb.InputGroup>

                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Level:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="level" isSearchable={true} isClearable={true}
                                options={showLevelsMaster}
                                getOptionLabel={option => option.level_name} getOptionValue={option => option.level_id}
                                value={showLevelsMaster?.find(it => it?.level_id === formIk?.values?.level) || null}
                                onChange={(selectedOption) => { handleAllMasters("level", selectedOption, selectedOption?.level_id) }}
                            />
                            <ErrorMessage name="level" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>ProjectWise:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="projectwise" isSearchable={true} isClearable={true}
                                options={showProjectWiseMaster}
                                getOptionLabel={option => option.project_name} getOptionValue={option => option.project_id}
                                value={showProjectWiseMaster?.find(it => it?.project_id === formIk?.values?.projectwise) || null}
                                onChange={(selectedOption) => { handleAllMasters("projectwise", selectedOption, selectedOption?.project_id) }}
                            />
                            <ErrorMessage name="projectwise" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Band:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="band" isSearchable={true} isClearable={true}
                                options={showBandMaster}
                                getOptionLabel={option => option.band_name} getOptionValue={option => option.band_id}
                                value={showBandMaster?.find(it => it?.band_id === formIk?.values?.band) || null}
                                onChange={(selectedOption) => { handleAllMasters("band", selectedOption, selectedOption?.band_id) }}
                            />
                            <ErrorMessage name="band" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Qualification:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="qualification" isSearchable={true} isClearable={true}
                                options={showQualificationMaster}
                                getOptionLabel={option => option.qualification_name} getOptionValue={option => option.qualification_id}
                                value={showQualificationMaster?.find(it => it?.qualification_id === formIk?.values?.qualification) || null}
                                onChange={(selectedOption) => { handleAllMasters("qualification", selectedOption, selectedOption?.qualification_id) }}/>
                            <ErrorMessage name="qualification" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-1">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ float: "right" }}>
                        <button type="submit" className="btn rounded-0 btn-success btn-sm mb-3 float-end" >Submit</button>
                    </jnb.Col>
                </jnb.Row>
                </div>
            </Form>
        </FormikProvider>
    </>)
}