import { FormikProvider, useFormik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import moment from 'moment';

export default function EmpPreviousHystoryDetails() {
    const [GetEmpPreviousLeaveDetails, setEmpPreviousLeaveDetails] = useState()
    const employeeIdEmpPrevious = localStorage.getItem("employee_id")
    const backbuttonEmpPrevious = localStorage.getItem("backbutton");

    const [showErrMsgEmpPrevious, setErrMsgEmpPrevious] = useState(false)
    const [searchEmpPrevious, setSearchEmpPrevious] = useState("")

    const navigateEmpPrevious = useNavigate();
    const EmpPreviousformIk = useFormik({
        enableReinitialize: true,
        initialValues: {typeofleave:""},
        onSubmit: (values) => { console.log(null)},
    });
    function GetEmpPreviousLeaveHistory() {
        let Url = (config.url.COMMON_URL + "leavesdataFrs/" + employeeIdEmpPrevious);
        CommonAxiosGet(Url).then((resAtempPrevious) => {
            if (resAtempPrevious?.data?.leavesdataFrsEmpStatus === true) {
                setEmpPreviousLeaveDetails(resAtempPrevious?.data?.leavesdataFrsEmp);
                setErrMsgEmpPrevious(false)
            }
            else {
                setEmpPreviousLeaveDetails([]);
                setErrMsgEmpPrevious(true)
            }
        })
    }

    const fetchDataEmpPrevious = useRef(false)
    useEffect(() => {
        if (!fetchDataEmpPrevious.current) {
            GetEmpPreviousLeaveHistory()
            fetchDataEmpPrevious.current = true
        }
    }, []);


    function navigateToReport() {
        if (backbuttonEmpPrevious === "RequestApproveOrRejectApplication") {navigateEmpPrevious("/detailsofleaverequests")}
        else if (backbuttonEmpPrevious === "RO") {navigateEmpPrevious("/detailsofleaveapplications")}
        else {navigateEmpPrevious("/AprroveOrRejectDataHistory")}
    }
    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leave Approval</li>
                </ol>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className=" pt-2">
            <jnb.Row className="m-0 pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                    <div className="head-text" style={{ display: "inline" }}>
                        <h1> Leave Status Report of {localStorage.getItem("employee_name")} </h1> </div></jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pe-0">
                    <button type="button" className="bg-success p-2 text-white  btn-sm  " style={{ float: "right" }}
                        onClick={() => { navigateToReport() }}>Back</button>
                </jnb.Col>
            </jnb.Row>
            <FormikProvider value={EmpPreviousformIk}>
                <Form onSubmit={EmpPreviousformIk.handleSubmit} onChange={EmpPreviousformIk.handleChange}>
                    {GetEmpPreviousLeaveDetails !== undefined && GetEmpPreviousLeaveDetails?.length > 0 && GetEmpPreviousLeaveDetails !== "No data found" ? (
                        <jnb.Row className="m-0 pt-2">
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup>
                                    <Field as="select" className="form-control mb-2" name="typeofleave" onChange={event => setSearchEmpPrevious(event?.target?.value)}>
                                        <option value="">--Search here--</option>
                                        <option value="Leave">Leave</option>
                                        <option value="Movement">Regularization</option>
                                        <option value="WFH">Work From Home</option>
                                    </Field>
                                </jnb.InputGroup>
                            </jnb.Col>
                        </jnb.Row>
                    ) :""}
                    <jnb.Row className="px-3 pt-2">
                        {GetEmpPreviousLeaveDetails !== undefined && GetEmpPreviousLeaveDetails?.length > 0 && GetEmpPreviousLeaveDetails !== "No data found" ? (<>
                            <table className="table  table-bordered  table-responsive leaveapproval-table">
                                <thead>
                                    <tr>
                                        <th align="center">S.No</th>
                                        <th>Leave Request</th>
                                        <th>Details of <br></br>Leaves for Apply</th>
                                        <th>Type of Leave</th>
                                        <th>Visiting Office Name - Remarks</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                {GetEmpPreviousLeaveDetails && GetEmpPreviousLeaveDetails !== undefined && GetEmpPreviousLeaveDetails?.filter((elementatprevious) => {
                                        const typeOfLeaveAtprevious = elementatprevious?.type_of_leave?.toLowerCase() === "movement" ? "regularization" : elementatprevious?.type_of_leave?.toLowerCase();
                                        return (
                                            typeOfLeaveAtprevious?.includes(searchEmpPrevious?.toLowerCase()) ||
                                            elementatprevious?.from_date?.toString().toLowerCase().includes(searchEmpPrevious?.toLowerCase()) ||
                                            elementatprevious?.to_date?.toString().toLowerCase().includes(searchEmpPrevious?.toLowerCase()) ||
                                            elementatprevious?.type_name?.toLowerCase().includes(searchEmpPrevious?.toLowerCase())
                                        );
                                    })?.map((dataAtempprevious, emppreviousIndex) => {
                                        const rowClassNameAtempleavehistory = dataAtempprevious?.type_of_leave === "Leave"
                                        ? 'RowColorForLeave'
                                        : dataAtempprevious?.type_of_leave === "Movement"
                                            ? 'RowColorForMovement'
                                            : dataAtempprevious?.type_of_leave === "WFH"
                                                ? 'RowColorForWEH'
                                                : '';
                                        return (<React.Fragment key={emppreviousIndex}>
                                                <tr
                                                    className={rowClassNameAtempleavehistory}>
                                                    <td>{emppreviousIndex + 1}</td>
                                                    <td>{dataAtempprevious?.type_of_leave === "Movement" ? "Regularization" : <>{dataAtempprevious?.type_of_leave}</>}</td>
                                                    <td>
                                                        {dataAtempprevious?.no_of_days !== "" ? (<>
                                                            {dataAtempprevious?.no_of_days === "One Day" && (<>
                                                                {moment(dataAtempprevious?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                            </>)}{dataAtempprevious?.no_of_days === "More Than One Day" && (<>
                                                                {moment(dataAtempprevious?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                                &nbsp;to&nbsp;
                                                                {moment(dataAtempprevious?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}      </>)}
                                                            {dataAtempprevious?.no_of_days === "One Half Day" && (<>
                                                                {moment(dataAtempprevious?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                                ({dataAtempprevious?.halfday_from_time_to_time})</>)}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </>) : null}
                                                    </td>
                                                    <td>{dataAtempprevious?.type_name === "Movement" ? <>Regularization</> : <>{dataAtempprevious?.type_name}</>}</td>
                                                    <td>
                                                        {(dataAtempprevious?.type_of_leave === "Leave" || dataAtempprevious?.type_of_leave === "WFH" || dataAtempprevious?.type_of_leave === "Movement") ? (<>
                                                            <td>{dataAtempprevious?.office_name}<pre></pre>{dataAtempprevious?.remarks}</td>
                                                        </>) : (<td></td>)}
                                                    </td>
                                                    <td>
                                                        {dataAtempprevious?.leave_final_status !== null ? (<>
                                                            {dataAtempprevious?.leave_final_status === "Approved" && (<>
                                                                <b style={{ color: "green" }}>Approved</b></>)}
                                                            {dataAtempprevious?.leave_final_status === "Rejected" && (<><b style={{ color: "red" }}>Rejected</b>&nbsp;-<b>{dataAtempprevious?.leave_status_remakrs}</b></>)}
                                                            {dataAtempprevious?.leave_final_status === "pending" && (<><b >  Pending for Approval</b> </>)}
                                                        </>) : null}
                                                    </td>
                                                </tr>
                                                </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : 
                            (showErrMsgEmpPrevious && (<center className="mt-5"><b style={{ color: "red" }}>*********No Data Found*********</b></center>))}
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}
