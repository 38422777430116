import { FormikProvider, useFormik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BsEye } from "react-icons/bs";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import moment from 'moment';
export default function AprroveOrRejectDataHistory() {
    const logindetails = useSelector((state) => state?.reducers?.loginreducer?.userLoginDetials);
    const [GetDetails, setDetails] = useState([]);
    const [showErrMsg, setErrMsg] = useState(false);
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: { searchbyid: "" },
        onSubmit: (values) => { console.log(null)},
    });
    const navigate = useNavigate()
    function navigateToReport() {
        navigate("/detailsofleaverequests")
    }

    function GetData() {
        let Url = (config.url.COMMON_URL + "leavesdataFrsRoscreen/" + logindetails?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res?.data?.leavesdataFrsReportingOfficerStatus === true) {
                const filterdata = res?.data?.leavesdataFrsReportingOfficer?.filter((status) => status?.leave_final_status !== "pending")
                setDetails(filterdata)
                setErrMsg(false)
            }

            else {
                setErrMsg(true)
                setDetails([])
            }
        })
    }
    const fetchData = useRef(false);
    useEffect(() => {
        if (!fetchData.current) {
            GetData();
            fetchData.current = true
        }
    }, []);
    function navigateToHistory(empid, empname) {
        navigate("/EmpPreviousHystoryDetails")
        localStorage.setItem("employee_id", empid);
        localStorage.setItem("employee_name", empname);
        localStorage.setItem("backbutton", "AprroveOrRejectDataAllEmpHistory");
    }
    
    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leave Approval</li>
                </ol>
            </jnb.Col>
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>

                    <jnb.Row className="px-3 pt-1">
                        {GetDetails?.length > 0 && GetDetails !== "No data found" ? (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup>
                                    <Field as="select" className="form-control mb-2" name="typeofleave" onChange={event => {formIk.setFieldValue("searchbyid", "")}}>
                                        <option value="">--Search here--</option>
                                        <option value="Leave">Leave</option>
                                        <option value="Movement">Regularization</option>
                                        <option value="WFH">Work From Home</option>
                                    </Field>
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                    <Field name="searchbyid"
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Search by Employee ID"
                                    />
                                </jnb.InputGroup>
                            </jnb.Col>
                        </>) : <></>}
                        <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                            <button type="button" class="btn btn-secondary  btn-sm float-end bg-opacity-50" onClick={() => { navigateToReport() }}>Back</button>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-2">
                        {GetDetails?.length > 0 && GetDetails !== "No data found" ? (<>
                            <table className="table  table-bordered  table-responsive leaveapproval-table">
                                <thead>
                                    <tr>
                                        <th align="center">S.No</th>
                                        <th >ID</th>
                                        <th>Name</th>
                                        <th>Type of Leave</th>
                                        <th>Details of <br></br>Leaves for Apply</th>
                                        <th>Leave Request</th>
                                        <th>Visiting Office Name - <br />Remarks</th>
                                        <th>Approve/Rejected</th>
                                        <th>Previous <br />Data History</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {GetDetails && GetDetails !== undefined &&GetDetails?.filter((element) => {
                    const typeOfLeave = element?.type_of_leave?.toLowerCase() === "Movement" ? "regularization" : element?.type_of_leave?.toLowerCase();
                    const selectedLeaveType = formIk?.values?.typeofleave?.toLowerCase();
                    const empIdSearch = formIk?.values?.searchbyid?.toLowerCase();
                    const matchesType = selectedLeaveType ? typeOfLeave?.includes(selectedLeaveType) : true;
                    const matchesEmpId = empIdSearch ? element?.emp_id?.toString()?.toLowerCase()?.includes(empIdSearch) : true;
                    const matchesDateOrTypeName =
                        element?.from_date?.toString()?.toLowerCase()?.includes(empIdSearch) ||
                        element?.to_date?.toString()?.toLowerCase()?.includes(empIdSearch) ||
                        element?.type_name?.toLowerCase()?.includes(empIdSearch) ||
                        element?.halfday_from_time_to_time?.toLowerCase()?.includes(empIdSearch);
                    return matchesType && (matchesEmpId || matchesDateOrTypeName);
                })?.length > 0 ? (
                    GetDetails?.filter((element21) => {
                        const typeOfLeave = element21?.type_of_leave?.toLowerCase() === "Movement" ? "regularization" : element21?.type_of_leave?.toLowerCase();
                        const selectedLeaveType = formIk?.values?.typeofleave?.toLowerCase();
                        const empIdSearch = formIk?.values?.searchbyid?.toLowerCase();
                        const matchesType = selectedLeaveType ? typeOfLeave?.includes(selectedLeaveType) : true;
                        const matchesEmpId = empIdSearch ? element21?.emp_id?.toString()?.toLowerCase()?.includes(empIdSearch) : true;
                        const matchesDateOrTypeName =
                            element21?.from_date?.toString()?.toLowerCase()?.includes(empIdSearch) ||
                            element21?.to_date?.toString()?.toLowerCase()?.includes(empIdSearch) ||
                            element21?.type_name?.toLowerCase()?.includes(empIdSearch) ||
                            element21?.halfday_from_time_to_time?.toLowerCase()?.includes(empIdSearch);
                        return matchesType && (matchesEmpId || matchesDateOrTypeName);
                    })?.map((data, i) => {
                        const rowClassNameAtleavehistory = data?.type_of_leave === "Leave"
                        ? 'RowColorForLeave'
                        : data?.type_of_leave === "Movement"
                            ? 'RowColorForMovement'
                            : data?.type_of_leave === "WFH"
                                ? 'RowColorForWEH'
                                : '';
                        return (
                        <React.Fragment key={i}>
                                                <tr className={rowClassNameAtleavehistory}>
                                                    <td>{i + 1}</td>
                                                    <td>{data?.emp_id}</td>
                                                    <td>{data?.emp_name}</td>
                                                    <td>{data?.type_of_leave === "Movement" ? <>Regularization</> : <>{data?.type_of_leave}</>}</td>
                                                    <td>
                                                        {data?.no_of_days !== "" ? (<>
                                                            {data?.no_of_days === "One Day" && (<>
                                                                {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                            </>)}{data?.no_of_days === "More Than One Day" && (<>
                                                                {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                                &nbsp;to&nbsp;
                                                                {moment(data?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}     </>)}
                                                            {data?.no_of_days === "One Half Day" && (<>
                                                                {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} ({data?.halfday_from_time_to_time})</>)}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </>) : (<>
                                                        </>)}
                                                    </td>
                                                    <td>{data?.type_name === "Movement" ? <>Regularization</> : <>{data?.type_name}</>}</td>
                                                    <td>
                                                        {(data?.type_of_leave === "Leave" || data?.type_of_leave === "WFH" || data?.type_of_leave === "Movement") ? (<>
                                                            <td>{data?.office_name}<pre></pre>{data?.remarks}</td>
                                                        </>) : (<td></td>)}
                                                    </td>
                                                    <td>
                                                        {data?.leave_final_status !== null ? (<>
                                                            {data?.leave_final_status === "Approved" && (<><b style={{ color: "green" }}>Approved</b></>)}
                                                            {data?.leave_final_status === "Rejected" && (<><b style={{ color: "red" }}>Rejected</b>&nbsp;-<b>{data?.leave_status_remakrs}</b></>)}
                                                        </>) : (<></>)}</td>
                                                    <td className="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" onClick={(e) => { navigateToHistory(data?.emp_id, data?.emp_name) }}><BsEye /></button>
                                                    </td>
                                                </tr>
                                        </React.Fragment>
                    )})
                ) : (
                    <tr>
                        <td colSpan="9" className="text-center text-danger"><b style={{ color: "red" }}>No data found</b></td>
                    </tr>
                )}
                                </tbody>
                            </table>
                        </>) : (showErrMsg && (<center><b style={{ color: "red" }}>*********No Data Found*********</b></center>))}
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}


