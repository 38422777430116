import { FormikProvider, useFormik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { CommonAxiosGet } from "../../../../../CommonUtils/CommonAxios";
import { config } from "../../../../../CommonUtils/CommonApis";
import Swal from "sweetalert2";
import Sweetalert, { SweetalertOKFunction } from "../../../../../CommonUtils/SweetAlerts";
import CommonPost from "../../../../../CommonUtils/CommonPost"
export default function PayslipGenerater() {
    const [searchAtPayslip, setSearchAtPayslip] = useState("");
    const [GetDetailsAtPayslip, setDetailsAtPayslip] = useState([])

    const currentdateAtpayslip = new Date()
    const monthNamesAtpayslip = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = currentdateAtpayslip?.getMonth();
    const currentMonthpayslip = monthNamesAtpayslip[currentMonthIndex]
    const currentYearpayslip = currentdateAtpayslip?.getFullYear()
    const payslipGenareteformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_id: "",
            paygeneraion: []
        },
        onSubmit: (values) => {
            console.log("submit values", values)
                Swal.fire({
                    title: 'Are you sure you want to submit?',
                    text: 'Please check it once before submitting',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        CommonPost.savepaygeneation(values).then((resslipgenetare) => {
                                if (resslipgenetare?.data?.ResponseCode === "01") {
                                    SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                        if (isConfirm.value) {console.log(null)}
                                    })
                                }
                                else if (resslipgenetare?.data?.ResponseCode === "04") {
                                    Sweetalert(resslipgenetare?.data?.ResponseDesc, 'warning');
                                }
                                else if (resslipgenetare?.data?.ResponseCode === "02") {
                                    Sweetalert(resslipgenetare?.data?.ResponseDesc, 'warning');
                                }
                                else {
                                    Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                                }
                            })
                            .catch(() => {console.log("Exception Occured 71 ");
                            });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                    }
                })
        },
    });
   
    useEffect(() => {
        if (payslipGenareteformIk?.values?.category_id) {
            GetData(payslipGenareteformIk?.values?.category_id);
        }
    }, [payslipGenareteformIk?.values?.category_id]);

    function GetData(category_id_slipgenerate) {
        if (category_id_slipgenerate === "" || category_id_slipgenerate === undefined || category_id_slipgenerate === null) {
            setDetailsAtPayslip([]);
        } else {
            let Url = config.url.COMMON_URL + "employeepaydetailsreportregular/" + category_id_slipgenerate + "/updated";
            CommonAxiosGet(Url).then((resslipgnrate) => {
                if (resslipgnrate?.data?.ResponseCode === "01") {
                    setDetailsAtPayslip(resslipgnrate?.data?.employeepaydata);
                    const paygeneration = resslipgnrate?.data?.employeepaydata?.map((dataslipgenaretr) => {
                        const total_earnings = calculateTotalEarnings(dataslipgenaretr);
                        const total_deductionsslip = calculateTotalDeductions(dataslipgenaretr);
                        const net_pay = total_earnings - total_deductionsslip;
                        return {
                            emp_id: dataslipgenaretr?.emp_id,
                            desg_id: dataslipgenaretr?.designation_id,
                            currentmonth: currentMonthpayslip,
                            currentyear: currentYearpayslip,
                            total_earnings: total_earnings,
                            total_deductions: total_deductionsslip,
                            net_pay: net_pay
                        };
                    });
                    payslipGenareteformIk.setFieldValue("paygeneration", paygeneration);
                } else {
                    setDetailsAtPayslip([]);
                }
            });
        }
    }

    function calculateTotalEarnings(dataearnings) {
        return (dataearnings?.basic_pay +dataearnings?.hra +dataearnings?.flexible_allowance +dataearnings?.incentive_bonus);
    }

    function calculateTotalDeductions(datadeductions) {
        return (datadeductions?.employee_esic_contribution +datadeductions?.employee_epf_contribution +datadeductions?.employer_contribution_esic +
            datadeductions?.employer_contribution_epf +datadeductions?.professional_tax);
    }
   
    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Pay Details</li>
                </ol>
            </jnb.Col>
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <FormikProvider value={payslipGenareteformIk}>
                <Form onSubmit={payslipGenareteformIk.handleSubmit} onChange={payslipGenareteformIk.handleChange} autoComplete="off">
                    <jnb.Row className="m-0 pt-4">
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Category:<font style={{ color: "red" }}>*</font></b></span>
                                <Field as="select" name="category_id" className="form-control" onChange={(e) => { GetData(e?.target?.value) }}>
                                    <option value="">--Select--</option>
                                    <option value="1">Regular</option>
                                    {/* <option value="2">Contract</option>
                                <option value="3">Government</option>
                                <option value="4">Intern</option> */}
                                </Field>
                            </jnb.InputGroup>
                        </jnb.Col>   <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}></jnb.Col>
                        {payslipGenareteformIk?.values?.category_id !== "" ? (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup>
                                    <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off"
                                        onChange={event => setSearchAtPayslip(event?.target?.value)} />
                                </jnb.InputGroup>
                            </jnb.Col>
                        </>) : (<></>)}
                    </jnb.Row>
                    {payslipGenareteformIk?.values?.category_id !== "" ? (<>
                        <jnb.Row className="m-0 pt-1">
                            <jnb.Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <div className="head-text" style={{ display: "inline" }}>
                                    <h1>Pay Generation Details of  {`${currentMonthpayslip}`} {`${currentYearpayslip}`}</h1> </div></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}></jnb.Col>
                        </jnb.Row>
                        <jnb.Row className="px-3">
                            <div className="table-responsive">
                                <table className="table  table-bordered  table-responsive" style={{border:"3px solid lightblue"}}>
                                    <thead>
                                        <tr>
                                            <th width="15px" rowSpan={2}>S.No</th>
                                            <th width="200px" rowSpan={2}>ID (Name)</th>
                                            <th width="125px" rowSpan={2}>DOJ</th>
                                            <th width="200px" rowSpan={2}>Email </th>
                                            <th width="200px" rowSpan={2}>Designation</th>
                                            <th width="60px" rowSpan={2}>Band </th>
                                            <th width="70px" rowSpan={2}>Monthly Gross</th>
                                            <th width="70px" rowSpan={2}>Incentive / Bonus</th>
                                            <th colSpan={3}> Earnings </th>
                                            <th rowSpan={2}>Total </th>
                                            <th colSpan={5}>Deductions</th>
                                            <th rowSpan={2}>Total</th>
                                            <th rowSpan={2}>Net Pay</th>
                                        </tr>
                                        <tr>
                                            <th width="70px" rowSpan={2}>Basic Pay </th>
                                            <th width="70px" rowSpan={2}>HRA</th>
                                            <th width="70px" rowSpan={2}>FA</th>
                                            <th width="70px">Employee-ESIC </th>
                                            <th width="70px">Employee-EPF </th>
                                            <th width="70px">Employer- ESIC </th>
                                            <th width="70px">Employer-EPF </th>
                                            <th width="70px" rowSpan={2}>Professional Tax</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {GetDetailsAtPayslip && GetDetailsAtPayslip?.filter((elementslip) =>
                                            elementslip?.emp_name?.toLowerCase().includes(searchAtPayslip?.toLowerCase()) ||
                                            elementslip?.emp_id?.toString().toLowerCase().includes(searchAtPayslip?.toLowerCase())
                                        )?.map((dataslip, slipIndex) => {
                                            const total_earnings_slip = dataslip?.basic_pay + dataslip?.hra + dataslip?.flexible_allowance + dataslip?.incentive_bonus;
                                            const total_deductions_slip = dataslip?.employee_esic_contribution + dataslip?.employee_epf_contribution + dataslip?.employer_contribution_esic +
                                                dataslip?.employer_contribution_epf + dataslip?.professional_tax;
                                            const net_pay_slip = total_earnings_slip - total_deductions_slip;
                                            return (
                                                <tr key={slipIndex}>
                                                    <td>{slipIndex + 1}</td>
                                                    <td>
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].emp_id`} />
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].desg_id`} />
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].currentmonth`}/>
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].currentyear`} />
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].total_earnings`} />
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].total_deductions`} />
                                                        <Field type="hidden" className="form-control" name={`paygeneration[${slipIndex}].net_pay`} />
                                                        {dataslip?.emp_id} ({dataslip?.emp_name})
                                                    </td>
                                                    <td>{dataslip?.doj}</td>
                                                    <td>{dataslip?.email}</td>
                                                    <td>{dataslip?.designation_name}</td>
                                                    <td>{dataslip?.band}</td>
                                                    <td>{dataslip?.monthly_gross}</td>
                                                    <td>{dataslip?.basic_pay}</td>
                                                    <td>{dataslip?.hra}</td>
                                                    <td>{dataslip?.flexible_allowance}</td>
                                                    <td>{dataslip?.incentive_bonus}</td>
                                                    <td>{total_earnings_slip}</td>
                                                    <td>{dataslip?.employee_esic_contribution}</td>
                                                    <td>{dataslip?.employee_epf_contribution}</td>
                                                    <td>{dataslip?.employer_contribution_esic}</td>
                                                    <td>{dataslip?.employer_contribution_epf}</td>
                                                    <td>{dataslip?.professional_tax}</td>
                                                    <td>{total_deductions_slip}</td>
                                                    <td>{net_pay_slip}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </jnb.Row>
                        <jnb.Row className="pt-2 m-0">
                            <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4">Save</button>
                            </div>
                        </jnb.Row>
                    </>) : (<></>)}
                </Form>
            </FormikProvider>

        </jnb.Row>
    </>)
}

