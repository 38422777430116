

const loginInt = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    userLoginDetials:{},
}
function LoginReducer(state = loginInt, action) {

    switch (action.type) {
        case "LOGIN_DETAILS": {
            const { isLoggedIn, user, token, userLoginDetials } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                token,
                user,
                userLoginDetials
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: ''
            };
        }
        default:
            return state;
    }

}
export default LoginReducer;
