import { ErrorMessage, Field, FormikProvider, useFormik } from "formik"
import "../LoginPage/hrms.css"
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import CommonPost from "../../../CommonUtils/CommonPost";
import { useState } from "react";
import Container from 'react-bootstrap/Container';
import allowNumbersOnly from "../../../CommonUtils/CommonValidations";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import preval from "preval.macro";
import CarouselComponent from "../CommonPages/CarouselComponent";
export default function HrmsLoginPage() {
    const navigate = useNavigate();
    const userValidationSchema = Yup.object().shape({
        userId: Yup.string().required("Please enter username"),
        userPassword: Yup.string().required("Please enter password"),
    });
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showloginpage, setLoginPage] = useState(true)
    const [showForgotpage, setForgotPage] = useState(false)
    const [showTypeSave, setTypeSave] = useState("");
    const dispatch = useDispatch();
    const formIk = useFormik({
        initialValues: {
            userId: "",
            userPassword: "",
        },
        validationSchema: userValidationSchema,
        onSubmit: (values) => {
            let params = { "username": values.userId, "password": values.userPassword }
            CommonPost.LoginPost(params).then((response) => {
                if (response?.data !== "" && response?.data !== null && response?.data !== undefined) {
                    if (response?.data?.status === "S01") {
                        if (response.data.accessToken) {
                            localStorage.setItem("user", JSON.stringify(response.data));
                            localStorage.setItem("token", response.data.accessToken);
                            localStorage.setItem("refresh_token", JSON.stringify(response.data.refresh_token));
                            localStorage.setItem("userId", values.userId);
                            let jsonData = {};
                            jsonData['username'] = values.userId;
                            dispatch({ type: "LOGIN_DETAILS", payload: { isLoggedIn: true, user: jsonData, token: response.data.accessToken, userLoginDetials: response.data } });
                            navigate("/homepage")
                        }
                    } else {
                        setShowErrorMsg(true);
                        dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
                        formIk.setFieldValue('userId', '');
                        formIk.setFieldValue('userPassword', '');
                    }
                }
                return response.data;
            })
        }
    });
    function mobilenosubmit(values) {
        CommonPost.getotppost(values.mobile_no).then((res) => {
            if (res.data.Responsecode === "01") {
                SweetalertOKFunction(res.data.Responsedesc, 'success').then(function (isConfirm) {
                    if (isConfirm.value) {
                        setotpscreen(true);
                        setForgotPage(false);
                        setLoginPage(false)
                        forgotformIk.setFieldValue("otp", "");
                        forgotformIk.setFieldValue("password", "");
                        forgotformIk.setFieldValue("confirmpassword", "");
                    }
                })
            } else {
                Sweetalert(res.data.ResponseDesc, 'warning')
            }
        })
            .catch(() => {
                console.log("Exception Occured ");
            });
    }
    function passwordsubmit(values) {
        CommonPost.getotpsubmitpost(values).then((res) => {
            if (res.data.ResponseCode === "01") {
                SweetalertOKFunction(res.data.ResponseDesc, 'success').then(function (isConfirm) {
                    if (isConfirm.value) {
                        setotpscreen(false);
                        setForgotPage(false);
                        setLoginPage(true)

                    }
                })
            } else if (res.data.ResponseCode === "02") { Sweetalert(res.data.ResponseDesc, 'warning') }
            else if (res.data.ResponseCode === "03") { Sweetalert(res.data.ResponseDesc, 'warning') }
            else { Sweetalert(res.data.ResponseDesc, 'warning') }
        })
            .catch(() => {
                console.log("Exception Occured ");
            });
    }
    const mobilevalidation = Yup.object().shape({
        mobile_no: Yup.string().required("Please Enter Mobile Number").nullable(),
    });
    const passwordvalidation = Yup.object().shape({
        otp: Yup.string().required("Please Enter otp").nullable(),
        password: Yup.string().required("Please Enter Your Password").nullable(),
        confirmpassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });
    const [showotpscreen, setotpscreen] = useState(false)
    const forgotformIk = useFormik({
        initialValues: {
            mobile_no: "",
            otp: "",
            password: "",
            confirmpassword: "",
        },
        validationSchema: showTypeSave === "Forgotpage" ? mobilevalidation : passwordvalidation,
        onSubmit: (values) => {
            showTypeSave === "Forgotpage" ? (mobilenosubmit(values)) : (passwordsubmit(values))
        }
    });


    function toggleForgotPassword (e)  {
        setLoginPage(false);
        dispatch({ type: 'SHOW_SPINNER' });
        setTimeout(() => {
            dispatch({ type: 'HIDE_SPINNER' });
            setForgotPage(true);
        }, 500);
    }
    function toggleBacktoLoginPage (e)  {
        setForgotPage(false); 
        setotpscreen(false);
        dispatch({ type: 'SHOW_SPINNER' });
        setTimeout(() => {
            dispatch({ type: 'HIDE_SPINNER' });
            setLoginPage(true);
        }, 500);
    }
    const carousel1Images = [
        { src: "img/1.png", alt: "" },
        { src: "img/2.png", alt: "" },
        { src: "img/3.png", alt: "" }
    ];
    
    const carousel2Images = [
        { src: "img/4.png", alt: "" },
        { src: "img/5.png", alt: "" },
        { src: "img/6.png", alt: "" }
    ];

    const carousel3Images = [
        { src: "img/7.png", alt: "" },
        { src: "img/8.png", alt: "" },
        { src: "img/9.png", alt: "" }
    ];

    const carousel4Images = [
        { src: "img/10.png", alt: "" },
        { src: "img/11.png", alt: "" },
        { src: "img/12.png", alt: "" }
    ];

    const intervals1 = [1000, 2500, 3800];
    const intervals2 = [1500, 3000, 3500];
    const intervals3 = [2000, 3500, 3900];
    const intervals4 = [2500, 4000, 4400];
     
    return (<>

        <div className="containe-fluid">
            <div className="row m-0  ">
                <div >
                </div>
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 leftside pt-5 position-relative">
                    <div className="wave">  <img src="img/wave.png" alt="waves" className="img-fluid" /></div>
                    <div className="lines">
                        <img src="img/lines.png" alt="" className="img-fluid " />
                    </div>
                    <div className="arrow">
                        <img src="img/arrow.png" alt="" className="img-fluid " />
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0 d-none d-lg-block">
                             <div className="set-pic">
                             {/*   <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="1000">
                                            <img src="img/1.png" alt="" className="img-fluid w-100" />
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <img src="img/2.png" alt="" className="img-fluid w-100" />
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3800">
                                            <img src="img/3.png" alt="" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>*/}
                             <CarouselComponent images={carousel1Images} interval={intervals1} />

                            </div> 
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-9 col-sm-12 col-12 two position-relative d-none d-md-block">
                            <h4 className="set-text">Performance
                                Appraisal
                            </h4>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 position-relative">
                            <div className="dots">
                                <img src="img/dots.png" alt="dots " className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 hrmstext px-5  d-none d-sm-block">
                            <h1 className="set-text">
                                HRMS
                            </h1>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 three text-center px-0 d-none d-lg-block">
                             <div className="set-pic2">
                               {/* <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="1500">
                                            <img src="img/4.png" alt="" className="img-fluid w-100" />
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3000">
                                            <img src="img/5.png" alt="" className="img-fluid w-100" />
                                        </div>
                                        <div className="carousel-item" data-bs-interval="3500">
                                            <img src="img/6.png" alt="" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>*/}
                             <CarouselComponent images={carousel2Images} interval={intervals2} />

                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 four d-none d-md-block">
                                    <h4 className="set-text">Leave Management</h4>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  p-0 d-none d-lg-block">
                                     <div className="set-pic3">
                                       {/* <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active" data-bs-interval="2000">
                                                    <img src="img/7.png" alt="" className="img-fluid w-100" />
                                                </div>
                                                <div className="carousel-item" data-bs-interval="3500">
                                                    <img src="img/8.png" alt="" className="img-fluid w-100" />
                                                </div>
                                                <div className="carousel-item" data-bs-interval="3900">
                                                    <img src="img/9.png" alt="" className="img-fluid w-100" />
                                                </div>
                                            </div>
                                        </div>*/}
                                    <CarouselComponent images={carousel3Images} interval={intervals3} />

                                    </div> 
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  p-0  d-none d-lg-block">
                                     <div className="set-pic4">
                                      {/*  <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active" data-bs-interval="2500">
                                                    <img src="img/10.png" alt="" className="img-fluid w-100" />
                                                </div>
                                                <div className="carousel-item" data-bs-interval="4000">
                                                    <img src="img/11.png" alt="" className="img-fluid w-100" />
                                                </div>
                                                <div className="carousel-item" data-bs-interval="4400">
                                                    <img src="img/12.png" alt="" className="img-fluid w-100" />
                                                </div>
                                            </div>
                                        </div> */}
                                     <CarouselComponent images={carousel4Images} interval={intervals4} />

                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 five d-none d-md-block">
                                    <h4 className="set-text">Payroll Management</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 position-relative d-none d-md-block">
                            <div className="lastimg position-relative">
                                <img src="img/lastcircle.png" alt="designimage" className="img-fluid big" />
                                <img src="img/lastlines.png" alt="designimage" className="img-fluid small" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pt-2 signin px-5">
                    <div className="mt-5 mt-md-4">
                        <Container fluid>
                            <img src="../img/hrms-logo.svg " width="300px" alt="Jnanabhumi" />
                        </Container>
                    </div>
                    {showloginpage && (<>
                        <h1 className="mx-0 mx-lg-5">Sign in</h1>
                        <FormikProvider value={formIk}>
                            <form onChange={formIk.handleChange} onSubmit={formIk.handleSubmit} className="mx-0 mx-lg-5">
                                <div className="mb-3 mt-3">
                                    <Field type="text" name="userId" placeholder="Enter Your User Name" className="form-control" />
                                    <ErrorMessage name="userId" component="div" className="text-error" />
                                </div>
                                <div className="mb-3">
                                    <Field placeholder="Password" name="userPassword" type="password" aria-label="Password"
                                        aria-describedby="basic-addon1" className="form-control" autoComplete="off" />
                                    <ErrorMessage name="userPassword" component="div" className="text-error" />
                                </div>
                                <button type="submit" className="btn btn-primary form-control">Sign in</button>
                                <br></br>
                                <center>
                                    <a className="text-center forgot mb-4" onClick={(e) => { toggleForgotPassword(e) }}>   Forgot Password  ?</a></center>


                                {showErrorMsg &&
                                    <div className="row">
                                        <div className="col-12  col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                            <div className="  alert alert-danger alert-dismissible fade show" role="alert">
                                                <strong>Invalid Username/Password </strong>
                                                <button type="button" onClick={() => setShowErrorMsg(false)} className="btn btn-close" data-dismiss="alert"></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                               <div className="row">
                               <div className="col-12  col-sm-12 col-md-12 col-xl-12 col-lg-12"  style={{ color: '#7e7e7e', fontSize: '12px' }}>
                                    Site last updated(build) on: {preval`module.exports = new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });`}
                                </div>
                                </div>
                            </form>
                        </FormikProvider>
                    </>)}
                    <FormikProvider value={forgotformIk}>
                        <form onChange={forgotformIk.handleChange} onSubmit={forgotformIk.handleSubmit} className="mx-0 mx-lg-5">
                            {showForgotpage ? (<>
                                <h1 className="mx-0 mx-lg-4">Forgot Password</h1>
                                <div className="mb-3 mt-3">
                                    <Field type="text" name="mobile_no" placeholder="Enter Your Mobile Number" className="form-control" maxLength="10"
                                        onKeyPress={(e) => { allowNumbersOnly(e); }} />
                                    <ErrorMessage name="mobile_no" component="div" className="text-error" />
                                </div>
                                <button type="submit" className="btn btn-primary form-control"
                                    onClick={(e) => { setTypeSave("Forgotpage") }} >Forgot Password</button>
                                <br></br>
                                <center> 
                                    <a className="text-center forgot mb-4" onClick={(e) => { toggleBacktoLoginPage(e) }}>Back to LoginPage</a></center>

                            </>) : (<>
                                {showotpscreen && (<>
                                    <h1 className="mx-0 mx-lg-4">Change Password</h1>
                                    <div className="mb-3 mt-3">
                                        <Field type="text" name="otp" placeholder="Enter OTP" className="form-control" maxLength="6"
                                            onKeyPress={(e) => { allowNumbersOnly(e); }} />
                                        <ErrorMessage name="otp" component="div" className="text-error" />
                                    </div>
                                    <div className="mb-3">
                                        <Field placeholder="Enter New Password" name="password" type="password" aria-label="Password"
                                            aria-describedby="basic-addon1" className="form-control" autoComplete="off" />
                                        <ErrorMessage name="password" component="div" className="text-error" />
                                    </div>
                                    <div className="mb-3">
                                        <Field placeholder="Confirm Password" name="confirmpassword" type="password" aria-label="Password"
                                            aria-describedby="basic-addon1" className="form-control" autoComplete="off" />
                                        <ErrorMessage name="confirmpassword" component="div" className="text-error" />
                                    </div>
                                    <button type="submit" className="btn btn-primary form-control"
                                        onClick={(e) => { setTypeSave("changepassword") }} >Submit</button>
                                    <br></br>
                                    <center>
                                    <a className="text-center forgot mb-4" onClick={(e) => {  toggleBacktoLoginPage(e) }}>Back to LoginPage</a></center>

                                </>)}
                            </>)}
                        </form>
                    </FormikProvider>
                   
                    <div className="lastimg position-relative d-block d-md-none">
                        <img src="img/lastcircle.png" alt="designimage" className="img-fluid big" />
                        <img src="img/lastlines.png" alt="designimage" className="img-fluid small" />
                    </div>
                </div>
            </div>
        </div>
    </>)
}