import { ErrorMessage, Field, FormikProvider, useFormik,Form } from "formik";
import * as jnb from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import { config } from "../../../../CommonUtils/CommonApis";
import getPDFView from "../../../../CommonUtils/PdfView";
import { FaFilePdf } from "react-icons/fa6";
export default function RoPmsForm() {
    const rologindata = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const employeeId = useSelector((state) => state.reducers.employeereducer.empid);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showROEmpData, setROEmpData] = useState([]);
    const [showROEmpKpData, setROEmpKpData] = useState([]);
    const [showROEmpKpStatusData, setROEmpKpStatusData] = useState([]);
    const onlynum = /^[0-9]+$/;
    const validation = Yup.object().shape({
        roFinalRatingStatus: Yup.string().required("Please Select Agree or Disagree").nullable(),
        roFinalRatingStatusRemarks: Yup.string().when("roFinalRatingStatus", {
            is: (roFinalRatingStatus) => (roFinalRatingStatus === "N") ? true : false,
            then: Yup.string().required("Please Enter Remarks").nullable(),
        }),
        roFinalRating: Yup.string().when("roFinalRatingStatus", {
            is: (roFinalRatingStatus) => (roFinalRatingStatus === "N") ? true : false,
            then: Yup.string().required("Please Select Final Rating").nullable(),
        }),
        roFinalRemarks: Yup.string().when("roFinalRatingStatus", {
            is: (roFinalRatingStatus) => (roFinalRatingStatus === "N") ? true : false,
            then: Yup.string().required("Please Enter Final Rating Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),
        roPromotionStatus: Yup.string().required("Please Select Agree or Disagree").nullable(),
        roPromotionStatusRemarks: Yup.string().when("roPromotionStatus", {
            is: (roPromotionStatus) => (roPromotionStatus === "N") ? true : false,
            then: Yup.string().required("Please Enter Promotion Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),
        roSalaryCorrectionStatus: Yup.string().required("Please Select Agree or Disagree").nullable(),
        roSalaryCorrectionStatusRemarks: Yup.string().when("roSalaryCorrectionStatus", {
            is: (roSalaryCorrectionStatus) => (roSalaryCorrectionStatus === "N") ? true : false,
            then: Yup.string().required("Please Enter Salary Correction Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),
        salaryCorrection: Yup.string().when("roSalaryCorrectionStatus", {
            is: (roSalaryCorrectionStatus) => (roSalaryCorrectionStatus === "N") ? true : false,
            then: Yup.string().required("Please Select Salary Correction").nullable(),
        }),
        salaryCorrectionRemarks: Yup.string().when(["roSalaryCorrectionStatus", "salaryCorrection"], {
            is: (roSalaryCorrectionStatus, salaryCorrection) => (roSalaryCorrectionStatus === "N"
                && salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please Enter Remarks")
        }),
        salaryCorrectionAmount: Yup.string().when(["roSalaryCorrectionStatus", "salaryCorrection"], {
            is: (roSalaryCorrectionStatus, salaryCorrection) => (roSalaryCorrectionStatus === "N"
                && salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please  salary Correction Amount").matches(onlynum, "Amount must be digits only").nullable(),
        }),
    })
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            roEmpId: rologindata?.username,
            empId: employeeId,
            kpWeightage: 100,
            ioFinalRating: "",
            ioFinalMarks: "",
            empSelfFinalMarks: "",
            empSelfFinalRating: "",

            roFinalRatingStatus: "",
            roFinalRating: "",
            roFinalRatingStatusRemarks: "",
            roFinalRemarks: "",

            roPromotionStatus: "",
            roPromotionStatusRemarks: "",
            promotionStatus: false,
            promotionRemarks: "",

            roSalaryCorrectionStatus: "",
            roSalaryCorrectionStatusRemarks: "",
            salaryCorrection: "",
            salaryCorrectionAmount: 0,
            salaryCorrectionRemarks: "",

            ro_strenth_remarks: "",
            ro_areas_of_imporvement_remarks: "",
            ro_carrer_aspirations_remarks: "",
            ro__addl_key_objective_remarks: "",
            ro_file_remarks: "",
            strengthsWeaknesses: "",
        },
        validationSchema: validation,
        onSubmit: (values) => {
            ROsubmitAlert(values);
        },

    });
    const ROsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.rologinPost(values).then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    navigate(`/loginwisereport/${"RO"}`)
                                }
                            })
                        }
                        else { Swal.fire('Canceled', 'Something went wrong,Please check', 'error'); }
                    })
                    .catch((err) => {console.log("err",err)});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showEmployeePromotionData, setEmployeePromotionData] = useState([]);
    function GetROApprisalData() {
        let Url = (config.url.COMMON_URL + "pms/employee/appraisal/RO/" + employeeId);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setROEmpData(res.data.employee_data);
                setROEmpKpData(res.data.employee_kp_data);
                setROEmpKpStatusData(res.data.employee_kpstatus_data);
                setEmployeePromotionData(res.data.employee_io_status_data)
                formIk.setFieldValue("empSelfFinalMarks", res?.data?.employee_io_status_data[0]?.emp_self_final_marks)
                formIk.setFieldValue("empSelfFinalRating", res?.data?.employee_io_status_data[0]?.emp_self_final_rating)
                formIk.setFieldValue("ioFinalMarks", res?.data?.employee_io_status_data[0]?.io_final_marks)
                formIk.setFieldValue("ioFinalRating", res?.data?.employee_io_status_data[0]?.io_final_rating)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    useEffect(() => {
        GetROApprisalData();
    }, [dispatch, employeeId]);
    return (<>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <div className="card">
                    <div className="card-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/homepage">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"RO"}`}>My Team Appraisal-RO</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                        </ol>
                    </div>
                </div>
                <div className="head-text mt-4"> <h1> Appraisal Form </h1></div>
                <div className="  mb-3" >
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span> Name (ID):</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.emp_name}({showROEmpData[0]?.emp_id})</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Date of Joining:</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.doj.split("-").reverse().join("/")}</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Wing:</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.zone_name}</b></span>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span> IO Name(ID):</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.io_emp_name}({showROEmpData[0]?.io_emp_id})</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>RO Name(ID):</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.ro_emp_name}({showROEmpData[0]?.ro_emp_id})</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Designation & Band</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.designation_name}</b></span>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Email:</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.email}</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Band:</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.band}</b></span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span>Contact:</span>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                            <span><b>{showROEmpData[0]?.mobile_no}</b></span>
                        </jnb.Col>
                    </jnb.Row>
                    <br />
                </div>

                {showROEmpKpData !== undefined && showROEmpKpData.map((row, i) => {
                    return (<React.Fragment key={uuidv4()}>
                        <div className="table-responsive">
                            <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                            <br></br>
                            <table className="table table-condensed table-bordered table-striped table-responsive"
                                style={{ border: '3px solid lightblue', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Key Performance Areas Description</th>
                                        <th>Self Rating</th>
                                        <th>Self Remarks</th>
                                        <th>IO Rating</th>
                                        <th> IO Remarks</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {
                                        showROEmpKpStatusData !== undefined && showROEmpKpStatusData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                            return (
                                                <React.Fragment key={k}>
                                                    <tr>
                                                        <td>{k + 1}</td>
                                                        <td>  
                                                            {kpidata.kp_indicator_id >= 9990 ? (<>
                                                            <span style={{ color: "blue" }}>{kpidata.kp_indicator_description}</span></>)
                                                            : (<>{kpidata.kp_indicator_description}</>)}
                                                            {kpidata.mandatory_kp_flag === true ? (<> <font style={{ color: "red" }}>*</font>
                                                            </>) : (<></>)}
                                                        </td>
                                                        <td>{kpidata.emp_self_rating}</td>
                                                        <td>{kpidata.emp_self_rating_remarks}</td>
                                                        <td>{kpidata.io_emp_rating}</td>
                                                        <td>{kpidata.io_emp_remarks}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </tbody>

                            </table>
                        </div>


                    </React.Fragment>)
                })}
                <div className="table-responsive">
                    <table className="table table-condensed table-bordered table-striped table-responsive"
                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Self remarks</th>
                                <th> IO Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>  Strengths </td>
                                <td><b>{showROEmpKpStatusData[0]?.self_strengths}</b></td>
                                <td><b>{showROEmpKpStatusData[0]?.io_strengths}</b> </td>
                            </tr>
                            <tr>
                                <td>Areas for Improvement</td>
                                <td><b>{showROEmpKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                <td><b>{showROEmpKpStatusData[0]?.io_areas_of_imporvement}</b></td>
                            </tr>
                            <tr>
                                <td> Career Aspirations </td>
                                <td><b>{showROEmpKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                <td><b>{showROEmpKpStatusData[0]?.io_carrer_aspirations}</b></td>
                            </tr>

                            <tr>
                                <td>Additional accomplishments not covered in Key Objectives above</td>
                                <td>{showROEmpKpStatusData[0]?.self_addl_key_objective}</td>
                                <td><b>{showROEmpKpStatusData[0]?.io_addl_key_objective}</b></td>
                            </tr>
                            <tr>
                                <td>Upload Any Relevant Document</td>
                                {(showROEmpData[0]?.file_upload != undefined && showROEmpData[0]?.file_upload != "") ? (<>
                                    <td>  <FaFilePdf style={{ fontWeight: "bold", fontSize: "30px", color: "blue", cursor: "pointer" }}
                                        onClick={(e) => { getPDFView(showROEmpData[0]?.file_upload) }} /> </td>
                                    <td>{showROEmpData[0]?.io_file_remarks}</td>
                                </>) : (<><td colSpan={3}><b>Document not Uploaded</b></td></>)}
                            </tr>
                            <tr>
                                <td >Ro Overall Remarks:</td>
                                <td colSpan={2}><Field as="textarea" className="form-control" name="strengthsWeaknesses" ></Field>
                                    <ErrorMessage name="strengthsWeaknesses" component="div" className="text-error" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {showROEmpData[0]?.ro_final_status ? (null) : (<>
                    <div className="table-responsive">
                        <table className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                            <thead>
                                <th width="250px"></th>
                                <th>Employee</th>
                                <th>IO</th>
                                <th colSpan={5}>Agree/Disagree</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Final Rating:</b><font style={{ color: "red" }}>*</font> </td>
                                    <td>{showEmployeePromotionData[0]?.emp_self_final_rating}
                                    ({showEmployeePromotionData[0]?.emp_self_final_marks})
                                    </td>
                                    <td>{showEmployeePromotionData[0]?.io_final_rating}
                                    ({showEmployeePromotionData[0]?.io_final_marks})
                                    </td>
                                    <td> <Field type="radio" name="roFinalRatingStatus" value="Y" />&nbsp;&nbsp;Agree&nbsp;&nbsp;
                                        <Field type="radio" name="roFinalRatingStatus" value="N" />&nbsp;&nbsp;Disagree
                                        <ErrorMessage name="roFinalRatingStatus" component="div" className="text-error" /></td>
                                    {formIk.values.roFinalRatingStatus === "N" ? (<>
                                        <td>    <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                            <Field as="textarea" className="form-control" name="roFinalRatingStatusRemarks" ></Field>
                                            <ErrorMessage name="roFinalRatingStatusRemarks" component="div" className="text-error" /></td>
                                        <td>  <b>Final Rating:</b><font style={{ color: "red" }}>*</font>
                                            <Field as="select" className="form-control" name="roFinalRating">
                                                <option value="">--Select--</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                            </Field>
                                            <ErrorMessage name="roFinalRating" component="div" className="text-error" /></td>
                                        {formIk.values.roFinalRating !== "" && (<>
                                            <td >   <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                                <Field as="textarea" className="form-control" name="roFinalRemarks" ></Field>
                                                <ErrorMessage name="roFinalRemarks" component="div" className="text-danger" ></ErrorMessage></td>

                                        </>)}
                                    </>) : ("")}
                                </tr>
                                <tr>
                                    <td><b>I am Referring for Promotion </b><font style={{ color: "red" }}>*</font>&nbsp;&nbsp;</td>
                                    <td>-</td>
                                    <td>{showEmployeePromotionData[0]?.io_promotion_status === true ? "Yes" : "No"}
                                        (<b>Remarks:&nbsp;</b>{showEmployeePromotionData[0]?.io_promotion_remarks})</td>
                                    <td ><Field type="radio" name="roPromotionStatus" value="Y" />&nbsp;&nbsp;Agree&nbsp;&nbsp;
                                        <Field type="radio" name="roPromotionStatus" value="N" />&nbsp;&nbsp;Disagree
                                        <ErrorMessage name="roPromotionStatus" component="div" className="text-error" /></td>

                                    {showEmployeePromotionData[0]?.io_promotion_status === true ? (<>
                                        {formIk.values.roPromotionStatus === "N" ? (<>
                                            <td>  <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                                <Field as="textarea" className="form-control" name="roPromotionStatusRemarks" maxLength="400"></Field>
                                                <ErrorMessage name="roPromotionStatusRemarks" component="div" className="text-error" />
                                            </td>
                                        </>) : ""}
                                    </>) : (<>
                                        {formIk.values.roPromotionStatus === "N" ? (<>
                                            <td>  <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                                <Field as="textarea" className="form-control" name="roPromotionStatusRemarks" maxLength="400"></Field>
                                                <ErrorMessage name="roPromotionStatusRemarks" component="div" className="text-error" />
                                            </td>
                                            <td>
                                                <Field type="checkbox" name="promotionStatus" onChange={(e) => { formIk.setFieldValue("promotionRemarks", "") }}></Field>
                                                <ErrorMessage name="promotionStatus" component="div" className="text-error" />
                                            </td>
                                            {formIk.values.promotionStatus === true && (<>
                                                <td>
                                                    <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                                    <Field as="textarea" className="form-control" name="promotionRemarks" maxLength="400"></Field>
                                                </td>
                                            </>)}
                                        </>) : (<></>)}
                                    </>)}
                                </tr>
                                <tr>
                                    <td><b>Salary Correction:</b><font style={{ color: "red" }}>*</font></td>
                                    <td>-</td>
                                    <td>{showEmployeePromotionData[0]?.io_salary_correction === "Y" ? (<>Yes
                                        (<b>Amount:&nbsp;Rs.</b>{showEmployeePromotionData[0]?.io_salary_correction_amount})
                                        (<b>Remarks:&nbsp;</b>{showEmployeePromotionData[0]?.io_salary_correction_remarks})
                                    </>) : ("No")}
                                    </td>
                                    <td><Field type="radio" name="roSalaryCorrectionStatus" value="Y" />&nbsp;&nbsp;Agree&nbsp;&nbsp;
                                        <Field type="radio" name="roSalaryCorrectionStatus" value="N" />&nbsp;&nbsp;Disagree
                                        <ErrorMessage name="roSalaryCorrectionStatus" component="div" className="text-error" /></td>
                                    {formIk.values.roSalaryCorrectionStatus === "N" ? (<>
                                        <td> <b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                            <Field as="textarea" className="form-control" name="roSalaryCorrectionStatusRemarks" maxLength="400"></Field>
                                            <ErrorMessage name="roSalaryCorrectionStatusRemarks" component="div" className="text-danger" ></ErrorMessage>
                                        </td>
                                        <td> <b>Salary Correction:</b><font style={{ color: "red" }}>*</font><br />
                                            <Field type="radio" name="salaryCorrection" value="Y" onChange={(e) => { formIk.setFieldValue("salaryCorrectionAmount", "") }} />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                                            <Field type="radio" name="salaryCorrection" value="N" />&nbsp;&nbsp;No
                                            <ErrorMessage name="salaryCorrection" component="div" className="text-error" /></td>
                                        {formIk.values.salaryCorrection === "Y" && (<>
                                            <td><b>Remarks:</b><font style={{ color: "red" }}>*</font>
                                                <Field as="textarea" className="form-control" name="salaryCorrectionRemarks"  maxLength="400"></Field> 
                                                <ErrorMessage name="salaryCorrectionRemarks" component="div" className="text-danger" ></ErrorMessage></td>
                                            <td><b>Amount:(per Annum)</b><font style={{ color: "red" }}>*</font>
                                                <Field type="text" className="form-control" name="salaryCorrectionAmount" onKeyPress={(e) => allowNumbersOnly(e)}
                                                    maxLength="7"></Field>
                                                <ErrorMessage name="salaryCorrectionAmount" component="div" className="text-danger" ></ErrorMessage>
                                            </td>
                                        </>)}
                                    </>) : (<></>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        <button type="submit" className="btn rounded-0 btn-success btn-sm">Submit</button>
                    </div>
                </>)}
            </Form>
        </FormikProvider >

    </>)
}
