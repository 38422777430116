import {FormikProvider, useFormik} from "formik";
import {Form} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React,{ useEffect, useState } from "react";
// import { config } from "../../../CommonUtils/CommonApis";
// import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import Swal from "sweetalert2";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";


export default function JobPostReport() {
    const [GetJobPostReportDetails,setJobPostReportDetails]=useState([])
    const navigate = useNavigate();
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {
            
            console.log(values)
        },
    });
    useEffect(() => {

        let Url = (config.url.COMMON_URL + "jobdtls");
        CommonAxiosGet(Url).then((res) => {
            console.log("res.data.alldata", res.data.alldata);
            if (res.data.status === true) {
                setJobPostReportDetails(res.data.alldata)
            }
            else {
                setJobPostReportDetails([])
                // Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }, []);
    function NavigateToJobPostEntryform(){
        navigate("/jobposting")
    } 
    function navitagtetoCandidateForm(technology_applied,designation_applied){
        navigate("/appliedCandidateDetails",{ state: {technology_applied: technology_applied,designation_applied:designation_applied} })
        localStorage.setItem("technology_applied",technology_applied);
        localStorage.setItem("designation_applied",designation_applied);
    }
    function alertMessage() {
        Swal.fire({
          text: "No Data Found",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((willDelete) => {
    
          if (willDelete.isConfirmed) {
          }
        })
    
      }
    return (<>
     <jnb.Row className=" m-0">
     <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
     <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Job Details</li>
                </ol>
     </jnb.Col>
     </jnb.Row>

        <jnb.Row className=" pt-2">
        <jnb.Row className="m-0 pt-2">
        <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
        <div className="head-text"style={{display:"inline"}}>
            <h1> Job Details </h1> </div></jnb.Col>
        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pe-0">
        <button type="button" class="btn btn-success btn-sm mb-3 float-end"  onClick={() => {NavigateToJobPostEntryform()}}>ADD NEW JOB POST</button> 




           {/* <button type="button" className="bg-success p-2 text-white  btn-sm  " style={{float:"right"}}
           onClick={() => {NavigateToJobPostEntryform()}}>ADD NEW JOB POST</button>  */}
        </jnb.Col>
        </jnb.Row>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <jnb.Row className="px-3 pt-2">
                <table className="table table-condensed table-bordered table-striped table-responsive">
                        <thead>
                            <tr>
                                <th align="center">S.No</th>
                                <th>Technologies</th>
                                <th>Requirement Years</th>
                                <th>Designation</th>
                                <th>Location</th>
                                <th>Immediate Joining</th>
                                <th>Jobs Applied</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "left" }}>
                            {GetJobPostReportDetails !== undefined && GetJobPostReportDetails?.map((data, i) => {
                                return (<React.Fragment key={i}>
                                    <tr>
                                        <td width="100px" align="center">{i + 1}</td>
                                        <td>{data.zone_name}</td>
                                        <td>{data.requirement_years}</td>
                                        <td>{data.designation_name}</td>
                                        <td>{data.job_location}</td>
                                        <td>{data.immediate_joining}</td>
                                       <td>
                                       <a href="javascript:void(0);" 
                                        onClick={(e) => { (data.jobs_applied) !== 0 ? navitagtetoCandidateForm(data.technology,data.designation) : alertMessage() }}>
                                       {data.jobs_applied}
                                       </a>
                                       </td>
                                    </tr>
                                </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </jnb.Row>
            </Form>
        </FormikProvider>
        </jnb.Row>
    </>)
}