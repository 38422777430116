import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { useState } from "react";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import CommonPost from "../../../CommonUtils/CommonPost";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from 'react-select';
import { useSelector } from "react-redux";
// import Footer from "../../../Footer/Footer";
import { Link } from "react-router-dom";

export default function PrimarySkillMapping() {
    const logindetails = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const Formvalidation = Yup.object().shape({
        // primary: Yup.array().of(Yup.object().shape({
        //     kpSubIndicatorIds: Yup.string().required("Please Select KP Indicators").nullable(),
        // })
        // ),
    });
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            primarytable: [
                {
                    roEmpId: logindetails.username,
                    empId: "",
                    zoneId: "",
                    designationId: "",
                    kpId: "",
                    kpSubIndicatorIds: [],


                }
            ]
            // kpid: "",


            // primary: [{
            //     // kpId: "",
            //     kpSubIndicatorIds: "",
            // }]
        },
        onSubmit: (values) => {
         //   console.log("submit values", values)
            const newData = values.primarytable.filter((rowdata) => rowdata.kpSubIndicatorIds !== undefined);
          //  console.log("submit values new", newData)

            let kpIndicatorsCount = 0;
            for (let k = 0; k < newData.length; k++) {
                kpIndicatorsCount += newData[k].kpSubIndicatorIds.length;
            }

            if (kpIndicatorsCount === 0) {
                Sweetalert('Please Select at least one Employees Primary Skill', 'warning');

            }
            else {
                values.primarytable = newData;
                // console.log("submit values new test", values);
                KPIsubmitAlert(values);
            }


        },
        validationSchema: Formvalidation,
    });
    const KPIsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
              //  console.log("submit values", values)
                CommonPost.primaryskillpost(values)
                    .then((res) => {
                      //  console.log("kjjllk", res.data)
                        if (res.data.scode === "01") {

                            SweetalertOKFunction('Successfully submited ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    // setSubIndicatorFalg(false);
                                    // formIk.setFieldValue("kpid", "")
                                    // formIk.setFieldValue("kp", []);
                                    // setReportFalg(false);
                                    GetPrimarySkillData();
                                    setSelectedOptions([]);
                                    //formIk?.values?.primarytable[0]?.kpSubIndicatorIds=[];
                                    formIk?.resetForm();
                                }
                            })
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showPrimarySkillData, setPrimarySkillData] = useState([]);
    const [showmappedPrimarySkillData, setmappedPrimarySkillData] = useState([]);
    function GetPrimarySkillData() {
        CommonAxiosGet(config.url.COMMON_URL + "employee/primaryskill/" + logindetails.username).then((res) => {
           // console.log("values", res.data)
            if (res.data.scode === "01") {
                setPrimarySkillData(res.data.data);
                setmappedPrimarySkillData(res.data.mapped_data);
                for (let i = 0; i < res.data.data.length; i++) {

                    formIk.setFieldValue("primarytable[" + i + "].zoneId", res.data.data[i].zone_id)
                    formIk.setFieldValue("primarytable[" + i + "].roEmpId", logindetails.username)//
                    formIk.setFieldValue("primarytable[" + i + "].designationId", res.data.data[i].designation_id)
                    formIk.setFieldValue("primarytable[" + i + "].empId", res.data.data[i].emp_id)
                    formIk.setFieldValue("primarytable[" + i + "].kpId", res.data.data[i]?.employee_kp_indicators_data[0]?.kp_id)
                }
                // setkeyperformanceindicatorsdata()
               // dispatch({type:"",payload:{}})
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, "warning");
            }
        })
    }


//  eslint-disable-next-line 
    const options = [
        { value: 'apple', label: 'Apple' },
        { value: 'banana', label: 'Banana' },
        { value: 'orange', label: 'Orange' },
        { value: 'pear', label: 'Pear' },
        { value: 'grape', label: 'Grape' },
    ];
    const [selectedOptions, setSelectedOptions] = useState([]);


    // const handleMultiSelectChange = (selectedOptions, index, arrayHelpers) => {
    //     arrayHelpers.replace(index, { selectedOptions });
    // };

    const handleMultiSelectChange = (selectedOptions, i) => {
        if (selectedOptions !== null) {
           // console.log("hhhhh", selectedOptions, i)
            formIk.setFieldValue("primarytable[" + i + "].kpSubIndicatorIds", selectedOptions.map((rowdata) => rowdata.value))

            // for (let i = 0; i < selectedOptions.length; i++) {
            //     console.log("hhhhh", selectedOptions.map((rowdata) => rowdata.value))
            //     formIk.setFieldValue("primarytable[" + i + "].kpSubIndicatorIds", selectedOptions.map((rowdata) => rowdata.value))
            // }
            // console.log("hhhhh", selectedOptions.map((rowdata) => rowdata.value))
            // console.log("hhhhh", selectedOptions)

            // formIk.setFieldValue("primarytable[" + i + "].kpSubIndicatorIds", selectedOptions.map((rowdata) => rowdata.value))
            // formik.setFieldValue("mandal", "")
        }
        else {
            // formik.setFieldValue("district", "")
        }
        setSelectedOptions(selectedOptions.value);
    };
    useEffect(() => {
        GetPrimarySkillData();
        //  eslint-disable-next-line 
    }, [])
    return (<>
        <div class="card">
            <div class="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Primary Skill Mapping</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"> <h1>Primary Skill Mapping</h1></div>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                {(showPrimarySkillData !== undefined && showPrimarySkillData.length > 0) ? (<>
                    <div className="table-responsive">
                        <table className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                            {/* width: '85%', marginLeft: '55px', */}
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Employee ID</th>
                                    <th>Employee Name</th>
                                    <th>Designation</th>
                                    <th>Skills</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                {showPrimarySkillData !== undefined && showPrimarySkillData.map((rowdata, i) => {
                                    return (<React.Fragment key={i}>
                                        <tr>
                                            <td >{i + 1}</td>
                                            <td>{rowdata.emp_id}</td>
                                            <td>{rowdata.emp_name}</td>
                                            <td>{rowdata.designation_name}</td>
                                            <td>

                                                <Select className="multi-select-custome" name={`primarytable[${i}].kpSubIndicatorIds`}
                                                    options={rowdata?.employee_kp_indicators_data.map((depi, i) => ({
                                                        value: depi.kp_subindicator_id,
                                                        label: depi.kp_indicator_description
                                                    }))}
                                                    // options={options}
                                                    isMulti
                                                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, i)}

                                                    //onChange={handleMultiSelectChange}
                                                    value={selectedOptions}
                                                />


                                                {/* <Field
                                                as={Select}
                                                isMulti
                                                name={`primary[${i}].kpSubIndicatorIds`}
                                                className="form-control mb-3"
                                                options={rowdata?.employee_kp_indicators_data?.map((depi, i) => ({
                                                    value: depi.kp_subindicator_id,
                                                    label: depi.kp_indicator_description
                                                }))}
                                            /> */}

                                                {/* <Field as="select" name={`primary[${i}].kpSubIndicatorIds`} className="form-control mb-3">
                                                <option value="">--Select--</option>
                                                {rowdata?.employee_kp_indicators_data !== undefined && rowdata?.employee_kp_indicators_data?.map((depi, i) => {
                                                    return (<React.Fragment key={i}>
                                                        <option value={depi.kp_subindicator_id}>{depi.kp_indicator_description}</option>
                                                    </React.Fragment>)
                                                })}
                                            </Field> */}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                    )
                                })}
                            </tbody >
                        </table>
                    </div>
                    <button type="submit" className="btn rounded btn-success btn-sm" style={{ float: "right" }} >Submit</button>

                </>) : (<></>)}
                <br></br><br></br><br></br><br></br>
                {showmappedPrimarySkillData.length > 0 ? (<>
                    <div className="table-responsive">
                        <table className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                            {/* width: '85%', marginLeft: '55px', */}
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Employee ID</th>
                                    <th>Employee Name</th>
                                    <th>Designation</th>
                                    <th>Skills</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                {showmappedPrimarySkillData !== undefined && showmappedPrimarySkillData.map((rowdata, i) => {
                                    return (<React.Fragment key={i}>
                                        <tr>
                                            <td >{i + 1}</td>
                                            <td>{rowdata.emp_id}</td>
                                            <td>{rowdata.emp_name}</td>
                                            <td>{rowdata.designation_name}</td>
                                            <td>{rowdata.kp_sub_indicator_description_ids}</td>
                                        </tr>
                                    </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>) : (<></>)}

            </Form>
        </FormikProvider>
        {/* </jnb.Container > */}
        {/* <Footer/> */}
    </>)
}
