import { FormikProvider, useFormik, Form, Field, ErrorMessage } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import * as  Yup from "yup";
import Swal from "sweetalert2";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import CommonPost from "../../../../CommonUtils/CommonPost";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import { useSelector } from "react-redux";
import ImageBucketUpload from "../../../../CommonUtils/ImageAndFileBucketUpload";
import { Link } from "react-router-dom";
import { downloadUpdatedStagephotoOrFile } from "../../../../CommonUtils/CommonAxios";
const PayRollEntryFormGovt = (propsAtgovt) => {
    const logindetailsGovt = useSelector((stategvt) => stategvt.reducers?.loginreducer?.userLoginDetials);
    const GovtformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            emp_id:"14210",
            desg_id:0,
            insert_by:logindetailsGovt?.username,
            basic_pay:"",
            pp_fp:"",
            dearness_allowance:"",
            hra:"",
            cca:"",
            ta_hra:"",
            da_on_ta_ra:"",
            fpa_kma :"",
            deputation_allowance:"",
            special_pay:"",
            ir_27:"",
            other_allowance_1:"",
            other_allowance_2:"" ,
            gpf_contribution:"",
            employee_cps_contribution:"",
            sanjeevani_scheme_contribution:"",
            apgli:"",
            apgli_l:"",
            gis:"",
            income_tax:"",
            professional_tax:"",
            salary_advance_recovery:"",
            ehf:"",
            ewf:"",
            hba_p:"",
            flag_day:"",
            other_deductions_1:"",
            other_deductions_2:"",
            other_deductions_3:"",
            other_deductions_1_remarks:"",
            other_deductions_2_remarks:"",
            other_deductions_3_remarks:"",
            cps_employer:"",
            remarks:"",
            upload_files:""
        },
        validationSchema: Yup.object().shape({
            basic_pay:Yup.string().required("Required").nullable(),
            pp_fp:Yup.string().required("Required").nullable(),
            dearness_allowance:Yup.string().required("Required").nullable(),
            hra:Yup.string().required("Required").nullable(),
            cca:Yup.string().required("Required").nullable(),
            ta_hra:Yup.string().required("Required").nullable(),
            da_on_ta_ra:Yup.string().required("Required").nullable(),
            fpa_kma :Yup.string().required("Required").nullable(),
            deputation_allowance:Yup.string().required("Required").nullable(),
            special_pay:Yup.string().required("Required").nullable(),
            ir_27:Yup.string().required("Required").nullable(),
            other_allowance_1:Yup.string().required("Required").nullable(),
            other_allowance_2:Yup.string().required("Required").nullable(),
            gpf_contribution:Yup.string().required("Required").nullable(),
            employee_cps_contribution:Yup.string().required("Required").nullable(),
            sanjeevani_scheme_contribution:Yup.string().required("Required").nullable(),
            apgli:Yup.string().required("Required").nullable(),
            apgli_l:Yup.string().required("Required").nullable(),
            gis:Yup.string().required("Required").nullable(),
            income_tax:Yup.string().required("Required").nullable(),
            salary_advance_recovery:Yup.string().required("Required").nullable(),
            ehf:Yup.string().required("Required").nullable(),
            ewf:Yup.string().required("Required").nullable(),
            hba_p:Yup.string().required("Required").nullable(),
            flag_day:Yup.string().required("Required").nullable(),
            other_deductions_1:Yup.string().required("Required").nullable(),
            other_deductions_2:Yup.string().required("Required").nullable(),
            other_deductions_3:Yup.string().required("Required").nullable(),
            other_deductions_1_remarks:Yup.string().required("Required"),
            other_deductions_2_remarks:Yup.string().required("Required"),
            other_deductions_3_remarks:Yup.string().required("Required"),
            remarks:Yup.string().required("Required").nullable(),
            upload_files:Yup.string().required("Required").nullable()
        }),
        onSubmit: (values) => {
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.GovtEmpPaySave(values).then((resgvt) => {
                            if (resgvt?.data?.ResponseCode === "01") {
                                SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                    if (isConfirm.value) {window.location.reload();}
                                })
                            }
                            else if (resgvt?.data?.ResponseCode === "02") {
                                Sweetalert(resgvt?.data?.ResponseDesc, 'warning');
                            }
                            else {
                                Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                            }
                        }).catch((err) => {console.log(err)});
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        },

    });
    function GetCpsEmployer(valueAtGvt){
        if(valueAtGvt>0){
            GovtformIk.setFieldValue("cps_employer",valueAtGvt);
        }
        else{
            GovtformIk.setFieldValue("cps_employer",0);}
    }
    const { basic_pay,pp_fp,dearness_allowance,hra,cca,ta_hra,da_on_ta_ra,
        fpa_kma,deputation_allowance,special_pay,ir_27,other_allowance_1,
        gpf_contribution,employee_cps_contribution,sanjeevani_scheme_contribution,
        apgli,apgli_l,gis,income_tax,professional_tax,salary_advance_recovery,
        ehf,ewf,hba_p,flag_day,other_deductions_1,other_deductions_2,
      } = GovtformIk?.values;
    const basicpaygvt = parseFloat(basic_pay || 0);const ppfp = parseFloat(pp_fp || 0);
    const dearnessallowance = parseFloat(dearness_allowance || 0);const Hra = parseFloat(hra || 0);
    const Cca = parseFloat(cca || 0);const tahra = parseFloat(ta_hra || 0);
    const da_on_tara = parseFloat(da_on_ta_ra || 0);const fpakma = parseFloat(fpa_kma || 0);
    const deputationallowance = parseFloat(deputation_allowance || 0);const specialpay = parseFloat(special_pay || 0);
    const ir = parseFloat(ir_27 || 0);const other_allowance1 = parseFloat(other_allowance_1 || 0);
   
    const gpfcontribution = parseFloat(gpf_contribution || 0);
    const employeecpscontribution = parseFloat(employee_cps_contribution || 0);
    const sanjeevani_schemecontribution = parseFloat(sanjeevani_scheme_contribution || 0);
    const apglivalue = parseFloat(apgli || 0);const apglil = parseFloat(apgli_l || 0);
    const gisvalue = parseFloat(gis || 0);const incometax = parseFloat(income_tax || 0);
    const professionaltax = parseFloat(professional_tax || 0);
    const salaryadvance_recovery = parseFloat(salary_advance_recovery || 0);
    const ehfvalue = parseFloat(ehf || 0);const ewfvalue = parseFloat(ewf || 0);
    const hbap = parseFloat(hba_p || 0);const flagday = parseFloat(flag_day || 0);
    const other_deductions1 = parseFloat(other_deductions_1 || 0);
    const other_deductions2 = parseFloat(other_deductions_2 || 0);

    const [showGrosstotal,setGrosstotal]=useState(null)
    const [showTotalDeductions,setTotalDeductions]=useState(null)
    const [showNetAmount,setNetAmount]=useState(null)
    const [viewMoreFields,setViewMoreFields]=useState(false)
    function GrossTotal(valueGovt){
        const other_allowance2 = parseFloat(valueGovt || 0);
        
        if (basic_pay !== "" && pp_fp !== "" && dearness_allowance !== "" && hra !== "" && cca !== "" && ta_hra !== "" && da_on_ta_ra !== "" &&
            fpa_kma !== "" && deputation_allowance !== "" && special_pay !== "" && ir_27 !== "" && other_allowance_1 !== "" && valueGovt !== "") {
         
            const grosstotalatgovt =  basicpaygvt + ppfp + dearnessallowance + Hra +Cca + tahra + da_on_tara + fpakma +  deputationallowance + specialpay + ir+
            other_allowance1 + other_allowance2;
            setViewMoreFields(true)
            setGrosstotal(grosstotalatgovt)
           
        }
        else if (basic_pay === "" || pp_fp === "" || dearness_allowance === "" || hra === "" ||cca === "" || ta_hra === "" || da_on_ta_ra === "" ||
        fpa_kma === "" || deputation_allowance === "" || special_pay === "" || ir_27=== "" || other_allowance_1 === "" || valueGovt === "") {
            setViewMoreFields(false)
            setGrosstotal(null)
           
        }
    }
    function GetTotalDeductions(valueGovtt){
        const other_deductions_3 = parseFloat(valueGovtt || 0);
        if (
            gpf_contribution !== "" && employee_cps_contribution !== "" && sanjeevani_scheme_contribution!== "" &&
            apgli!== "" &&apgli_l!== "" &&gis!== "" &&income_tax!== "" &&professional_tax!== "" &&salary_advance_recovery!== "" &&
            ehf!== "" &&ewf!== "" &&hba_p!== "" &&flag_day!== "" &&other_deductions_1!== "" &&other_deductions_2!== "" &&
            valueGovtt !== "") {
         
            const total_deductions =  gpfcontribution - employeecpscontribution - sanjeevani_schemecontribution - apglivalue -apglil 
            -gisvalue-incometax-professionaltax-salaryadvance_recovery-ehfvalue-ewfvalue-hbap-flagday-other_deductions1-other_deductions2- other_deductions_3;
            setTotalDeductions(total_deductions)
            const netamount=showGrosstotal-total_deductions
            setNetAmount(netamount)
            
        }
        else if (
            gpf_contribution === "" || employee_cps_contribution === "" || sanjeevani_scheme_contribution=== "" ||
            apgli=== "" ||apgli_l=== "" ||gis=== "" ||income_tax=== "" ||professional_tax=== "" ||salary_advance_recovery=== "" ||
            ehf=== "" || ewf=== "" || hba_p=== "" || flag_day=== "" ||other_deductions_1=== "" ||other_deductions_2=== "" ||
            valueGovtt === "") {
            setTotalDeductions(null)
            setNetAmount(null)
 
        }
    }
    

    function GetProfessionalTax(valuegovert){
        const other_allowance2 = parseFloat(valuegovert || 0);
    if (basic_pay !== "" && pp_fp !== "" && dearness_allowance !== "" && hra !== "" &&cca !== "" && ta_hra !== "" && da_on_ta_ra !== "" &&
        fpa_kma !== "" && deputation_allowance !== "" && special_pay !== "" && ir_27!== "" && other_allowance_1 !== "" && valuegovert !== "") {
     
        const gross_total =  basicpaygvt + ppfp + dearnessallowance + Hra +Cca + tahra + da_on_tara + fpakma +  deputationallowance + specialpay + ir+
        other_allowance1 + other_allowance2;
        if (gross_total <= 15000) {
            GovtformIk.setFieldValue("professional_tax", 0)
        } else if (gross_total >= 15001 && gross_total <= 20000) {
            GovtformIk.setFieldValue("professional_tax", 150);
        } else if (gross_total >= 20000) {
            GovtformIk.setFieldValue("professional_tax", 200)
        }

    }
    else if (basic_pay === "" && pp_fp === "" && dearness_allowance === "" && hra === "" &&cca === "" && ta_hra === "" && da_on_ta_ra === "" &&
    fpa_kma === "" && deputation_allowance === "" && special_pay === "" && ir_27=== "" && other_allowance_1 === "" && valuegovert === "") {
        GovtformIk.setFieldValue("professional_tax", "")
    }
}

const fieldGroupsAtFovt = {
    "basicpay": ["professional_tax", "pp_fp", "dearness_allowance", "hra", "cca", "ta_hra", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "ppfp": ["professional_tax", "dearness_allowance", "hra", "cca", "ta_hra", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "dearnessallowance": ["professional_tax", "hra", "cca", "ta_hra", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "hra": ["professional_tax", "cca", "ta_hra", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "cca": ["professional_tax", "ta_hra", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "tahra": ["professional_tax", "da_on_ta_ra", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "daontara": ["professional_tax", "fpa_kma", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "fpakma": ["professional_tax", "deputation_allowance", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "deputationallowance": ["professional_tax", "special_pay", "ir_27", "other_allowance_1", "other_allowance_2"],
    "specialpay": ["professional_tax", "ir_27", "other_allowance_1", "other_allowance_2"],
    "ir27": ["professional_tax", "other_allowance_1", "other_allowance_2"],
    "otherallowance1": ["professional_tax", "other_allowance_2"]
};

function clearFunctionAtFovt(value, fieldnameAtgovt) {
    const fieldsToClear = fieldGroupsAtFovt[fieldnameAtgovt] || [];
    fieldsToClear.forEach(field => {
        GovtformIk.setFieldValue(field, "");
    });
    setGrosstotal(null);
    setViewMoreFields(false);
}
const deductionFieldGroups = {
    "gpfcontribution": ["employee_cps_contribution", "sanjeevani_scheme_contribution", "apgli", "apgli_l", "gis", "income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "employeecpscontribution": ["sanjeevani_scheme_contribution", "apgli", "apgli_l", "gis", "income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "sanjeevanischemecontribution": ["apgli", "apgli_l", "gis", "income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "apgli": ["apgli_l", "gis", "income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "apglil": ["gis", "income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "gis": ["income_tax", "salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"],
    "incometax": ["salary_advance_recovery", "ehf", "ewf", "hba_p", "flag_day", "other_deductions_1", "other_deductions_2", "other_deductions_3"]
};

function clearDeductionsFunction(fieldnameDeduct) {
    const deductionFieldsToClear = deductionFieldGroups[fieldnameDeduct] || [];
    deductionFieldsToClear.forEach(field => {
        GovtformIk.setFieldValue(field, "");
    });
    setTotalDeductions(null);
    setNetAmount(null);
}


function GetemployeesdataAtGovtPayroll() {
    if (propsAtgovt?.govtviewdata === undefined || propsAtgovt?.govtviewdata === null || propsAtgovt?.govtviewdata === "") {
        return;
    }
    else {
        if(propsAtgovt?.govtviewdata[0]?.basic_pay === null || propsAtgovt?.govtviewdata[0]?.basic_pay === undefined){ return;}
    }
   
}

useEffect(() => {
    GetemployeesdataAtGovtPayroll()
}, [propsAtgovt?.govtviewdata[0]])

const isFileUploaded = propsAtgovt?.govtviewdata[0]?.upload_files !== "-";
const fileUploadLink = isFileUploaded ? (
    <Link  style={{ cursor: "pointer", textDecoration: "none" }} 
    onClick={() => downloadUpdatedStagephotoOrFile(propsAtgovt?.govtviewdata[1]?.upload_files)}>
        View</Link>) : "Not Uploaded";
const handleCloseAtGovtPayroll = () => {
    propsAtgovt.Govtnpopup();
    GovtformIk.resetForm();
        setTotalDeductions(null)
        setNetAmount(null)
        setGrosstotal(null)
        setViewMoreFields(false)
}
    return (<>

             <jnb.Modal show={propsAtgovt?.Govtpopupflagvalue} onHide={handleCloseAtGovtPayroll} className="modal-xl" backdrop="static">
                <jnb.Modal.Header className="bg-success bg-opacity-75 text-white" closeButton>
                    <jnb.Modal.Title>Payroll Details </jnb.Modal.Title>
                </jnb.Modal.Header>
                <jnb.Modal.Body>
                <jnb.Row className="px-2 mb-2 pt-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div class="card">
                        <div class="card-header" style={{ backgroundColor: '#2980B9', color: '#ffffff' }}>Employee Details:-</div>
                            <div class="card-body RowColorForLeave">
                                <jnb.Row>
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        <label className="w-100"> <b>ID-Employee Name:</b>&nbsp; <i style={{ textAlign: "justify" }}>1421-Lakshmi</i></label>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        <label className="w-100"> <b>RO Name(ID):</b>&nbsp; <i style={{ textAlign: "justify" }}></i></label>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        <label className="w-100"><b>Zone-Designation:</b>&nbsp;
                                            <i style={{ textAlign: "justify" }}></i></label>
                                    </jnb.Col>
                                </jnb.Row>
                                <jnb.Row>
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        <label className="w-100"><b>Category:</b>&nbsp;
                                            <i style={{ textAlign: "justify" }}></i>
                                        </label>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                        <label className="w-100"> <b>Department:</b>&nbsp; <i style={{ textAlign: "justify" }}></i></label>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <label className="w-100"> <b>Band:</b>&nbsp;
                                            <i style={{ textAlign: "justify" }}></i>
                                        </label>
                                    </jnb.Col>
                                </jnb.Row>
                            </div>
                        </div>

                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
                </jnb.Row>
            <FormikProvider value={GovtformIk}>
                <Form onSubmit={GovtformIk.handleSubmit} onChange={GovtformIk.handleChange} autoComplete="off">
                <jnb.Row className="px-3">
                        <div className="border pt-3 RowColorForWEH">
                    <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Basic Pay:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="basic_pay" className="form-control" onChange={(e)=>{
                               clearFunctionAtFovt(e.target.value,"basicpay");}}
                            onKeyPress={allowNumbersOnly} />
                            <ErrorMessage name="basic_pay" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>PP / FP:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="pp_fp" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"ppfp");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="pp_fp" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>Dearness Allowance (DA):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="dearness_allowance" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"dearnessallowance");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="dearness_allowance" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>House Rent Allowance (HRA):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="hra" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={(e)=>{clearFunctionAtFovt(e.target.value,"hra");}}/>
                            <ErrorMessage name="hra" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>City Compensatory Allowance (CCA):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="cca" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"cca");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="cca" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>TA/AHRA:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="ta_hra" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"tahra");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="ta_hra" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>DA on TA / RA:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="da_on_ta_ra" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"daontara");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="da_on_ta_ra" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>

                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>FPA / KMA:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="fpa_kma" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"fpakma");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="fpa_kma" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Deputation Allowance:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="deputation_allowance" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"deputationallowance");}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="deputation_allowance" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Special Pay:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="special_pay" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"specialpay");}} onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="special_pay" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>IR 27%:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="ir_27" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"ir27");}} onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="ir_27" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Allowance 1:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_allowance_1" className="form-control" onChange={(e)=>{
                                clearFunctionAtFovt(e.target.value,"otherallowance1");}} onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="other_allowance_1" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Allowance 2:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_allowance_2" className="form-control" onChange={(e)=>{
                                GetProfessionalTax(e.target.value);GrossTotal(e.target.value);clearFunctionAtFovt(e.target.value,"otherallowance2");
                                setViewMoreFields(true)}}
                                onKeyPress={allowNumbersOnly}
                                />
                            <ErrorMessage name="other_allowance_2" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                    <b>Gross Total:&nbsp;<b style={{color:"red"}}>{showGrosstotal}</b></b>
                    </jnb.Col>
                    </jnb.Row>
                {viewMoreFields === true ? (<>
                    <jnb.Row className="px-3 pt-1">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>GPF Contribution (GPF):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="gpf_contribution" className="form-control" onChange={(e)=>{
                                clearDeductionsFunction("gpfcontribution");GetCpsEmployer(e.target.value)}}onKeyPress={allowNumbersOnly}/>
                            <ErrorMessage name="gpf_contribution" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee CPS Contribution(CPS):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="employee_cps_contribution" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("employeecpscontribution");}}/>
                            <ErrorMessage name="employee_cps_contribution" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Sanjeevani Scheme Contribution :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="sanjeevani_scheme_contribution" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("sanjeevanischemecontribution");}}/>
                            <ErrorMessage name="sanjeevani_scheme_contribution" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5" >
                            <span className="label-text-style"><b>APGLI :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="apgli" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("apgli");}}/>
                            <ErrorMessage name="apgli" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>APGLI(L) :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="apgli_l" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("apglil");}}/>
                            <ErrorMessage name="apgli_l" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>GIS :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="gis" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("gis");}}/>
                            <ErrorMessage name="gis" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Income Tax :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="income_tax" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("incometax");}}/>
                            <ErrorMessage name="income_tax" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>Professional Tax :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="professional_tax" className="form-control" readOnly="true"onKeyPress={allowNumbersOnly}
                            />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Salary Advance recovery :<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="salary_advance_recovery" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("salaryadvancerecovery");}}/>
                            <ErrorMessage name="salary_advance_recovery" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>EHF:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="ehf" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("ehf");}}/>
                            <ErrorMessage name="ehf" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>EWF:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="ewf" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("ewf");}}/>
                            <ErrorMessage name="ewf" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>HBA (P):<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="hba_p" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("hbap");}}/>
                            <ErrorMessage name="hba_p" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Flag Day:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="flag_day" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("flagday");}}/>
                            <ErrorMessage name="flag_day" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 1:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_1" className="form-control"onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("otherdeductions1");}}/>
                            <ErrorMessage name="other_deductions_1" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 1 -Remarks:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_1_remarks" className="form-control"/>
                            <ErrorMessage name="other_deductions_1_remarks" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 2:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_2" className="form-control" onKeyPress={allowNumbersOnly}
                            onChange={()=>{clearDeductionsFunction("otherdeductions2");}}/>
                            <ErrorMessage name="other_deductions_2" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 2 -Remarks:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_2_remarks" className="form-control"/>
                            <ErrorMessage name="other_deductions_2_remarks" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 3:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_3" className="form-control" onKeyPress={allowNumbersOnly}
                            onChange={(e)=>{GetTotalDeductions(e.target.value);}}/>
                            <ErrorMessage name="other_deductions_3" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Other Deductions 3 -Remarks:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup> 
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="other_deductions_3_remarks" className="form-control"/>
                            <ErrorMessage name="other_deductions_3_remarks" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                    </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-1">
                    <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                    <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style"><b>Total Deductions : <b style={{color:"red"}}>{showTotalDeductions}</b></b></span>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                    <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style"><b>Net Amount:<b style={{color:"red"}}>{showNetAmount}</b></b></span>
                        </jnb.InputGroup>
                    </jnb.Col>
                        </jnb.Row>
                    <jnb.Row className="px-3 pt-1">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>CPS Employer:<font style={{ color: "red" }}>*</font> </b></span>
                        </jnb.InputGroup>
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="text" name="cps_employer" className="form-control"  readOnly="true" onKeyPress={allowNumbersOnly}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Remarks:<font style={{ color: "red" }}>*</font> </b></span>
                            
                            <Field as="textarea" className="form-control" name="remarks" maxLength="400"></Field>
                            <ErrorMessage name="remarks" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Upload Files:<font style={{ color: "red" }}>*</font> </b></span>
                            <input type="file" name="upload_files" className="form-control"
                                                onChange={(e) => {
                                                    let filename = GovtformIk?.values?.emp_id + "_Remarks";
                                                    let path = "HRMS/PAYROLLGOVT/"
                                                    ImageBucketUpload(e, GovtformIk, path, 'upload_files', filename)
                                                }}/>
                                            {fileUploadLink}
                            <ErrorMessage name="upload_files" component="div" className="text-error"/>
                        </jnb.InputGroup> 
                       
                    </jnb.Col>

                    </jnb.Row>
                    </>):null}
                    <jnb.Row className="pt-2 m-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}></jnb.Col>
                    {viewMoreFields === true && (
    <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
        <div className='d-flex flex-row justify-content-end pe-0'>
            <button type="submit" className="btn btn-success btn-sm mb-4">Save</button>
        </div>
    </jnb.Col>
) }
                             <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                            <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="button" className="btn btn-primary btn-sm float-end ms-2 mb-4" onClick={handleCloseAtGovtPayroll}>Close</button>
                            </div>
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                    </jnb.Row>
                </Form>
            </FormikProvider>
            
            </jnb.Modal.Body>
            </jnb.Modal>
        
    </>)
}
export default PayRollEntryFormGovt