import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import * as jnb from "react-bootstrap";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_EMPLOYEE, ROLE_IO } from "../../../CommonUtils/ApplicationRoles";
import FilesBase64 from "../../../CommonUtils/FilesBase64";
export default function GetPreview(props) {
    const Previewlogindata = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const validation = Yup.object().shape({
        finalStatus: Yup.boolean().oneOf([true], "Please Check Self Declaration"),
        strength: Yup.string().required("Please Enter Strengths").trim().min(2, 'Minimum 2 Characters required').max(400,'Maximum 400 Characters allowed').nullable(),
        areas_of_imporvement: Yup.string().required("Please Enter Areas of Improvements").trim().min(2, 'Minimum 2 Characters required').max(400,'Maximum 400 Characters allowed').nullable(),
        carrer_aspirations: Yup.string().required("Please Enter Career Aspirations").trim().min(2, 'Minimum 2 Characters required').max(400,'Maximum 400 Characters allowed').nullable(),
        addl_key_objective: Yup.string().required("Please Enter Key Objective").trim().min(2, 'Minimum 2 Characters required').max(400,'Maximum 400 Characters allowed').nullable(),
    });
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            empId: props.previewempid,
            userId: Previewlogindata?.username,
            finalStatus: false,
            strength: "",
            areas_of_imporvement: "",
            carrer_aspirations: "",
            addl_key_objective: "",
            file_upload:"",
            file_uploadhidden:"",
            upload_remarks:"",
        },
        onSubmit: (values) => {
            finalsubmitAlert(values)
        },
        validationSchema: validation,
    });
  
    const finalsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.iofinalPost(values, props.type)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    window.location.reload();
                                }
                            })
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch((error) => {console.log("error",error)});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showEmployeeData, setEmployeeData] = useState([]);
    const [showpreviewdata, setpreviewdata] = useState([]);
    const [showEmployeeKpStatusData, setEmployeeKpStatusData] = useState([]);

    const [empFile,setEmpFile] = useState('');

    function GetPreviewfunction() {
        let Url = (config.url.COMMON_URL + "employee/kpstatus/"+props.type+"/" + props.previewempid);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setEmployeeData(res.data.employee_data);
                setpreviewdata(res.data.employee_kp_data);
                setEmployeeKpStatusData(res.data.employee_kpstatus_data);
                setEmpFile(res.data.file_upload);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    useEffect(() => {
        GetPreviewfunction();
    }, []);

    return (<>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                {(showpreviewdata !== undefined) && showpreviewdata.map((row, i) => {
                    return (<React.Fragment key={i}>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <legend ><b>{row.kp_short_description}</b></legend>
                                <React.Fragment>
                                    <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Key Performance Areas Description</th>
                                                <th>Self Rating</th>
                                                <th>Self Remarks</th>
                                                {props.type === ROLE_IO && (<>
                                                    <th>Io Rating</th>
                                                    <th>Io Remarks</th></>)}
                                            </tr>
                                        </thead>
                                        <tbody style={{textAlign:"left"}}>
                                            {
                                                showEmployeeKpStatusData !== undefined && showEmployeeKpStatusData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                                    return (
                                                        <React.Fragment key={k}>
                                                            <tr>
                                                                <td>{k + 1}</td>
                                                                <td>{kpidata.kp_indicator_description}</td>
                                                                <td>{kpidata.emp_self_rating}</td>
                                                                <td>{kpidata.emp_self_rating_remarks}</td>
                                                                {props.type === ROLE_IO && (<>
                                                                    <td>{kpidata.io_emp_rating}</td>
                                                                    <td>{kpidata.io_emp_remarks}</td></>)}
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            </jnb.Col>
                        </jnb.Row>

                    </React.Fragment>)
                })}
                {props.type === ROLE_EMPLOYEE ? (<>
                    {showEmployeeData[0]?.self_confirmation_status ? (<>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <tbody style={{textAlign:"left"}}>
                                            <tr>
                                                <td>Strengths</td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_strengths}</b></td>
                                                <td>Areas for Improvement</td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                            </tr>
                                            <tr>
                                                <td> Career Aspirations </td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                                <td>Upload Any Relevant Document</td>
                                                <td> {(empFile!=undefined && empFile!="")?(
                                                <a href={empFile} target="_blank" download={"Employee_Document"}>  View</a>):null} </td>
                                            </tr>
                                            <tr>
                                                <td width="450px" >Additional accomplishments not covered in Key Objectives above</td>
                                                <td colSpan={3}>{showEmployeeKpStatusData[0]?.self_addl_key_objective}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Col>
                        </jnb.Row>
                    </>) : (<>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <span className="label-text"><b>Strengths <font style={{ color: "red" }}>*</font>:</b></span>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                <jnb.InputGroup className="">
                                    <Field as="textarea" className="form-control" name="strength" maxLength="400"></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="strength" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <span className="label-text"><b>Areas for Improvement <font style={{ color: "red" }}>*</font>:</b></span>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                <jnb.InputGroup className="">
                                    <Field as="textarea" className="form-control" name="areas_of_imporvement" maxLength="400"></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="areas_of_imporvement" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                        </jnb.Row>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <span className="label-text"><b>Career Aspirations<font style={{ color: "red" }}>*</font>:</b></span>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                <jnb.InputGroup className="">
                                    <Field as="textarea" className="form-control" name="carrer_aspirations" maxLength="400"></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="carrer_aspirations" component="div" className="text-danger" ></ErrorMessage>

                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <span className="label-text"><b>Upload Any Relevant Document</b></span>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                <jnb.InputGroup className="">
                                <Field type="hidden" id="file_uploadhidden" name="file_uploadhidden" maxLength="15"
                                        className="form-control mb-3"/>
                                    <Field type="file" id="file_upload" name="file_upload" maxLength="15"
                                        className="form-control mb-3"
                                    onChange={(event) => FilesBase64(event, formIk,"file_upload","file_uploadhidden")} 
                                    />
                                </jnb.InputGroup>
                            </jnb.Col>
                        </jnb.Row>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <span className="label-text"><b>Additional accomplishments not covered in Key Objectives above<font style={{ color: "red" }}>*</font></b></span>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                <jnb.InputGroup className="">
                                    <Field as="textarea" className="form-control" name="addl_key_objective" maxLength="400"></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="addl_key_objective" component="div" className="text-danger" ></ErrorMessage>

                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                        </jnb.Row>
                        <h4 style={{ fontSize: '18px' }}>
                            <Field type="checkbox" name="finalStatus"></Field>&nbsp;&nbsp;<b>Are you sure you want to confirm the data<font style={{ color: "red" }}>*</font></b>
                            <ErrorMessage name="finalStatus" component="div" className="text-error ms-4" /></h4>
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="mb-3 text-end">
                                <button type="submit" className="btn btn-success btn-sm" style={{ float: "right" }}>Submit</button>
                            </jnb.Col>
                        </jnb.Row>
                    </>)}
                </>) : (<>{props.type === ROLE_IO ? (<>
                    {showEmployeeData[0]?.io_final_status ? (<>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td>Self</td>
                                                <td>Remarks</td>
                                            </tr>
                                        </thead>
                                        <tbody style={{textAlign:"left"}}>
                                            <tr>
                                                <td>  Strengths </td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_strengths}</b></td>
                                                <td><b>{showEmployeeKpStatusData[0]?.io_strengths}</b> </td>
                                            </tr>
                                            <tr>
                                                <td>Areas for Improvement</td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                                <td><b>{showEmployeeKpStatusData[0]?.io_areas_of_imporvement}</b></td>
                                            </tr>
                                            <tr>
                                                <td> Career Aspirations </td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                                <td><b>{showEmployeeKpStatusData[0]?.io_carrer_aspirations}</b></td>

                                            </tr>
                                            <tr>
                                                <td>Upload Any Relevant Document</td>
                                                <td>{(empFile!=undefined && empFile!="")?(
                                                    <a href={empFile} target="_blank" download={"Employee_Document"}>  View</a>):null}</td>
                                                <td><b>{showEmployeeData[0]?.io_file_remarks}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Additional accomplishments not covered in Key Objectives above</td>
                                                <td>{showEmployeeKpStatusData[0]?.self_addl_key_objective}</td>
                                                <td><b>{showEmployeeKpStatusData[0]?.io_addl_key_objective}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Col>
                        </jnb.Row>
                    </>) : (<>
                        <jnb.Row className="px-0 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Self Remarks</th>
                                                <th>Remarks<font style={{ color: "red" }}>*</font></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{textAlign:"left"}}>
                                            <tr>
                                                <td> Strengths<font style={{ color: "red" }}>*</font></td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_strengths}</b></td>
                                                <td> <jnb.InputGroup className="mb-3p5">
                                                    <Field as="textarea" className="form-control" name="strength" maxLength="400"></Field>
                                                </jnb.InputGroup>
                                                    <ErrorMessage name="strength" component="div" className="text-danger" ></ErrorMessage></td>

                                            </tr>
                                            <tr>
                                                <td>Areas for Improvement<font style={{ color: "red" }}>*</font></td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                                <td><jnb.InputGroup className="mb-3p5">
                                                    <Field as="textarea" className="form-control" name="areas_of_imporvement" maxLength="400"></Field>
                                                </jnb.InputGroup>
                                                    <ErrorMessage name="areas_of_imporvement" component="div" className="text-danger" ></ErrorMessage></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Career Aspirations<font style={{ color: "red" }}>*</font>
                                                </td>
                                                <td><b>{showEmployeeKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                                <td>
                                                    <jnb.InputGroup className="mb-3p5">
                                                        <Field as="textarea" className="form-control" name="carrer_aspirations" maxLength="400"></Field>
                                                    </jnb.InputGroup>
                                                    <ErrorMessage name="carrer_aspirations" component="div" className="text-danger" ></ErrorMessage>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>Upload Any Relevant Document</td>
                                               {(empFile!=undefined && empFile!="")?(<>
                                                <td>  <a href={empFile} target="_blank" download={"Employee_Document"}>  View</a> </td>
                                                <td> <jnb.InputGroup className="mb-3p5">
                                                    <Field as="textarea" className="form-control" name="upload_remarks" maxLength="400"></Field>
                                                </jnb.InputGroup>
                                                    <ErrorMessage name="upload_remarks" component="div" className="text-danger" ></ErrorMessage></td>
                                                </>):(<td colSpan={2}><b>Document not Uploaded</b></td>
                                                )}
                                               
                                            </tr>
                                            <tr>
                                                <td>Additional accomplishments not covered in Key Objectives above<font style={{ color: "red" }}>*</font></td>
                                                <td>{showEmployeeKpStatusData[0]?.self_addl_key_objective}</td>
                                                <td>
                                                    <jnb.InputGroup className="mb-3p5">
                                                        <Field as="textarea" className="form-control" name="addl_key_objective" maxLength="400"></Field>
                                                    </jnb.InputGroup>
                                                    <ErrorMessage name="addl_key_objective" component="div" className="text-danger" ></ErrorMessage>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Col>
                        </jnb.Row>
                        <h4>
                            <Field type="checkbox" name="finalStatus"></Field>&nbsp;&nbsp;<b>Are you sure you want to confirm the data<font style={{ color: "red" }}>*</font></b>
                            <ErrorMessage name="finalStatus" component="div" className="text-error" /></h4>
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={9} xxl={9}> </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ textAlign: "right" }}>
                                <button type="submit" className="btn btn-success btn-sm" style={{ float: "right" }}>Submit</button>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                <br></br>
                            </jnb.Col>
                        </jnb.Row>
                    </>)}
                </>) : ("")}
                </>)}
            </Form>
        </FormikProvider>
    </>)
}