import { FormikProvider, useFormik, Form, Field, ErrorMessage } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import Swal from "sweetalert2";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import CommonPost from "../../../../CommonUtils/CommonPost";
import { useSelector } from "react-redux";
import * as  Yup from "yup";
import CommonEmployeeDetailsForPayroll from "./PaySlips/CommonEmployeeDetailsForPayroll";
const RegularEmpPayRollEntryForm = (propsAtRegularEmpPayRoll) => {
    const regulardata = JSON.parse(localStorage?.getItem("data")) || [];
    const logindetailsAtRegPayRollForm = useSelector((statereg) => statereg?.reducers?.loginreducer?.userLoginDetials);
    const RegularformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            emp_id: regulardata?.emp_id,
            desg_id: regulardata?.designation_id,
            insert_by: logindetailsAtRegPayRollForm?.username,
            salary_revision_date:  "",
            monthly_gross: "",
            basic_pay: "",
            hra: "",
            flexible_allowance: "",
            incentive_bonus:"",
            employee_esic_contribution: "",
            employee_epf_contribution: "",
            professional_tax: "",
            employer_contribution_esic:  "",
            employer_contribution_epf: "",
        },
        validationSchema: Yup.object().shape({
            monthly_gross: Yup.string().required("Required"),
            basic_pay: Yup.string().required("Required"),
            hra: Yup.string().required("Required"),
            flexible_allowance: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            values.monthly_gross = parseFloat(values?.monthly_gross || 0)
            const basicPayAtReg = parseFloat(values?.basic_pay || 0)
            const hraValueAtReg = parseFloat(values?.hra || 0);
            const flexibleAllowanceatReg = parseFloat(values?.flexible_allowance || 0);
            const total = basicPayAtReg + hraValueAtReg + flexibleAllowanceatReg
            if (values?.monthly_gross === total) {
                Swal.fire({
                    title: 'Are you sure you want to submit?',
                    text: 'Please check it once before submitting',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        CommonPost.RegularEmpPaySave(values).then((resAtReg) => {
                                if (resAtReg?.data?.ResponseCode === "01") {
                                    SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                        if (isConfirm.value) {window.location.reload();}
                                    })
                                }
                                else if (resAtReg?.data?.ResponseCode === "04") {
                                    Sweetalert(resAtReg?.data?.ResponseDesc, 'warning');
                                }
                                else if (resAtReg?.data?.ResponseCode === "02") {
                                    Sweetalert(resAtReg?.data?.ResponseDesc, 'warning');
                                }
                                else {
                                    Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                                }
                            })
                            .catch(() => { console.log("Exception Occured 71 ");});
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                    }
                })

            } else {
                Swal.fire("Basic Pay , HRA,FA should be equal to Monthly Gross").then((res) => { if (res?.isConfirmed === true) { } })
            }
        },
    });


    const handleCloseAtRegularEmpPayRoll = () => {
        propsAtRegularEmpPayRoll.regularpopup();
        if (propsAtRegularEmpPayRoll?.viewdata?.basic_pay === null) {
            RegularformIk.resetForm();
            setCalculatedOtherDetails(false)
        } else {
            setCalculatedOtherDetails(true);
            RegularViewData();}
    }

    const [showCalculatedOtherDetails, setCalculatedOtherDetails] = useState(false);

    function CalculatedOtherDetails() {
        const { monthly_gross, basic_pay, hra, flexible_allowance, incentive_bonus } = RegularformIk?.values;
        const monthlyGross_reg = parseFloat(monthly_gross || 0);
        const basicPay_reg = parseFloat(basic_pay || 0);
        const hraValue_reg = parseFloat(hra || 0);
        const flexibleAllowance_reg = parseFloat(flexible_allowance || 0);
        const incentiveBonus_reg = parseFloat(incentive_bonus || 0);
        if (monthly_gross !== "") {
            if (monthlyGross_reg < 21000) {
                const calculatedEmpesic = Math.round(monthlyGross_reg * 0.0075);// 0.75%
                RegularformIk.setFieldValue("employee_esic_contribution", calculatedEmpesic)
                if (calculatedEmpesic > 0) {
                    const calculatedEmplrESIC = Math.round(monthlyGross_reg * 0.0325); // 3.25%
                    RegularformIk.setFieldValue("employer_contribution_esic", calculatedEmplrESIC)
                }else{
                    RegularformIk.setFieldValue("employer_contribution_esic", 0)
                }
            }
            else {
                RegularformIk.setFieldValue("employee_esic_contribution", 0)
                RegularformIk.setFieldValue("employer_contribution_esic", 0)
            }
        }
        if (basic_pay !== "") {
            const calculatedEmpEPF = Math.round(basicPay_reg * 0.12); // 12%
            if(calculatedEmpEPF < 1800){
                RegularformIk.setFieldValue("employee_epf_contribution", "1800");
                RegularformIk.setFieldValue("employer_contribution_epf", "1800");
            }
            else{
                RegularformIk.setFieldValue("employee_epf_contribution", calculatedEmpEPF)
                RegularformIk.setFieldValue("employer_contribution_epf", calculatedEmpEPF)
            }
           
        }
        if (basic_pay !== "" && hra !== "" && flexible_allowance !== "") {
            setCalculatedOtherDetails(true);
            const gross_totalreg =  Math.round(basicPay_reg + hraValue_reg + flexibleAllowance_reg + (incentive_bonus !== "" ? incentiveBonus_reg : 0));
            if (gross_totalreg <= 15000) {
                RegularformIk.setFieldValue("professional_tax", 0);
            } else if (gross_totalreg >= 15001 && gross_totalreg <= 20000) {
                RegularformIk.setFieldValue("professional_tax", 150);
            } else if (gross_totalreg >= 20000) {
                RegularformIk.setFieldValue("professional_tax", 200);
            }
        }
        else if (monthly_gross === "" || basic_pay === "" || hra === "" || flexible_allowance === "" || incentive_bonus === "") {
            RegularformIk.setFieldValue("professional_tax", "")
        }

    }
    const { monthly_gross, basic_pay, hra, flexible_allowance } = RegularformIk?.values;
    async function RegularViewData() {
        if (propsAtRegularEmpPayRoll?.viewdata === undefined || propsAtRegularEmpPayRoll?.viewdata === null || propsAtRegularEmpPayRoll?.viewdata === "") {
            return;
        }
        else {
            if (propsAtRegularEmpPayRoll?.viewdata?.monthly_gross === null || propsAtRegularEmpPayRoll?.viewdata?.monthly_gross === undefined) { 
                setCalculatedOtherDetails(false)
            }
            else {
                RegularformIk.setFieldValue("salary_revision_date", propsAtRegularEmpPayRoll?.viewdata?.salary_revision_date);
                RegularformIk.setFieldValue("monthly_gross", propsAtRegularEmpPayRoll?.viewdata?.monthly_gross);
                RegularformIk.setFieldValue("basic_pay", propsAtRegularEmpPayRoll?.viewdata?.basic_pay);
                RegularformIk.setFieldValue("hra", propsAtRegularEmpPayRoll?.viewdata?.hra);
                RegularformIk.setFieldValue("flexible_allowance", propsAtRegularEmpPayRoll?.viewdata?.flexible_allowance);
                RegularformIk.setFieldValue("incentive_bonus", propsAtRegularEmpPayRoll?.viewdata?.incentive_bonus);
                RegularformIk.setFieldValue("employee_esic_contribution", propsAtRegularEmpPayRoll?.viewdata?.employee_esic_contribution);
                RegularformIk.setFieldValue("employee_epf_contribution", propsAtRegularEmpPayRoll?.viewdata?.employee_epf_contribution);
                RegularformIk.setFieldValue("employee_epf_contribution_arrears", propsAtRegularEmpPayRoll?.viewdata?.employee_epf_contribution_arrears);
                RegularformIk.setFieldValue("professional_tax", propsAtRegularEmpPayRoll?.viewdata?.professional_tax);
                RegularformIk.setFieldValue("employer_contribution_esic", propsAtRegularEmpPayRoll?.viewdata?.employer_contribution_esic);
                RegularformIk.setFieldValue("employer_contribution_epf", propsAtRegularEmpPayRoll?.viewdata?.employer_contribution_epf);
            }
        }
    }

    useEffect(() => {
        if (propsAtRegularEmpPayRoll?.viewdata?.basic_pay === null) {
            setCalculatedOtherDetails(false);
        } else {
            setCalculatedOtherDetails(true);
            RegularViewData();
        }
    }, [propsAtRegularEmpPayRoll?.viewdata?.basic_pay]);
    
    return (<>

        <jnb.Modal show={propsAtRegularEmpPayRoll?.popupflagvalue} onHide={handleCloseAtRegularEmpPayRoll} className="modal-xl" backdrop="static">
            <jnb.Modal.Header className="bg-success bg-opacity-75 text-white" closeButton>
                <jnb.Modal.Title>Payroll Details </jnb.Modal.Title>
            </jnb.Modal.Header>
            <jnb.Modal.Body>
                <jnb.Row className="px-2 mb-2 pt-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                     <CommonEmployeeDetailsForPayroll employeeDetails={regulardata} />
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
                </jnb.Row>
                <FormikProvider value={RegularformIk}>
                    <Form onSubmit={RegularformIk.handleSubmit} onChange={RegularformIk.handleChange} autoComplete="off">
                    <jnb.Row className="px-3">
                        <div className="border pt-3 RowColorForWEH">
                        <jnb.Row className="px-3 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Basic Pay:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="text" name="basic_pay" className="form-control" onKeyPress={allowNumbersOnly}
                                        onChange={(e) => {
                                            RegularformIk.setFieldValue("employee_epf_contribution", "");setCalculatedOtherDetails(false)
                                            RegularformIk.setFieldValue("employer_contribution_epf", "")
                                            RegularformIk.setFieldValue("monthly_gross", "");
                                            RegularformIk.setFieldValue("flexible_allowance", "");
                                            RegularformIk.setFieldValue("hra", "");
                                        }}/>
                                     <ErrorMessage name="basic_pay" component="div" className="text-error"/>
                                </jnb.InputGroup>
                               
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>House Rent Allowance:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="text" name="hra" className="form-control"
                                     onChange={(e)=>{
                                        setCalculatedOtherDetails(false)
                                        RegularformIk.setFieldValue("monthly_gross", "");
                                        RegularformIk.setFieldValue("flexible_allowance", "");
                                        }} />
                                    <ErrorMessage name="hra" component="div" className="text-error"/>
                                </jnb.InputGroup>
                               
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Flexible Allowance:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="text" name="flexible_allowance" className="form-control" 
                                     onChange={(e) => {
                                        setCalculatedOtherDetails(false);
                                        const basicPayValue = parseFloat(basic_pay);
                                        const hraValue = parseFloat(hra);
                                        const flexibleAllowanceValue = parseFloat(e.target.value);
                                        if (!isNaN(basicPayValue) && !isNaN(hraValue) && !isNaN(flexibleAllowanceValue)) {
                                            const monthlyGrossValue = basicPayValue + hraValue + flexibleAllowanceValue;
                                            RegularformIk.setFieldValue("monthly_gross", monthlyGrossValue);
                                        } else {
                                            RegularformIk.setFieldValue("monthly_gross", "");
                                        }
                                    }}/>
                                    <ErrorMessage name="flexible_allowance" component="div" className="text-error"/>
                                </jnb.InputGroup>
                            
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Monthly Gross:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="text" name="monthly_gross" className="form-control" onKeyPress={allowNumbersOnly}
                                        onChange={(e) => {
                                            RegularformIk.setFieldValue("employee_esic_contribution", ""); setCalculatedOtherDetails(false)
                                            RegularformIk.setFieldValue("employer_contribution_esic", "")
                                        }} readOnly="true"/>
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Incentive / Bonus:</b></span>
                                    <Field type="text" name="incentive_bonus" className="form-control" onChange={()=>{setCalculatedOtherDetails(false)}}/>
                                </jnb.InputGroup>
                               
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Salary Revision Date:</b></span>
                                    <Field type="date" name="salary_revision_date" className="form-control"/>
                                </jnb.InputGroup>
                            </jnb.Col>
                        </jnb.Row>
                        </div>
                        </jnb.Row>
                        {(monthly_gross !== "" && basic_pay !== "" && hra !== "" && flexible_allowance !== "" ) ? (
                            <jnb.Row className="px-2 pt-2">
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <button type="button" className="btn btn-primary btn-sm mb-2 "
                                        onClick={() => { CalculatedOtherDetails() }}>Calculate Other Details</button>
                                </jnb.Col>
                            </jnb.Row> 
                            ) : null}
                        {showCalculatedOtherDetails === true ? (
                            <jnb.Row className="px-3 ">
                            <div className="border pt-3 RowColorForMovement">
                                <jnb.Row className="px-3 pt-3">
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Employee ESIC Contribution<font style={{ color: "red" }}>*</font> </b></span>
                                        </jnb.InputGroup>
                                        <jnb.InputGroup className="mb-4p5">
                                            <Field type="text" name="employee_esic_contribution" className="form-control" readOnly={true} />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Employee EPF Contribution<font style={{ color: "red" }}>*</font> </b></span>
                                        </jnb.InputGroup>
                                        <jnb.InputGroup className="mb-4p5">
                                            <Field type="text" name="employee_epf_contribution" className="form-control" readOnly={true} />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Employer ESIC Contribution:<font style={{ color: "red" }}>*</font> </b></span>
                                        </jnb.InputGroup>
                                        <jnb.InputGroup className="mb-4p5">
                                            <Field type="text" name="employer_contribution_esic" className="form-control" readOnly={true} />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Employer EPF Contribution:<font style={{ color: "red" }}>*</font> </b></span>
                                        </jnb.InputGroup>
                                        <jnb.InputGroup className="mb-4p5">
                                            <Field type="text" name="employer_contribution_epf" className="form-control" readOnly={true} />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Professional Tax:<font style={{ color: "red" }}>*</font> </b></span>
                                        </jnb.InputGroup>
                                        <jnb.InputGroup className="mb-4p5">
                                            <Field type="text" name="professional_tax" className="form-control" readOnly={true} />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                </jnb.Row>
                            </div>
                            </jnb.Row>
                        ) : null}
                        <jnb.Row className="px-2 pt-2">
                            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                                <div className='justify-content-end'>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleCloseAtRegularEmpPayRoll}>Close</button>
                                </div>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}></jnb.Col>
                            {(monthly_gross !== "" && basic_pay !== "" && hra !== "" && flexible_allowance !== "" ) ? (
                                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                                    <div className='d-flex flex-row justify-content-end pe-0'>
                                        <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4">Save</button>
                                    </div>
                                </jnb.Col>
                            ) : null}
                        </jnb.Row>
                    </Form>
                </FormikProvider>
            </jnb.Modal.Body>
        </jnb.Modal>
    </>)
}
export default RegularEmpPayRollEntryForm;