import { FormikProvider, useFormik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BsEye } from "react-icons/bs";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import moment from 'moment'
export default function RequestApplicationApproveOrReject() {
    const [GetDetailsAtReqApproveReject, setDetailsAtReqApproveReject] = useState([]);
    const [showErrMsgAtReqApproveReject, setErrMsgAtReqApproveReject] = useState(false)
    const logindetailsAtReqApproveReject = useSelector((statereqrej) => statereqrej?.reducers?.loginreducer?.userLoginDetials);
    const ReqApproveRejectformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            typeofleave: "",
            leave_status_updated_by: "",
            searchbyid: ""
        },
        onSubmit: (values) => {console.log(null)},
    });

    function GetDataAtReqApproveReject() {
        let Url = (config.url.COMMON_URL + "leavesdataFrsRoReport/" + logindetailsAtReqApproveReject?.username);
        CommonAxiosGet(Url).then((resAtreqRej) => {
            if (resAtreqRej?.data?.status === true) {
                setDetailsAtReqApproveReject(resAtreqRej?.data?.leavesdataFrsReportingOfficer)
                setErrMsgAtReqApproveReject(false)
            }
            else {
                setErrMsgAtReqApproveReject(true)
                setDetailsAtReqApproveReject([])
            }
        })
    }
    const fetchDataAtReqApproveReject = useRef(false);
    useEffect(() => {
        if (!fetchDataAtReqApproveReject.current) {
            ReqApproveRejectformIk.resetForm();
            GetDataAtReqApproveReject()
            fetchDataAtReqApproveReject.current = true
        }
    }, []);

    useEffect(() => {
        if (GetDetailsAtReqApproveReject) {
            const reqValuesAtReqApproveReject = GetDetailsAtReqApproveReject?.map(dataReqApproveReject => ({
                emp_id: dataReqApproveReject?.emp_id,
                leave_status_updated_by: dataReqApproveReject?.leave_status_updated_by,
                leaves_type_id: dataReqApproveReject?.leaves_type_id,
                type_of_leave: dataReqApproveReject?.type_of_leave,
                total_no_of_days: dataReqApproveReject?.total_no_of_days,
                leave_status: ""
            }));
            ReqApproveRejectformIk.setValues({ ...ReqApproveRejectformIk?.values, requestcomfirmation: reqValuesAtReqApproveReject });
        }
    }, [GetDetailsAtReqApproveReject]);
    const navigateAtReqApproveReject = useNavigate()
    function navigateToHistoryAtReqApproveReject(empidAtApprReject, empnameAtApprReject) {
        navigateAtReqApproveReject("/EmpPreviousHystoryDetails")
        localStorage.setItem("employee_id", empidAtApprReject);
        localStorage.setItem("employee_name", empnameAtApprReject);
        localStorage.setItem("backbutton", "RO");
    }

    return (<>
        <jnb.Row className="m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leave Approval</li>
                </ol>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className=" pt-2">
            <FormikProvider value={ReqApproveRejectformIk}>
                <Form onSubmit={ReqApproveRejectformIk.handleSubmit} onChange={ReqApproveRejectformIk.handleChange}>
                        <jnb.Row className="px-3 pt-1">
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            {(GetDetailsAtReqApproveReject !== undefined && GetDetailsAtReqApproveReject?.length > 0 && GetDetailsAtReqApproveReject !== "No data found") ? (
                                <jnb.InputGroup>
                                    <Field as="select" className="form-control mb-2" name="typeofleave" onChange={event => {
                                        ReqApproveRejectformIk.setFieldValue("searchbyid", "")}}>
                                        <option value="">--Search here--</option>
                                        <option value="Leave">Leave</option>
                                        <option value="Movement">Regularization</option>
                                        <option value="WFH">Work From Home</option>
                                    </Field>
                                </jnb.InputGroup>
                                ) : null}
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                            {(GetDetailsAtReqApproveReject !== undefined && GetDetailsAtReqApproveReject?.length > 0 && GetDetailsAtReqApproveReject !== "No data found") ? (
                                <jnb.InputGroup>
                                    <Field name="searchbyid" type="text"className="form-control mb-2"
                                        placeholder="Search by Employee ID"/>
                                </jnb.InputGroup>) : null}
                            </jnb.Col>
                        </jnb.Row>
                    <jnb.Row className="px-3 pt-2">
                        {(GetDetailsAtReqApproveReject !== undefined && GetDetailsAtReqApproveReject?.length > 0 && GetDetailsAtReqApproveReject !== "No data found") ? (
                            <table className="table  table-bordered  table-responsive  leaveapproval-table">
                                <thead>
                                    <tr>
                                        <th align="center" >S.No</th>
                                        <th >ID</th>
                                        <th >Name</th>
                                        <th >Leave Request</th>
                                        <th >Details of <br></br>Leaves for Apply</th>
                                        <th >Type of Leave</th>
                                        <th >Visiting Office Name - <br />Remarks</th>
                                        <th>Approve/Rejected</th>
                                        <th >Previous <br />Data History</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                            {GetDetailsAtReqApproveReject?.filter((elementAtapproveReject) => {
                    const typeOfLeaveApproveReject = elementAtapproveReject?.type_of_leave?.toLowerCase() === "Movement" ? "regularization" : elementAtapproveReject?.type_of_leave?.toLowerCase();
                    const selectedLeaveTypeAtapprove = ReqApproveRejectformIk?.values?.typeofleave?.toLowerCase();
                    const empIdSearchsAtReqApprove = ReqApproveRejectformIk?.values?.searchbyid?.toLowerCase();
                    const matchesTypeAtReqApprove = selectedLeaveTypeAtapprove ? typeOfLeaveApproveReject?.includes(selectedLeaveTypeAtapprove) : true;
                    const matchesEmpIdAtReqApprove = empIdSearchsAtReqApprove ? elementAtapproveReject?.emp_id?.toString()?.toLowerCase()?.includes(empIdSearchsAtReqApprove) : true;
                    const matchesDateOrTypeNameAtReqApprove =
                    elementAtapproveReject?.from_date?.toString()?.toLowerCase()?.includes(empIdSearchsAtReqApprove) ||
                    elementAtapproveReject?.to_date?.toString()?.toLowerCase()?.includes(empIdSearchsAtReqApprove) ||
                    elementAtapproveReject?.type_name?.toLowerCase()?.includes(empIdSearchsAtReqApprove) ||
                    elementAtapproveReject?.halfday_from_time_to_time?.toLowerCase()?.includes(empIdSearchsAtReqApprove);
                    return matchesTypeAtReqApprove && (matchesEmpIdAtReqApprove || matchesDateOrTypeNameAtReqApprove);
                })?.length > 0 ? (
                    GetDetailsAtReqApproveReject?.filter((elementAtReqFilter) => {
                        const typeOfLeaveReqFilter = elementAtReqFilter?.type_of_leave?.toLowerCase() === "Movement" ? "regularization" : elementAtReqFilter?.type_of_leave?.toLowerCase();
                        const selectedLeaveTypeReqFilter = ReqApproveRejectformIk?.values?.typeofleave?.toLowerCase();
                        const empIdSearchReqFilter = ReqApproveRejectformIk?.values?.searchbyid?.toLowerCase();
                        const matchesTypeReqFilter = selectedLeaveTypeReqFilter ? typeOfLeaveReqFilter?.includes(selectedLeaveTypeReqFilter) : true;
                        const matchesEmpIdReqFilter = empIdSearchReqFilter ? elementAtReqFilter?.emp_id?.toString()?.toLowerCase()?.includes(empIdSearchReqFilter) : true;
                        const matchesDateOrTypeNameReqFilter =
                        elementAtReqFilter?.from_date?.toString()?.toLowerCase()?.includes(empIdSearchReqFilter) ||
                        elementAtReqFilter?.to_date?.toString()?.toLowerCase()?.includes(empIdSearchReqFilter) ||
                            elementAtReqFilter?.type_name?.toLowerCase()?.includes(empIdSearchReqFilter) ||
                            elementAtReqFilter?.halfday_from_time_to_time?.toLowerCase()?.includes(empIdSearchReqFilter);
                        return matchesTypeReqFilter && (matchesEmpIdReqFilter || matchesDateOrTypeNameReqFilter);
                    }).map((dataAtreqApprReject, reqIndex) => {
                        const rowClassName = dataAtreqApprReject?.type_of_leave === "Leave"
                        ? 'RowColorForLeave'
                        : dataAtreqApprReject?.type_of_leave === "Movement"
                            ? 'RowColorForMovement'
                            : dataAtreqApprReject?.type_of_leave === "WFH"
                                ? 'RowColorForWEH'
                                : '';
        
                    return (
                        <React.Fragment key={reqIndex}>
                                                <tr className={rowClassName}>
                                                   <td>
                                                        {reqIndex + 1}</td>
                                                    <td>{dataAtreqApprReject?.emp_id}</td>
                                                    <td>{dataAtreqApprReject?.emp_name}</td>
                                                    <td>{dataAtreqApprReject?.type_of_leave === "Movement" ? <>Regularization</> : <>{dataAtreqApprReject?.type_of_leave}</>}</td>
                                                    <td>
                                                        {dataAtreqApprReject?.no_of_days !== "" ? (<>
                                                            {dataAtreqApprReject?.no_of_days === "One Day" && (<>
                                                                {moment(dataAtreqApprReject?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                            </>)}{dataAtreqApprReject?.no_of_days === "More Than One Day" && (<>
                                                                {moment(dataAtreqApprReject?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} &nbsp;to&nbsp;
                                                                {moment(dataAtreqApprReject?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</>)}
                                                            {dataAtreqApprReject?.no_of_days === "One Half Day" && (<>
                                                                {moment(dataAtreqApprReject?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} ({dataAtreqApprReject?.halfday_from_time_to_time})</>)}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </>) : (<></>)}
                                                    </td>
                                                    <td>{dataAtreqApprReject?.type_name === "Movement" ? <>Regularization</> : <>{dataAtreqApprReject?.type_name}</>}</td>
                                                    <td>
                                                        {(dataAtreqApprReject?.type_of_leave === "Leave" || dataAtreqApprReject?.type_of_leave === "WFH" || dataAtreqApprReject?.type_of_leave === "Movement") ? (<>
                                                            <td>{dataAtreqApprReject?.office_name}<pre></pre>{dataAtreqApprReject?.remarks}</td>
                                                        </>) : (<td></td>)}
                                                    </td>
                                                    <td>

                                                        {dataAtreqApprReject?.leave_final_status !== null ? (<>
                                                            {dataAtreqApprReject?.leave_final_status === "Approved" && (<><b style={{ color: "green" }}>Approved</b></>)}
                                                            {dataAtreqApprReject?.leave_final_status === "Rejected" && (<><b style={{ color: "red" }}>Rejected</b>&nbsp;-<b>{dataAtreqApprReject?.leave_status_remakrs}</b></>)}
                                                            {dataAtreqApprReject?.leave_final_status === "pending" && (<><b>Pending</b></>)}
                                                        </>) : (<></>)}</td>
                                                    <td className="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            onClick={(e) => { navigateToHistoryAtReqApproveReject(dataAtreqApprReject?.emp_id, dataAtreqApprReject?.emp_name) }}><BsEye /></button>
                                                    </td>
                                                </tr>
                                        </React.Fragment>)
})
                ) : (
                    <tr>
                        <td colSpan="9" className="text-center text-danger"><b style={{ color: "red" }}>No data found</b></td>
                    </tr>
                )}
                                </tbody>
                            </table>
                    ) : (showErrMsgAtReqApproveReject === true && (
                                <center className="mt-5"><b style={{ color: "red" }}>**************** No Data Found **************</b></center>
                            ) )}
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}