import { ErrorMessage, Field, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { BiTrash } from 'react-icons/bi';
import { VscDiffAdded } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import Sweetalert, { successAlert2 } from '../../../../CommonUtils/SweetAlerts';
import Swal from 'sweetalert2';
import CommonPost from '../../../../CommonUtils/CommonPost';
import * as jnb from "react-bootstrap";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import CommonReactTable from '../../../../CommonUtils/CommonReactTable';
export default function DuesWithOrWithoutDetailsForm(){
    const logindetailsAtDuesDtlsform = useSelector((stateAtDues) => stateAtDues?.reducers?.loginreducer?.userLoginDetials);
    const [showAssetsDtlsform, setAssetsDtlsform] = useState([])
    const backbuttonAtDtlsform = localStorage?.getItem("backbutton")
    const navigateAtDtlsform = useNavigate();
    const emp_id_Dues = localStorage?.getItem("employee_id")
    const [showpopupAtDtlsform, setPopupAtDtlsform] = useState(false);
    const [showAssetsDetailsForm, setAssetDetailsForm] = useState([]);
    const [InsertedAssetDetailsForm, setInsertedAssetDetailsForm] = useState([])
    const [showErrMsgAtDuesForm, setErrMsgAtDuesForm] = useState(false)
    function handleCloseAtDuesForm() { setPopupAtDtlsform(false) }
    const DuesFormformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            insert_by:logindetailsAtDuesDtlsform?.username,
            emp_id: localStorage?.getItem("employee_id"),
            desg_id: localStorage?.getItem("designation_id"),
            assetdetails: [{
                asset_id: "",
                item_id: "",
                asset_given_date: "",
                remarks: "",
                asset_return: "",
                asset_return_date: "",
                asset_return_status: "",
                asset_return_remarks: "",
            }],
            givenassetdetails: [{
                asset_return: "",
                asset_return_date: "",
                asset_return_status: "",
                asset_return_remarks: "",
            }]
        },
        validationSchema: Yup.object().shape({
            assetdetails: Yup.array().of(Yup.object().shape({
                asset_id: Yup.string().required("Required"),
                asset_given_date: Yup.string().required(" Required"),
                remarks: Yup.string().required(" Required"),
            }))
        }),
        onSubmit: (values) => {
            assetsDetailsSave(values)
        },
    });
    function GetassetsData() {
        let Url = (config?.url?.COMMON_URL + "assetmstGet");
        CommonAxiosGet(Url).then((resAtAssets) => {
            if (resAtAssets?.data?.status === true) {setAssetsDtlsform(resAtAssets?.data?.assetmstGet)}
            else {setAssetsDtlsform([])}
        })
    }
    function GetAssetDetailsByEmpData() {
        let Url = (config?.url?.COMMON_URL + "assetsdeatilsbyemp/" + emp_id_Dues);
        CommonAxiosGet(Url).then((resAtAssetsForm) => {
            if (resAtAssetsForm?.data?.status === true) {
                const assetReturnData = resAtAssetsForm?.data?.assetsdeatilsbyemp?.filter((item) => item?.asset_return === "true")
                const assetsGivenData = resAtAssetsForm?.data?.assetsdeatilsbyemp?.filter((item) => item?.asset_return === null)
                setAssetDetailsForm(assetReturnData);
                setInsertedAssetDetailsForm(assetsGivenData);
                setErrMsgAtDuesForm(false)
            }
            else {
                setAssetDetailsForm([])
                setErrMsgAtDuesForm(true)
            }
        })
    }
    const AssetsFormcolumns = [
        {
            Header: "S.No",
            accessor: (row,assetsIndex) => (<>{assetsIndex+ 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: "Name of the Item",
            accessor: 'asset_name',
        },
        {
            Header: "Item ID",
            accessor: 'item_id',
        },
        {
            Header: "Item Given Date",
            accessor: 'asset_given_date',
        },

        {
            Header: "Remarks",
            accessor: 'remarks',
        },
        {
            Header: "Items Given Back",
            accessor: (row,assetsFormIndex) => (
                <div>
                    <Field type="hidden" name="insert_by"/>
                    <Field type="radio" name={`[givenassetdetails[${assetsFormIndex}].asset_return`} value="true"
                    onChange={(e) => {
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_date`, "");
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_status`, "");
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_remarks`, "");
                        }} />&nbsp;<b style={{ fontSize: "15px" }}>Yes</b>&emsp;
                    <Field type="radio" name={`[givenassetdetails[${assetsFormIndex}].asset_return`} value="false"
                    onChange={(e) => {
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_date`, "");
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_status`, "");
                        DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_remarks`, "");
                    }}/>&nbsp;<b style={{ fontSize: "15px" }}>No</b>
                    {DuesFormformIk?.values?.givenassetdetails[assetsFormIndex]?.asset_return === 'true' ? (
                    <jnb.Row className="px-3 pt-1">
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className='pt-1'>
                            <jnb.InputGroup className="mt-4">
                                <span className="label-text-style">Date</span>
                                <Field type="date" className="form-control" name={`[givenassetdetails[${assetsFormIndex}].asset_return_date`} ></Field>
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                            <jnb.InputGroup className="mt-4" >
                                <span className="label-text-style">Given Back with good shape</span>
                                <div className='border form-control'>
                                    <Field type="radio" name={`[givenassetdetails[${assetsFormIndex}].asset_return_status`} value="true"
                                    onChange={(e) => { DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_remarks`, ""); }}
                                    />&nbsp;Yes&emsp;
                                    <Field type="radio" name={`[givenassetdetails[${assetsFormIndex}].asset_return_status`} value="false"
                                    onChange={(e) => { DuesFormformIk?.setFieldValue(`givenassetdetails[${assetsFormIndex}].asset_return_remarks`, ""); }}
                                    />&nbsp;No
                                </div>
                            </jnb.InputGroup>
                        </jnb.Col>
                        {DuesFormformIk?.values?.givenassetdetails[assetsFormIndex]?.asset_return_status === 'false' ? (
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            <jnb.InputGroup className="mt-4" >
                                <span className="label-text-style">Remarks</span>
                                <Field as="textarea" className="form-control mb-2" style={{ height: '30px' }} name={`[givenassetdetails[${assetsFormIndex}].asset_return_remarks`}></Field>
                                <ErrorMessage name={`[givenassetdetails[${assetsFormIndex}].asset_return_remarks`} component="div" className="text-danger" ></ErrorMessage>
                                </jnb.InputGroup>
                            </jnb.Col>
                        ) : null}
                        </jnb.Row>
                        ) : null}
                </div>
            ),
        },
        {
            Header: "Update",
            accessor: (row, index) => (
                <div>
                    <button type="button" className="bg-primary p-2 text-white bg-opacity-75 btn-sm rounded" style={{textAlign:"center"}}
                    onClick={() => { DuesWithorWithoutSave(DuesFormformIk?.values?.givenassetdetails[index], row) }}>
                    Update </button> 
                </div>
            ),
        },   
    ];

    const Returnedcolumns = [
        {
            Header: "S.No",
            accessor: (row,returnedIndex) => (<>{returnedIndex + 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: 'Name of the Item',
            accessor: "asset_name",
        },
        {
            Header: 'Item Given Date',
            accessor: "asset_given_date",
        },
        {
            Header: 'Remarks',
            accessor: "remarks",
        },
        {
            Header: 'Closed Items Details',
            accessor: (returnedrow,returnedIndex) => (
                <div style={{ fontSize: "15px" }} className=" p-1">
                     Date:{returnedrow?.asset_return_date} &nbsp;Given Back with good shape:&nbsp;
                     {returnedrow?.asset_return_status === 'false' ? (<> No &nbsp;Remarks:{returnedrow?.asset_return_remarks}
                     </>) : "Yes"}
                </div>
            ),
        },
];
    const fetchDataAtAssetsForm = useRef(false)

    useEffect(() => {
        if (!fetchDataAtAssetsForm.current) {
            GetassetsData();
            GetAssetDetailsByEmpData()
            fetchDataAtAssetsForm.current = true;
        }
    }, [emp_id_Dues]);
    function assetsDetailsSave(values) {
        const readOnlyFalseValues = values?.assetdetails?.filter(detail => !detail?.readonly);
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.assetsDataSave({ ...values, assetdetails: readOnlyFalseValues }).then((resSave) => {
                    if (resSave?.data?.ResponseCode === "01") {
                        Swal.fire({
                            text: resSave?.data?.ResponseDesc,
                            icon: "success",
                            backdrop: false,
                        }).then((refresh) => {
                            if (refresh.isConfirmed) {window.location.reload(true);}
                        })
                    }
                    else if (resSave?.data?.ResponseCode === "02") {
                        Sweetalert(resSave?.data?.ResponseDesc, 'warning');
                    }
                    else {
                        Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                    }
                }).catch(() => { console.log("Exception Occured 71 ");});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    }

    function navigateToduesDetails() {
        if(backbuttonAtDtlsform === "noofdues"){navigateAtDtlsform("/duesdetails")}
        else if (backbuttonAtDtlsform === "witoutdues") {navigateAtDtlsform("/withoutduesdetails")}
        else {navigateAtDtlsform("/totalemployeesassetdetails")}
    }

    function showClosedAssetsForm() {setPopupAtDtlsform(true)}

    function DuesWithorWithoutSave(valuesAtDuesForm, dataAtDuesForm) {
        if (valuesAtDuesForm?.asset_return === "null" || valuesAtDuesForm?.asset_return === "" || valuesAtDuesForm?.asset_return === undefined) {
            Swal.fire("Please select item given back","info")
        }
        else if ((valuesAtDuesForm?.asset_return !== "null" || valuesAtDuesForm?.asset_return !== "") && valuesAtDuesForm?.asset_return === "true" && valuesAtDuesForm?.asset_return_date === "") {
            Swal.fire("item reuturn date is Required","info")
        }
        else if ((valuesAtDuesForm?.asset_return !== "null" || valuesAtDuesForm?.asset_return !== "") && valuesAtDuesForm?.asset_return === "true" && valuesAtDuesForm?.asset_return_status === "") {
            Swal.fire("item return status is Required","info")
        }
        else if (((valuesAtDuesForm?.asset_return !== "null" || valuesAtDuesForm?.asset_return !== "") && valuesAtDuesForm?.asset_return === "true") && (
            (valuesAtDuesForm?.asset_return_status !== "null" || valuesAtDuesForm?.asset_return_status !== "") && valuesAtDuesForm?.asset_return_status === "false"
        ) && valuesAtDuesForm?.asset_return_remarks === "") {
            Swal.fire("item return remarks is Required","info")}
        else {
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    const savevaluesAtDuesForm = {
                        "emp_id": localStorage?.getItem("employee_id"),
                        "asset_id": dataAtDuesForm?.asset_id,
                        "asset_return": valuesAtDuesForm?.asset_return,
                        "asset_return_date": valuesAtDuesForm?.asset_return_date,
                        "asset_return_status": valuesAtDuesForm?.asset_return_status,
                        "asset_return_remarks": valuesAtDuesForm?.asset_return_remarks,
                    }
                    CommonPost.updateassetsData(savevaluesAtDuesForm).then((resDuesform) => {
                        if (resDuesform?.data?.ResponseCode === "01") {successAlert2(resDuesform?.data?.ResponseDesc)}
                        else if (resDuesform?.data?.ResponseCode === "02") {Sweetalert(resDuesform?.data?.ResponseDesc, 'warning');}
                        else {Swal.fire('Canceled', 'Something went wrong,Please check', 'error');}
                    }).catch(() => {  console.log("Exception Occured 71 ");});
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        }
    }

    return (<>
         <jnb.Row className=" m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={9}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Employee Dues Report</li>
                    </ol>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                    {showAssetsDetailsForm?.length > 0 ? (
                        <button type="button" className="p-2 bg-success-subtle text-success-emphasis rounded"style={{ float: "right" }}
                            onClick={() => { showClosedAssetsForm() }}>Returned Items</button>) : null}
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                    <button type="button" className="bg-success p-2 text-white bg-opacity-75 btn-sm rounded" style={{ float: "right" }}
                        onClick={() => { navigateToduesDetails() }}>Back</button>
                </jnb.Col>
            </jnb.Row>

        <div className="p-3 pb-0 mb-3">
            <jnb.Row className="px-0 mb-2 pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div class="card">
                        <div class="card-header" style={{ backgroundColor: '#2980B9', color: '#ffffff',textAlign:'center' }}>Employee Details</div>
                        <div class="card-body">
                            <jnb.Row>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                    <label className="w-100"> <b>ID:</b>&nbsp; <i style={{ textAlign: "justify" }}>{localStorage?.getItem("employee_id")}</i></label>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                                    <label className="w-100"> <b>Employee Name:</b>&nbsp; <i style={{ textAlign: "justify" }}>{localStorage?.getItem("employee_name")}</i></label>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                    <label className="w-100"><b>Designation:</b>&nbsp;
                                        <i style={{ textAlign: "justify" }}>{localStorage?.getItem("designation_name")}</i></label>
                                </jnb.Col>
                            </jnb.Row>
                        </div>
                    </div>
                </jnb.Col>
            </jnb.Row>
            <FormikProvider value={DuesFormformIk}>
                <Form onSubmit={DuesFormformIk.handleSubmit} onChange={DuesFormformIk.handleChange}>
                    <div className='border pt-2'>
                    <div className="table-responsive pt-2 px-3">
                        <table className="table table-condensed table-bordered  table-responsive">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Items</th>
                                    <th>Item ID</th>
                                    <th>Item Given Date</th>
                                    <th>Remarks</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                <FieldArray name="assetdetails">
                                    {(formikFieldArrayDtlsForm) => {
                                        return (
                                            formikFieldArrayDtlsForm?.form?.values.assetdetails.map(
                                                (qual, DtlsFormindex) => {
                                                    return (
                                                        <React.Fragment key={DtlsFormindex}>
                                                            <tr>
                                                                <td>{DtlsFormindex + 1}</td>
                                                                <td>
                                                                    <Field type="hidden" className="form-control" name={`[assetdetails[${DtlsFormindex}].status`} />
                                                                    <Field as="select" className='form-control' name={`[assetdetails[${DtlsFormindex}].asset_id`}
                                                                        disabled={DuesFormformIk?.values?.assetdetails[DtlsFormindex]?.readonly}>
                                                                        <option value="">--Select--</option>
                                                                        {showAssetsDtlsform !== undefined && showAssetsDtlsform?.map((aname, aIndex) => {
                                                                            return <option key={aIndex} value={aname.asset_id}>{aname.asset_name}</option>
                                                                        })}
                                                                    </Field>
                                                                    <ErrorMessage name={`[assetdetails[${DtlsFormindex}].asset_id`} component="div" className="text-danger"></ErrorMessage>
                                                                </td>
                                                                <td>
                                                                    <Field type="text" className="form-control" name={`[assetdetails[${DtlsFormindex}].item_id`}
                                                                        disabled={DuesFormformIk?.values?.assetdetails[DtlsFormindex]?.readonly}></Field>
                                                                    <ErrorMessage name={`[assetdetails[${DtlsFormindex}].item_id`} component="div" className="text-danger"></ErrorMessage>
                                                                </td>
                                                                <td>
                                                                    <Field type="date" className="form-control" name={`[assetdetails[${DtlsFormindex}].asset_given_date`}
                                                                        disabled={DuesFormformIk?.values?.assetdetails[DtlsFormindex]?.readonly}></Field>
                                                                    <ErrorMessage name={`[assetdetails[${DtlsFormindex}].asset_given_date`} component="div" className="text-danger"></ErrorMessage>
                                                                </td>
                                                                <td>
                                                                    <Field as="textarea" className="form-control mb-2" style={{ height: '30px' }} name={`[assetdetails[${DtlsFormindex}].remarks`}
                                                                        disabled={DuesFormformIk?.values?.assetdetails[DtlsFormindex]?.readonly}></Field>
                                                                    <ErrorMessage name={`[assetdetails[${DtlsFormindex}].remarks`} component="div" className="text-danger"></ErrorMessage>
                                                                </td>
                                                                {(DtlsFormindex) ? <>
                                                                    <td><button type="button" className="button-titile btn btn-sm btn-danger rounded"
                                                                        onClick={() => { formikFieldArrayDtlsForm?.remove(DtlsFormindex); }} >
                                                                        <BiTrash></BiTrash>
                                                                    </button> </td></> : <td></td>}
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                        )
                                    }}
                                </FieldArray>
                                <tr >
                                    <td align="right" colSpan={7}>
                                        <FieldArray name="assetdetails">
                                            {(formikFieldArrayDtlsForm) => {
                                                return formikFieldArrayDtlsForm?.form?.values?.assetdetails?.map(
                                                    (LJP, DtlsFormindex) => {
                                                        return (
                                                            <React.Fragment key={DtlsFormindex}>
                                                                {(DtlsFormindex === 0) ? <>
                                                                    <button type="button" className="button-titile btn btn-sm btn-success rounded"
                                                                        onClick={() => {
                                                                            formikFieldArrayDtlsForm.push({
                                                                                asset_id: "",
                                                                                item_id: "",
                                                                                asset_given_date: "",
                                                                                remarks: "",
                                                                            });
                                                                        }}>
                                                                        <VscDiffAdded></VscDiffAdded> </button> </> : ''}
                                                            </React.Fragment>
                                                        )})
                                            }}
                                        </FieldArray>
                                    </td>
                                </tr>
                            </tbody >
                        </table>
                        <jnb.Row className="pt-2 m-0">
                            <div className='d-flex flex-row justify-content-end pe-0'>
                            <button type="submit" className="btn rounded-0 btn-success btn-sm mb-3" style={{ float: "right" }}>Save</button>
                            </div>
                        </jnb.Row>
                    </div>
                    </div>
                    <div className="table-responsive pt-4">
                    {InsertedAssetDetailsForm?.length > 0 ? (
                        <CommonReactTable data={InsertedAssetDetailsForm} columns={AssetsFormcolumns} showFooter="false" filename="Employee Dues Report" 
                                        headerName={`Employee Dues Report`} />
                    ) : (<>{showErrMsgAtDuesForm === true ? (<center><b style={{ color: "red" }}>*********************No Data Found******************* </b></center>) : null}</>)}
                        </div>
                    <jnb.Modal show={showpopupAtDtlsform} onHide={handleCloseAtDuesForm} className="modal-xl" backdrop="static">
                        <jnb.Modal.Header className="bg-success bg-opacity-75 text-white">
                            <jnb.Modal.Title>Returned Items Details </jnb.Modal.Title>
                        </jnb.Modal.Header>
                        <jnb.Modal.Body>
                            {showAssetsDetailsForm?.length > 0 ? (
                                <CommonReactTable data={showAssetsDetailsForm} columns={Returnedcolumns} showFooter="false" filename="Employee Dues Report" headerName={`Employee Dues Report`} />) : null}
                            <jnb.Row className="px-4 pt-2">
                                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                                    <div className='justify-content-end'>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={handleCloseAtDuesForm}>Close</button>
                                    </div>
                                </jnb.Col>
                            </jnb.Row>
                        </jnb.Modal.Body>
                    </jnb.Modal>
                </Form>
            </FormikProvider>
        </div>
    </>)
}
