import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import Swal from 'sweetalert2';
import CommonReactTable from '../../../../CommonUtils/CommonReactTable';
export default function NoOfDuesDetails() {
    const [showNoOfDuesDetailsOfEmp, setNoOfDuesDetailsOfEmp] = useState([]);
    const [showErrMsgAtDuesDtls, setErrMsgAtDuesDtls] = useState(false);
    const [showNoOfAssetsDetails, setNoOfAssetDetails] = useState([]);
    const [showpopupAtNoOfDues, setPopupAtNoOfDues] = useState(false);
    const navigateNoOfDues = useNavigate();
    function navigatetoEmpNoOfDues(empidAtNoofSues, empnameAtNoofSues, designation_nameAtNoofSues, designation_idAtNoofSues, count, asset_idAtNoofSues) {
        localStorage.setItem("employee_id", empidAtNoofSues);
        localStorage.setItem("employee_name", empnameAtNoofSues);
        localStorage.setItem("designation_name", designation_nameAtNoofSues);
        localStorage.setItem("designation_id", designation_idAtNoofSues);
        localStorage.setItem("asset_id", asset_idAtNoofSues);
        localStorage.setItem("backbutton", "noofdues");
        navigateNoOfDues("/withduesorwithoutduesdetails", { state: { count: count } })}
    function showClosedAssetsAtNoOfDues() {setPopupAtNoOfDues(true)}
    function handleCloseAtNoOfDues() { setPopupAtNoOfDues(false) }
    function GetDataAtNoOfDues() {
        let Url = (config?.url?.COMMON_URL + "assetsreport/withdues");
        CommonAxiosGet(Url).then((resAtNoOfDues) => {
            if (resAtNoOfDues?.data?.status === true) {setNoOfDuesDetailsOfEmp(resAtNoOfDues?.data?.assetsreport);
                setErrMsgAtDuesDtls(false)}
            else {setNoOfDuesDetailsOfEmp([]);setErrMsgAtDuesDtls(true)}})}
    function GetAssetDetailsByEmpData(emp_id_AtNoofDues) {
        let Url = (config?.url?.COMMON_URL + "assetsdeatilsbyemp/" + emp_id_AtNoofDues);
        CommonAxiosGet(Url).then((resNumDues) => {
            if (resNumDues?.data?.status === true) {
                const assetReturnData = resNumDues?.data?.assetsdeatilsbyemp?.filter((item) => item?.asset_return === "true")
                setNoOfAssetDetails(assetReturnData);}
            else {setNoOfAssetDetails([])}})}

    const fetchDataAtNoOfDues = useRef(false)
    useEffect(() => {
        if (!fetchDataAtNoOfDues.current) {GetDataAtNoOfDues()
            fetchDataAtNoOfDues.current = true}
    }, []);

    function navigateAtNoOfDues() { navigateNoOfDues("/employeesinfo") }

    const NoOfDuescolumns = [
        {Header: "S.No",
            accessor: (row,NoOFIndex) => (<>{NoOFIndex + 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,},
        {Header: 'ID',accessor: "emp_id",},
        {Header: 'Employee Name',accessor: "emp_name",},
        {Header: 'Designation',accessor: "designation_name",},
        {
            Header: 'No.Of Dues',
            columns: [
                {
                    Header: 'Returned',
                    accessor: (row,i) => (
                        <div style={{ fontSize: "15px" }} className="p-1">
                            <button type="button" className="btn btn-link btn-sm"
                                onClick={() => {if (row?.inactice !== 0) {
                                        GetAssetDetailsByEmpData(row?.emp_id); 
                                        showClosedAssetsAtNoOfDues();
                                    } else {Swal.fire("No Data found", "", "info");}}}>{row?.inactice}
                            </button>
                        </div>),},
                {Header: 'Given',
                    accessor: (row,i) => (
                        <div style={{ fontSize: "15px" }} className="p-1">
                            <button type="button" className="btn btn-link btn-sm"
                                onClick={() => {
                                    navigatetoEmpNoOfDues(row?.emp_id, row?.emp_name, row?.designation_name, row?.designation_id, row?.count, row?.asset_id);
                                }}>{row?.actice}</button>
                        </div>),},],},];
    const NoOfDuesReturnedcolumns = [{
            Header: "S.No",
            accessor: (row,ReturnedIndex) => (<>{ReturnedIndex+ 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {Header: 'Name of the Item',accessor: "asset_name",},
        {Header: 'Item Given Date',accessor: "asset_given_date",},
        {Header: 'Remarks',accessor: "remarks",},
        {Header: 'Closed Items Details',
            accessor: (Returnedrow,i) => (
                <div style={{ fontSize: "15px" }} className=" p-1">
                    Date:{Returnedrow?.asset_return_date} &nbsp;Given Back with good shape:&nbsp;
                    {Returnedrow?.asset_return_status === 'false' ? (<> No &nbsp;Remarks:{Returnedrow?.asset_return_remarks}
                    </>) : "Yes"}</div>),},];
    return (
        <div>
            <jnb.Row className=" m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Employee Dues Report</li>
                    </ol>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                    <button type="button" className="bg-success p-2 text-white btn-sm rounded" style={{ float: "right" }}
                        onClick={() => {navigateAtNoOfDues()}}>Back</button>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                        <jnb.Row className="px-3">
                            {showNoOfDuesDetailsOfEmp?.length > 0 ? (
                                <CommonReactTable data={showNoOfDuesDetailsOfEmp} columns={NoOfDuescolumns} showFooter="false" filename="Employee Dues Report"
                                    headerName={`Employee Dues Report`} />
                            ) : (showErrMsgAtDuesDtls === true && (<center><b style={{ color: "red" }}>*******No Data Found***** </b></center>))}
                            <jnb.Modal show={showpopupAtNoOfDues} onHide={handleCloseAtNoOfDues} className="modal-xl" backdrop="static">
                                <jnb.Modal.Header className="bg-success bg-opacity-75 text-white">
                                    <jnb.Modal.Title>Closed Items Details </jnb.Modal.Title>
                                </jnb.Modal.Header>
                                <jnb.Modal.Body>
                                    {showNoOfAssetsDetails?.length > 0 ? (
                                        <CommonReactTable data={showNoOfAssetsDetails} columns={NoOfDuesReturnedcolumns} showFooter="false" filename="Employee Dues Report"
                                            headerName={`Employee Closed Dues Report`} />
                                    ) : (showErrMsgAtDuesDtls === true && (<center><b style={{ color: "red" }}>************No Data Found************</b></center>))}
                                    <jnb.Row className="px-4 pt-2">
                                        <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                                            <div className='justify-content-end'>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={handleCloseAtNoOfDues}>Close</button>
                                            </div>
                                        </jnb.Col>
                                    </jnb.Row>
                                </jnb.Modal.Body>
                            </jnb.Modal>
                        </jnb.Row>
            </jnb.Row>
        </div>
    )
}
