import React from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persister } from './store';
import HrmsDashboard from "./Components/HRMS/HR/HrmsDashboard";
import LoginWiseReport from "./Components/HRMS/CommonPages/LoginWiseReport";
import Loader from "./Loader/Loader";
import KeyPerformanceMasterForm from "./Components/HRMS/HR/KeyPerformance";
import KpWiseWeightage from "./Components/HRMS/HR/KpWeightage";
import KpsubindicatorsMapping from "./Components/HRMS/HR/KpsubindicatorMapping";
import Kpsubindicators from "./Components/HRMS/HR/Kpsubindicators";
import EmployeeAppraisalForm from "./Components/HRMS/EMPLOYEE/EmployeeAppraisalForm";
import ROApprisalForm from "./Components/HRMS/RO/ROApprisalForm";
import IOAppraisalForm from "./Components/HRMS/IO/IoAppraisalForm";
import EmployeeProfilePrint from "./Components/HRMS/CommonPages/EmployeeProfilePrint";
import { lazy } from "react";
import HrmsLoginPage from "./Components/HRMS/LoginPage/HrmsLoginPage";
import { Suspense } from "react";
import HomePage from "./Components/HRMS/DashBoard/HomePage";
import PrimarySkillMapping from "./Components/HRMS/RO/PrimarySkillsMapping";
import { ProSidebarProvider } from "react-pro-sidebar";
import EmployeeEntryForm from "./Components/HRMS/HR/EmployeeEntryForm";
// import EmpDetailsUpdation from "./Components/HRMS/HR/EmployeeDetailsUpdation";
import AppraisalStatusReport from "./Components/HRMS/HR/AppriasalStatusReport";
import roles from "./roles";
import ProtectedRoute from "./ProtectedRoute";
import AppraisalRevort from "./Components/HRMS/HR/AppraisalRevort";
import AppraisalChangeRequest from "./Components/HRMS/HR/AppraisalChangeRequest";
// import EmployeePMS from "./Components/HRMS/NEW_PMS_STRUCTURE/EMPLOYEE/EmployeePMS";
import EmployeePMSForm from "./Components/HRMS/NEW_PMS_STRUCTURE/EMPLOYEE/EmployeePMS";
import IoPmsForm from "./Components/HRMS/NEW_PMS_STRUCTURE/IO/IoPmsForm";
import RoPmsForm from "./Components/HRMS/NEW_PMS_STRUCTURE/RO/RoPmsForm";
import EmployeeEntryFormNew from "./Components/HRMS/NEW_PMS_STRUCTURE/HR/EmployeeEntryFormNew";
import MastersEntryForm from "./Components/HRMS/NEW_PMS_STRUCTURE/HR/MastersEntryForm";
import PmsEmpPrintForm from "./Components/HRMS/NEW_PMS_STRUCTURE/pmsEmpPrintForm";
import EmployeeDashboard from "./Components/HRMS/NEW_PMS_STRUCTURE/HR/EmployeeDashboard";
import PmsDashboard from "./Components/HRMS/NEW_PMS_STRUCTURE/HR/PmsDashboard";
import LeavesOrMovementRegistration from "./Components/HRMS/EMPLOYEE/LeavesOrMovementRegistration";
import LeavesDetailsReport from "./Components/HRMS/EMPLOYEE/LeavesDetailsReport";
import RequestApplicationApproveOrReject from "./Components/HRMS/RO/RequestApplicationApproveOrReject";
import AprroveOrRejectDataHistory from "./Components/HRMS/RO/AprroveOrRejectDataHistory";
import EmpPreviousHystoryDetails from "./Components/HRMS/RO/EmpPreviousHystoryDetails";
import TotalLeaveDetailsReport from "./Components/HRMS/HR/TotalLeaveDetailsReport";
import ActiveEmployees from "./Components/HRMS/HR/AssetsDetails/ActiveEmployees";
import NoOfDuesDetails from "./Components/HRMS/HR/AssetsDetails/NoOfDuesDetails";
import WithoutDuesEmployeesDetails from "./Components/HRMS/HR/AssetsDetails/WithoutDuesEmployeesDetails";
import DuesWithOrWithoutDetailsForm from "./Components/HRMS/HR/AssetsDetails/DuesWithOrWithoutDetailsForm";
import TotalEmployeeAssetsDetails from "./Components/HRMS/HR/AssetsDetails/TotalEmployeeAssetsDetails";
// import EmpPayDetailsReport from "./Components/HRMS/HR/PayDetails/EmpPayDetailsReport";
import PayDetailsForm from "./Components/HRMS/HR/PayDetails/PayDetailsForm";
// import UpdatedEmpPayDetails from "./Components/HRMS/HR/PayDetails/UpdatedEmpPayDetails";
import DashboardOfPayDetails from "./Components/HRMS/HR/PayDetails/DashboardOfPayDetails";
// import PayRollEntryForm from "./Components/HRMS/HR/PayDetails/PayRollEntryFormGovt";
import RegularEmpPayRollEntryForm from "./Components/HRMS/HR/PayDetails/RegularEmpPayRollEntryForm";
import ContractPayRollEntryForm from "./Components/HRMS/HR/PayDetails/ContractPayRollEntryForm";
import InternPayRollEntryForm from "./Components/HRMS/HR/PayDetails/InternPayRollEntryForm";
import PayRollEntryFormGovt from "./Components/HRMS/HR/PayDetails/PayRollEntryFormGovt";
// import AppraisalChangeRequest from "./Components/HRMS/HR/ChangeRequest";
// import SampleSelect from "./Components/HRMS/HR/PayDetails/SampleSelect";
import PayslipGenerater from "./Components/HRMS/HR/PayDetails/PaySlips/PayslipGenerater";
import EmployeeLopDetails from "./Components/HRMS/HR/EmployeeLopDetails";
import JobPostReport from "./Components/HRMS/HR/JobPosts/JobPostReport";
import JobPostingForm from "./Components/HRMS/HR/JobPosts/JobPostingForm";
import JobPostingFormDetails from "./Components/HRMS/HR/JobPosts/JobPostingDetails";
import CandidateEntryForm from "./Components/HRMS/HR/JobPosts/CandidateEntryForm";
import AppliedCandidateDetailsReport from "./Components/HRMS/HR/JobPosts/AppliedCandidateDetailsReport";
import UpdateStatus from "./Components/HRMS/HR/JobPosts/UpdateStatus";
import HealthDashboardReport from "./Components/HRMS/EMPLOYEE/HealthInsurance/Reports/HealthDashboardReport";
import LeaveRequestApproveOrRejectByIO from "./Components/HRMS/IO/LeaveRequestApproveOrRejectByIO";
import EmpFRSReport from "./Components/HRMS/HR/FRSReports/EmpFRSReport";
import HealthInsuranceForm from "./Components/HRMS/EMPLOYEE/HealthInsurance/HealthInsuranceForm";
import FRSDetailsReportRo from "./Components/HRMS/HR/FRSReports/FRSDetailsReportRo";
import PaternityOrMaternityAddLeaves from "./Components/HRMS/HR/AddLeave/PaternityOrMaternityAddLeaves";
const SidebarForm = lazy(() => import("./sidebarnew"));

const RoutesComponent = () => {
  const logindetails = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
  return (

    <PersistGate loading={null} persistor={persister}>
      <ProSidebarProvider>
        {/* history={history}*/}
        <Suspense
          fallback={
            <center>
              <br></br>
              <br></br>
              <br></br>Loading...
            </center>
          }
        >
          <Router >
            <Routes>
              <Route path="/hrmsloginpage" element={<HrmsLoginPage></HrmsLoginPage>}></Route>
              <Route path="/" element={<HrmsLoginPage></HrmsLoginPage>}></Route>
              <Route path="*" element={<HrmsLoginPage></HrmsLoginPage>} />
              <Route path="/homepage" element={<SidebarForm><ProtectedRoute path="/homepage" component={HomePage}
                allowedRoles={roles['homepage']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................HR ADMIN............................................................ */}
              <Route path="/keyperformance" element={<SidebarForm><ProtectedRoute component={KeyPerformanceMasterForm}
                allowedRoles={roles['keyperformance']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/kpsubindicatorsmapping" element={<SidebarForm><ProtectedRoute component={KpsubindicatorsMapping}
                allowedRoles={roles['kpsubindicatorsmapping']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/kpsubindicators" element={<SidebarForm><ProtectedRoute component={Kpsubindicators}
                allowedRoles={roles['kpsubindicators']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/keyperformanceweightage" element={<SidebarForm><ProtectedRoute component={KpWiseWeightage}
                allowedRoles={roles['keyperformanceweightage']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/hradmindashboard" element={<SidebarForm><ProtectedRoute component={HrmsDashboard}
                allowedRoles={roles['hradmindashboard']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/appraisalstatusreport" element={<SidebarForm><ProtectedRoute component={AppraisalStatusReport}
                allowedRoles={roles['appraisalstatusreport']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/appraisalrevert" element={<SidebarForm><ProtectedRoute component={AppraisalRevort}
                allowedRoles={roles['appraisalrevert']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/updateempdetails" element={<SidebarForm><ProtectedRoute component={AppraisalChangeRequest}
                allowedRoles={roles['updateempdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/employeeentryform" element={<SidebarForm><ProtectedRoute component={EmployeeEntryForm}
                allowedRoles={roles['employeeentryform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* .............................................EMPLOYEE............................................................ */}
              <Route path="/employeeappraisalform" element={<SidebarForm><ProtectedRoute component={EmployeeAppraisalForm}
                allowedRoles={roles['employeeappraisalform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................IO............................................................ */}
              <Route path="/ioappraisalform" element={<SidebarForm><ProtectedRoute component={IOAppraisalForm}
                allowedRoles={roles['ioappraisalform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................RO............................................................ */}
              <Route path="/roapprisalform" element={<SidebarForm><ProtectedRoute component={ROApprisalForm}
                allowedRoles={roles['roapprisalform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/primaryskillmapping" element={<SidebarForm><ProtectedRoute component={PrimarySkillMapping}
                allowedRoles={roles['primaryskillmapping']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* ..............................................COMMON PAGES........................................... */}
              <Route path="/loginwisereport/:roleId" element={<SidebarForm><ProtectedRoute component={LoginWiseReport}
                allowedRoles={roles['loginwisereport']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/EmpProfilePrint" element={<SidebarForm><ProtectedRoute component={EmployeeProfilePrint}
                allowedRoles={roles['EmpProfilePrint']} userRole={logindetails?.roles} /></SidebarForm>}></Route>


              {/* ..................................................................PMS NEW........................................... */}
              {/* .............................................EMPLOYEE............................................................ */}
              <Route path="/employeepmsform" element={<SidebarForm><ProtectedRoute component={EmployeePMSForm}
                allowedRoles={roles['employeepmsform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................EMPLOYEE............................................................ */}
              {/* .............................................IO............................................................ */}
              <Route path="/iopmsform" element={<SidebarForm><ProtectedRoute component={IoPmsForm}
                allowedRoles={roles['iopmsform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................IO............................................................ */}
              {/* .............................................RO............................................................ */}
              <Route path="/ropmsform" element={<SidebarForm><ProtectedRoute component={RoPmsForm}
                allowedRoles={roles['ropmsform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* .............................................RO............................................................ */}

              {/* .............................................HR............................................................ */}
              <Route path="/employeeentryformnew" element={<SidebarForm><ProtectedRoute component={EmployeeEntryFormNew}
                allowedRoles={roles['employeeentryformnew']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/mastersentryform" element={<SidebarForm><ProtectedRoute component={MastersEntryForm}
                allowedRoles={roles['mastersentryform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* .............................................HR............................................................ */}
              <Route path="/pmsempprintform" element={<SidebarForm><ProtectedRoute component={PmsEmpPrintForm}
                allowedRoles={roles['pmsempprintform']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/employeedashboard" element={<SidebarForm><ProtectedRoute component={EmployeeDashboard}
                allowedRoles={roles['employeedashboard']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/pmsdashboard" element={<SidebarForm><ProtectedRoute component={PmsDashboard}
                allowedRoles={roles['pmsdashboard']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* *********************************************  JOBS  *********************************************** */}

              <Route path="/jobpostreport" element={<SidebarForm><ProtectedRoute component={JobPostReport}
                allowedRoles={roles['jobpostreport']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/jobposting" element={<SidebarForm><ProtectedRoute component={JobPostingForm}
                allowedRoles={roles['jobposting']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/jobpostingdetails" element={<SidebarForm><ProtectedRoute component={JobPostingFormDetails}
                allowedRoles={roles['jobpostingdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/JobApplication" element={<SidebarForm><ProtectedRoute component={CandidateEntryForm}
                allowedRoles={roles['JobApplication']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/appliedCandidateDetails" element={<SidebarForm><ProtectedRoute component={AppliedCandidateDetailsReport}
                allowedRoles={roles['appliedCandidateDetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/updatestatus" element={<SidebarForm><ProtectedRoute component={UpdateStatus}
                allowedRoles={roles['updatestatus']} userRole={logindetails?.roles} /></SidebarForm>}></Route>


              {/* *********************************************  Leaves  *********************************************** */}

              {/* Employee */}
              <Route path="/leavesormovements" element={<SidebarForm><ProtectedRoute component={LeavesOrMovementRegistration}
                allowedRoles={roles['leavesormovements']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/leavesDetailsReport" element={<SidebarForm><ProtectedRoute component={LeavesDetailsReport}
                allowedRoles={roles['leavesDetailsReport']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* RO */}
              <Route path="/detailsofleaveapplications" element={<SidebarForm><ProtectedRoute component={RequestApplicationApproveOrReject}
                allowedRoles={roles['detailsofleaveapplications']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* IO */}
              <Route path="/detailsofleaverequests" element={<SidebarForm><ProtectedRoute component={LeaveRequestApproveOrRejectByIO}
                allowedRoles={roles['detailsofleaverequests']} userRole={logindetails?.roles} /></SidebarForm>}></Route>


              <Route path="/AprroveOrRejectDataHistory" element={<SidebarForm><ProtectedRoute component={AprroveOrRejectDataHistory}
                allowedRoles={roles['AprroveOrRejectDataHistory']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/EmpPreviousHystoryDetails" element={<SidebarForm><ProtectedRoute component={EmpPreviousHystoryDetails}
                allowedRoles={roles['EmpPreviousHystoryDetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* HR */}
              <Route path="/TotalLeaveDetailsReport" element={<SidebarForm><ProtectedRoute component={TotalLeaveDetailsReport}
                allowedRoles={roles['TotalLeaveDetailsReport']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/paternityormaternityaddleaves" element={<SidebarForm><ProtectedRoute component={PaternityOrMaternityAddLeaves}
                allowedRoles={roles['paternityormaternityaddleaves']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* ===================================================/Assets/=========================================================================== */}
              <Route path="/employeesinfo" element={<SidebarForm><ProtectedRoute component={ActiveEmployees}
                allowedRoles={roles['employeesinfo']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/duesdetails" element={<SidebarForm><ProtectedRoute component={NoOfDuesDetails}
                allowedRoles={roles['duesdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/withoutduesdetails" element={<SidebarForm><ProtectedRoute component={WithoutDuesEmployeesDetails}
                allowedRoles={roles['withoutduesdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/withduesorwithoutduesdetails" element={<SidebarForm><ProtectedRoute component={DuesWithOrWithoutDetailsForm}
                allowedRoles={roles['withduesorwithoutduesdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>


              <Route path="/totalemployeesassetdetails" element={<SidebarForm><ProtectedRoute component={TotalEmployeeAssetsDetails}
                allowedRoles={roles['totalemployeesassetdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>


              {/* ====================================================/PayrollDetailsForm/================================================================= */}

              <Route path="/dashboradofpaydetils" element={<SidebarForm><ProtectedRoute component={DashboardOfPayDetails}
                allowedRoles={roles['dashboradofpaydetils']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/infoofpaydetails" element={<SidebarForm><ProtectedRoute component={PayDetailsForm}
                allowedRoles={roles['infoofpaydetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/payrollsdetailsregular" element={<SidebarForm><ProtectedRoute component={RegularEmpPayRollEntryForm}
                allowedRoles={roles['payrollsdetailsregular']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/payrollsdetailscontract" element={<SidebarForm><ProtectedRoute component={ContractPayRollEntryForm}
                allowedRoles={roles['payrollsdetailscontract']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/payrollsdetailsintern" element={<SidebarForm><ProtectedRoute component={InternPayRollEntryForm}
                allowedRoles={roles['payrollsdetailsintern']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/govtpayrollsdetails" element={<SidebarForm><ProtectedRoute component={PayRollEntryFormGovt}
                allowedRoles={roles['govtpayrollsdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/payslipdetails" element={<SidebarForm><ProtectedRoute component={PayslipGenerater}
                allowedRoles={roles['payslipdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              <Route path="/employeelopdetails" element={<SidebarForm><ProtectedRoute component={EmployeeLopDetails}
                allowedRoles={roles['employeelopdetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* <Route path="/emppaydetails" element={<SidebarForm><ProtectedRoute component={EmpPayDetailsReport}
                allowedRoles={roles['emppaydetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route> */}
              {/* <Route path="/infoofupdatedpaydetails" element={<SidebarForm><ProtectedRoute component={UpdatedEmpPayDetails}
                allowedRoles={roles['infoofupdatedpaydetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route> */}

              {/* =========================================  Health Insurance ========================================================== */}

              <Route path="/healthInsuranceDashboard" element={<SidebarForm><ProtectedRoute component={HealthDashboardReport}
                allowedRoles={roles['healthInsuranceDashboard']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              <Route path="/healthinsurance" element={<SidebarForm><ProtectedRoute component={HealthInsuranceForm}
                allowedRoles={roles['healthinsurance']} userRole={logindetails?.roles} /></SidebarForm>}></Route>
              {/* ====================================================/FRS/================================================================= */}

              <Route path="/frsDetails" element={<SidebarForm><ProtectedRoute component={EmpFRSReport}
                allowedRoles={roles['frsDetails']} userRole={logindetails?.roles} /></SidebarForm>}></Route>

              {/* ====================================================/ID Card Generate/================================================================= */}

              <Route path="/detailsofFRSRO/:roleId"  element={<SidebarForm><ProtectedRoute component={FRSDetailsReportRo} 
              allowedRoles={roles['detailsofFRSRO']} userRole={logindetails?.roles} /></SidebarForm>}></Route> </Routes> </Router> </Suspense>
      </ProSidebarProvider>
      <Loader />
    </PersistGate>

  )
};
export default RoutesComponent;
