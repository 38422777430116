import React, { useEffect, useState, useMemo } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { Link } from "react-router-dom";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { AiOutlineFileExcel } from "react-icons/ai";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
export default function AppraisalStatusReport() {
    const [showempstatusdata, setempstatusdata] = useState();
    function Getemployeesstatusdata() {
        CommonAxiosGet(config.url.COMMON_URL + "pms/hrstatusreport-new").then((response) => {
            if (response.data.scode === "01") {
                setempstatusdata(response.data.hrstatusreport);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    const columns = useMemo(() => [
        {
            Header: " ",
            Footer: ' ',
            name: 'SL.No',
            cell: (row, index) => index + 1,
            selector: row => row.slno,
            width: '70px'
        },
        {
            name: 'ID',
            selector: row => row.emp_id,
            sortable: true,
            width: '97px'
        },
        {
            name: 'Name',
            selector: row => row.emp_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Zone',
            selector: row => row.zone_name,
            sortable: true,
            wrap: true,
        },

        {
            name: 'Designation',
            selector: row => row.designation_name,
            sortable: true,
            wrap: true,
        },

        {
            name: 'IO',
            selector: row => (<>{row.io}-{row.io_emp_name}</>),
            sortable: true,
            wrap: true
        },
        {
            name: 'RO',
            selector: row => (<>{row.ro}-{row.ro_emp_name}</>),
            sortable: true,
            wrap: true
        },

        {
            name: 'Self Status',
            selector: row => row.self_status,
            sortable: true,
            wrap: true
        },
        {
            name: 'IO Status',
            selector: row => row.io_status,
            sortable: true,
            wrap: true
        },
        {
            name: 'RO Status',
            selector: row => row.ro_status,
            sortable: true,
            wrap: true
        },


    ])
    const excelDownload = (excelData, fileName) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    function downloadExcelD(e) {
        const fileName = "HRMSStatusReport";
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
        const excelFileName = `${fileName}_${formattedDate}`;
        const excelData = []
        showempstatusdata.map((item) =>
            excelData.push(
                {
                    "EMPLOYEE ID": item.emp_id, "EMPLOYEE NAME": item.emp_name, "ZONE": item.zone_name, "DESIGNATION": item.designation_name,
                    "IO ID": item.io, "IO NAME": item.io_emp_name, "RO ID": item.ro, "RO NAME": item.ro_emp_name, "SELF STATUS": item.self_status,
                    "IO STATUS": item.io_status, "RO STATUS": item.ro_status,
                }
            )
        )
        excelDownload(excelData, excelFileName)
        Sweetalert('Sucessfully Downloaded (Excel) ', 'success')
    }
    //...............................................................EMP Overal Status Report...........................
    const [showempoveraldata, setempoveraldata] = useState();
    function GetempOveraldata() {
        CommonAxiosGet(config.url.COMMON_URL + "pms/hroverallstatusreport-new").then((response) => {
            if (response.data.scode === "01") {
                setempoveraldata(response.data.hrstatusreport);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    function downloadempOveralDataExcelD(e) {
        const fileName = "HRMSOveralStatusReport";
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
        const excelFileName = `${fileName}_${formattedDate}`;
        const excelData = []
        showempoveraldata.map((item) =>
            excelData.push(
                {
                    "EMPLOYEE ID": item.emp_id, "EMPLOYEE NAME": item.emp_name, "ZONE": item.zone_name, "DESIGNATION": item.designation_name,
                    "IO ID": item.io, "IO NAME": item.io_emp_name, "RO ID": item.ro, "RO NAME": item.ro_emp_name,
                    "SELF STATUS": item.self_status, "IO STATUS": item.io_status, "RO STATUS": item.ro_status,
                    "SELF FINAL MARKS": item.emp_self_final_marks, "SELF FINAL RATING": item.emp_self_final_rating, "IO FINAL MARKS": item.io_final_marks,
                    "IO FINAL RATING": item.io_final_rating, "IO PROMOTION STATUS": item.io_promotion_status, "IO PROMOTION STATUS REMARKS": item.io_promotion_status_remarks,
                    "IO SALARY CORRECTION": item.io_salary_correction, "IO SALARY CORRECTION REMARKS": item.io_salary_correction_remarks, "IO SALARY CORRECTION AMOUNT": item.io_salary_correction_amount,
                    "RO FINAL RATING": item.ro_final_rating, "RO FINAL RATING STATUS": item.ro_final_rating_status,
                    "RO FINAL RATING DISAGREE REMARKS": item.ro_final_rating_disagree_remarks, "RO FINAL RATING REMARKS": item.ro_final_rating_remarks,
                    "RO PROMOTION STATUS": item.ro_promotion_status,
                    "RO PROMOTION STATUS DISAGREE REMARKS": item.ro_promotion_status_disagree_remarks, "RO FINAL PROMOTION STATUS": item.ro_final_promotion_status,
                    "RO FINAL PROMOTION STATUS REMARKS": item.ro_final_promotion_status_remarks,
                    "RO SALARY CORRECTION STATUS": item.ro_salary_correction_status,
                    "RO SALARY CORRECTION DISAGREE REMARKS": item.ro_salary_correction_disagree_remarks,
                    "RO FINAL SALARY CORRECTION STATUS": item.ro_salary_correction_final_status,
                    "RO FINAL SALARY CORRECTION REMARKS": item.ro_salary_correction_final_remarks,
                    "RO FINAL SALARY CORRECTION AMOUNT": item.ro_salary_correction_final_amount


                }
            )
        )
        excelDownload(excelData, excelFileName)
        Sweetalert('Sucessfully Downloaded (Excel) ', 'success')
    }


    useEffect(() => {
        Getemployeesstatusdata();
        GetempOveraldata();
    }, [])
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee List</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"> <h1>Employee Status Report</h1></div>

        <div className="border p-3 pb-0 mb-3 ">
            <div className="my-custom-scrollbar">
                <button type='button' className='btn btn-success btn-sm ' style={{ float: 'right' }} onClick={(e) => downloadempOveralDataExcelD(e)}>
                    Appraisal Overal Status Report <AiOutlineFileExcel style={{ width: '20px', height: '21px', color: '#fff' }} /> </button>
                <div className="inner-herbpage-service-title-sub mb-4p5">
                    <h1>List of Data</h1>


                    <button type='button' className='btn btn-success btn-sm ' style={{ float: 'right' }} onClick={(e) => downloadExcelD(e)}>
                        Excel <AiOutlineFileExcel style={{ width: '20px', height: '21px', color: '#fff' }} /> </button>
                </div>

                <DataTableExtensions export={false} print={false}
                    columns={columns} data={showempstatusdata} filterPlaceholder='Search'>
                    <DataTable columns={columns} pagination={true}
                        paginationPerPage="10" data={showempstatusdata}
                        keyField="emp_id"
                    />
                </DataTableExtensions>

            </div>
        </div>

    </>)
}