import { useDispatch as useReduxDispatch } from 'react-redux';//useSelector
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';

//for storage
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import Reducers from './reducers';



const persistConfig = {
  key: 'root',
  storage,
}

//   const Reducers = combineReducers({
//     fieldreducer: FieldsReducer,
//      loginreducer : LoginReducer,
//      modalState : ModalPopupReducer,
// })



const persistedReducer = persistReducer(persistConfig, Reducers)

//
const store = configureStore({
  reducer: {
    reducers: persistedReducer,
    //Reducers,
    //middleware:(getDefaultMiddleware)=>getDefaultMiddleware(),
  },
  //reducer : persistedReducer,
  devTools: true,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]//added
});
//const store = createStore(persistedReducer);
//export const history = createBrowserHistory();

export const useDispatch = () => useReduxDispatch();

//export const history = createBrowserHistory();
// export const store = createStore(persistedReducer);

const persister = persistStore(store);
//export const persistor = persistStore(store)

const { dispatch } = store;
axios.interceptors.request.use(function (config) {
  dispatch({ type: 'SHOW_SPINNER' });
  // console.log("### axios loading");
  return config;
}, function (error) {
  if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined || localStorage.getItem("token").length < 4) {
    // window.location.href=LOGIN_PAGE_URL
  }
  setTimeout(() => { }, 1500)
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  dispatch({ type: 'HIDE_SPINNER' });
  return response;
}, function (error) {
  if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined || localStorage.getItem("token").length < 4) {
    // window.location.href=LOGIN_PAGE_URL
  }
  setTimeout(() => { }, 1500)
  return Promise.reject(error);
})

//persister
export { store, persister };