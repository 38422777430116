import { FormikProvider, useFormik, Form, Field, ErrorMessage } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect } from "react";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Swal from "sweetalert2";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { useSelector } from "react-redux";
import * as  Yup from "yup";
import CommonEmployeeDetailsForPayroll from "./PaySlips/CommonEmployeeDetailsForPayroll";
const InternPayRollEntryForm = (propsAtInternPayroll) => {
    const  contractOrInternAtInternForm=JSON.parse(localStorage?.getItem("data")) || [];
    const logindetailsAtInternPayroll = useSelector((stateAtIntern) => stateAtIntern?.reducers?.loginreducer?.userLoginDetials);
    const internformIk = useFormik({ 
        enableReinitialize: true,
        initialValues: {
            consolidated_pay_earned: "",
            emp_id:contractOrInternAtInternForm?.emp_id,
            desg_id:contractOrInternAtInternForm?.designation_id,
            insert_by:logindetailsAtInternPayroll?.username,
        },
        validationSchema: Yup.object().shape({
            consolidated_pay_earned: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.InternEmpPaySave(values).then((resAtIntern) => {
                            if (resAtIntern?.data?.ResponseCode === "01") {
                                SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                    if (isConfirm.value) {window.location.reload();}
                                })
                            }
                            else if (resAtIntern?.data?.ResponseCode === "02") {
                                Sweetalert(resAtIntern?.data?.ResponseDesc, 'warning');
                            }
                            else {
                                Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                            }
                        })
                        .catch(() => {console.log("Exception Occured 71 ")});
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        },
    });

  
    function InternViewDataAtPayRoll() {
        if (propsAtInternPayroll?.Internviewdata === undefined || propsAtInternPayroll?.Internviewdata === null || propsAtInternPayroll?.Internviewdata === "") {
            return;
        }
        else {
            if(propsAtInternPayroll?.Internviewdata?.consilated_total === null || propsAtInternPayroll?.Internviewdata?.consilated_total === undefined){
                return ;
            }
            else{
                internformIk?.setFieldValue("consolidated_pay_earned", propsAtInternPayroll?.Internviewdata?.consilated_total);
            }
        }
    }

    useEffect(() => {
        InternViewDataAtPayRoll();
    }, [propsAtInternPayroll?.Internviewdata]);

    const handleClose = () => {
        propsAtInternPayroll.Internpopup();
        if(propsAtInternPayroll?.Internviewdata?.consilated_total === null){
            internformIk.resetForm();
        }
        else{
            InternViewDataAtPayRoll()
        }
    }

    return (<>
            <jnb.Modal show={propsAtInternPayroll?.Internpopupflagvalue} onHide={handleClose} className="modal-xl" backdrop="static">
                <jnb.Modal.Header className="bg-success bg-opacity-75 text-white">
                    <jnb.Modal.Title>Payroll Details </jnb.Modal.Title>
                </jnb.Modal.Header>
                <jnb.Modal.Body>
                <jnb.Row className="px-2 mb-2 pt-0">
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <CommonEmployeeDetailsForPayroll employeeDetails={contractOrInternAtInternForm} />
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
            </jnb.Row>
            <FormikProvider value={internformIk}>
                <Form onSubmit={internformIk.handleSubmit} onChange={internformIk.handleChange} autoComplete="off" >
                <jnb.Row className="px-3">
                        <div className="border pt-3 RowColorForWEH">
                    <jnb.Row className="px-4 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Consolidated Pay Gross:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="consolidated_pay_earned" className="form-control"  onKeyPress={allowNumbersOnly} />
                        <ErrorMessage name="consolidated_pay_earned" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                        <div className='d-flex flex-row justify-content-end mb-0'>
                            <button type="submit" className="btn btn-success btn-sm">Save</button>
                        </div></jnb.Col></jnb.Row>
                    </div></jnb.Row>
                    <jnb.Row className="px-4 pt-2 mb-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                        <div className='justify-content-end'><button type="button" className="btn btn-primary btn-sm" onClick={handleClose}>Close</button></div></jnb.Col></jnb.Row>
                </Form>
            </FormikProvider></jnb.Modal.Body></jnb.Modal>
    </>)
}
export default InternPayRollEntryForm;