 import { useEffect } from "react";
import "./jnanabhumi.css";
import RoutesComponent from "./routes";
function App() {
  useEffect(() => {
    if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
      localStorage.clear()
    }
  }, [])
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
        registrations?.forEach((registration) => {
          registration?.unregister();
        });
      });
    }
  }, []);
  return (
    <> 
      <RoutesComponent></RoutesComponent>
    </>
  );
}

export default App;
