
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik, Form } from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import allowNumbersOnly, { allowAlphabetAndSpacesOnly } from "../../../../CommonUtils/CommonValidations";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Sweetalert, { successAlert2 } from "../../../../CommonUtils/SweetAlerts";
import * as Yup from "yup";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { VscDiffAdded } from "react-icons/vsc";
import moment from "moment";
export default function HealthInsuranceForm() {
    const logindetails = useSelector((state1) => state1.reducers?.loginreducer?.userLoginDetials);
    const [GetHealthFormData, setHealthFormData] = useState([])
    const [healthformReportDatafound, sethealthformReportDatafound] = useState()
    const ALPHABETS_ONLY = /^[aA-zZ\s]+$/;

    const validation = Yup.object().shape({
        emp_dob: Yup.string().required("Required").nullable(),
        emp_gender: Yup.string().required("Required").nullable(),
        emp_aadhar: Yup.string().required("Required").nullable(),
        emp_name: Yup.string().required("Required").nullable().matches(ALPHABETS_ONLY, " Enter Alphabets only"),
        marital_status: Yup.string().required("Required").nullable(),
        Parents: Yup.string().required("Required").nullable(),
    });
    const showEntryInitialValues = {
        date_of_join: logindetails?.doj,
        division: logindetails?.wing,
        emp_id: logindetails?.username,
        grade: logindetails?.grade,
        emp_aadhar: "",
        emp_age: "",
        emp_dob: "",
        emp_gender: "",
        emp_name: "",
        father_aadhar: "",
        father_dob: "",
        father_name: "",
        father_age:"",
        father_in_law_name:"",
        father_in_law_dob:"",
        father_in_law_aadhar:"",
        father_in_law_age:"",
        marital_status: "",
        mother_aadhar: "",
        mother_dob: "",
        mother_name: "",
        mother_age:"",
        mother_in_law_name:"",
        mother_in_law_dob:"",
        mother_in_law_aadhar:"",
        mother_in_law_age:"",
        is_child: "",
        spouse_aadhar: "",
        Parents:"",
        spouse_dob: "",
        spouse_gender: "",
        spouse_name: "",
        spouse_age: "",
        maritalStatus: "",
        childEntity: [{
            child_name: "", child_gender: "", child_dob: "", child_uid: ""
       }]
    };
    const healthformIk = useFormik({
        enableReinitialize: true,
        initialValues: showEntryInitialValues,
        onSubmit: (values) => {
            if (values.marital_status === "Y" && values.is_child === "Y") {
                const missingFields = values.childEntity.reduce((acc, childvalue, index) => {
                    const missingfieldvalue = [];
                    if (!childvalue.child_name) missingfieldvalue.push('Child Name');
                    if (!childvalue.child_gender) missingfieldvalue.push('Gender');
                    if (!childvalue.child_dob) missingfieldvalue.push('Child DOB');
                    if (!childvalue.child_uid) missingfieldvalue.push('UID');
                    if (missingfieldvalue.length > 0) {
                        acc.push(`${missingfieldvalue.join(', ')} at Row ${index + 1}`);
                    }
                    return acc;
                }, []);
        
                if (missingFields?.length > 0) {
                    Swal.fire({
                        title: 'Missing Fields',
                        text: `Missing fields for children:\n\n${missingFields.join('\n')}`,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
            }
        
            const familyEntities = [];
        
            const pushToFamilyEntities = (person_name, aadhaar, dob, gender, relation) => {
                familyEntities?.push({
                    emp_id: logindetails.username,
                    person_name,
                    aadhaar,
                    dob,
                    gender,
                    relation,
                    is_child: values.is_child,
                });
            };
            if (values?.emp_aadhar || values?.emp_dob || values?.emp_gender || values?.emp_name) {
                pushToFamilyEntities(values?.emp_name, values?.emp_aadhar, values?.emp_dob, values?.emp_gender, "1");
            }
            if (values?.spouse_aadhar || values?.spouse_dob || values?.spouse_gender || values?.spouse_name) {
                pushToFamilyEntities(values?.spouse_name, values?.spouse_aadhar, values?.spouse_dob, values?.spouse_gender, "2");
            }
            if (values?.father_aadhar || values?.father_dob || values?.father_name) {
                pushToFamilyEntities(values?.father_name, values?.father_aadhar, values?.father_dob, "M", "3");
            }
            if (values?.mother_aadhar || values?.mother_dob || values?.mother_name) {
                pushToFamilyEntities(values?.mother_name, values?.mother_aadhar, values?.mother_dob, "F", "4");
            }
            if (values?.father_in_law_aadhar || values?.father_in_law_dob || values?.father_in_law_name) {
                pushToFamilyEntities(values?.father_in_law_name, values?.father_in_law_aadhar, values?.father_in_law_dob, "M", "5");
            }
            if (values?.mother_in_law_aadhar || values?.mother_in_law_dob || values?.mother_in_law_name) {
                pushToFamilyEntities(values?.mother_in_law_name, values?.mother_in_law_aadhar, values?.mother_in_law_dob, "F", "6");
            }
            if (values?.childEntity && values?.childEntity?.length > 0) {
                values?.childEntity?.forEach((childforeach) => {
                    pushToFamilyEntities(childforeach?.child_name, childforeach?.child_uid, childforeach?.child_dob, childforeach?.child_gender, "7");
                });
            }
        
            const formattedValues = { familyEntities };
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.saveHealthInsuranceSave(formattedValues).then((res) => {
                        if (res.data.SCODE === "01") {
                            successAlert2(res.data.SDESC)
                        }
                        else if (res.data.SCODE === "02") {
                            Sweetalert(res.data.SDESC, 'warning');
                        }
                        else if (res.data.SCODE === "04") {
                            Sweetalert(res.data.SDESC);
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    }).catch(() => { console.log("Exception Occured"); });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            });
        },
       
        validationSchema: validation,
    });

      const calculateAge = (dob1) => {
        const birthDate = new Date(dob1);
        const todaydate = new Date();
        let yearsforcalc = todaydate.getFullYear() - birthDate.getFullYear();
        let monthsforcalc = todaydate.getMonth() - birthDate.getMonth();
        if (monthsforcalc < 0) {
          yearsforcalc--;
          monthsforcalc += 12;
        }
        if (todaydate.getDate() < birthDate.getDate()) {
          monthsforcalc--;
          if (monthsforcalc < 0) {
            yearsforcalc--;
            monthsforcalc = 11;
          }
        }      
        return { yearsforcalc, monthsforcalc };
      };
      
   
      const handleDobChange = (event1, fieldNameinhealth) => {
        const dobinhealth = event1.target.value;
        if (dobinhealth) {
          const { yearsforcalc, monthsforcalc } = calculateAge(dobinhealth);
          healthformIk.setFieldValue(fieldNameinhealth, `${yearsforcalc} years and ${monthsforcalc} months`);
        } else {
          healthformIk.setFieldValue(fieldNameinhealth, ''); 
        }
      };
    
    const calculateAgeInYearsAndMonths = (dob2) => {
        const dob2Date = new Date(dob2);
        const todaycalcAgeInYrsMth = new Date();
    
        let yearsincalcAge = todaycalcAgeInYrsMth.getFullYear() - dob2Date.getFullYear();
        let monthsincalcAge = todaycalcAgeInYrsMth.getMonth() - dob2Date.getMonth();
    
        if (monthsincalcAge < 0) {
            yearsincalcAge--;
            monthsincalcAge += 12;
        }
    
        return { yearsincalcAge, monthsincalcAge };
    };

    const handleChildDobChange = (indexfordob, eventfordob, dobformikFieldArray) => {
        const childDob = eventfordob.target.value;
    
        dobformikFieldArray.replace(indexfordob, { 
            ...dobformikFieldArray.form.values.childEntity[indexfordob], 
            child_dob: childDob 
        });
    
        if (childDob) {
            const { yearsincalcAge, monthsincalcAge } = calculateAgeInYearsAndMonths(childDob);
            dobformikFieldArray.replace(indexfordob, {
                ...dobformikFieldArray.form.values.childEntity[indexfordob],
                child_age: `${yearsincalcAge} years and ${monthsincalcAge} months` // Use correct destructured variables
            });
        } else {
            dobformikFieldArray.replace(indexfordob, {
                ...dobformikFieldArray.form.values.childEntity[indexfordob],
                child_age: ''
            });
        }
    };
    
function ClearChildData(){
    healthformIk.setFieldValue("childEntity", []);
}
function ClearParentsData(){
    healthformIk.setFieldValue("father_dob", "");
    healthformIk.setFieldValue("father_uid", "");
    healthformIk.setFieldValue("mother_name", "");
    healthformIk.setFieldValue("mother_dob", "");
    healthformIk.setFieldValue("mother_uid", "");
}

function clearMaritalStatusatHealth() {
    ClearChildData();
    ClearParentsData();
    healthformIk.setFieldValue("is_child", "");
    healthformIk.setFieldValue("Parents", "");
    healthformIk.setFieldValue("spouse_name", "");
    healthformIk.setFieldValue("spouse_dob", "");
    healthformIk.setFieldValue("spouse_uid", "");
}

    function HealthDataReport() {
        let Url = (config.url.COMMON_URL + "healthdatanew/" + logindetails?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res !== null && res !== undefined) {
                if (res?.data?.status === true) {
                    setHealthFormData(res?.data?.healthdatanew);
                    sethealthformReportDatafound("Data Found")
                }
                else{
                    setHealthFormData([])
                    sethealthformReportDatafound("No Data Found")
                }
            }
        })
    }
    useEffect(() => {
        HealthDataReport()
    }, []);
 const deleteAlert = (index, formikFieldArray) => {
        Swal.fire({
            text: "Do you want to delete ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            backdrop: false,
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                formikFieldArray.remove(index);
            }
        })
    };
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Health Insurance Form</li>
                </ol>
            </div>
        </div>
        <jnb.Row className="px-3 mb-2 pt-3" >
            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div class="card">
                    <div class="card-header" style={{ textAlign: "center" }}>Employee Details</div>
                    <div class="card-body">
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>ID:</b>&nbsp;&nbsp;{logindetails?.username}</label>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Name:</b>&nbsp;&nbsp;{logindetails?.employee_name}</label>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <label className="w-100"><b>Division:</b>&nbsp;&nbsp;{logindetails?.wing}</label>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <label className="w-100"> <b>DOJ:</b>&nbsp;&nbsp;{logindetails?.doj}</label>
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                </div>
            </jnb.Col>
        </jnb.Row>
        <FormikProvider value={healthformIk}>
            <Form onSubmit={healthformIk.handleSubmit} onChange={healthformIk.handleChange}>
                {healthformReportDatafound === "Data Found" ? (<>
                <jnb.Row className="px-3 pt-4 mt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Name of the Employee:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="emp_name" className="form-control" maxLength="65" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            <ErrorMessage name="emp_name" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                        <jnb.InputGroup className="mb-4p5">
                            <Field type="hidden" name="emp_age"/>
                            <span className="label-text-style"><b>Employee DOB:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="date" name="emp_dob" className="form-control" onChange={(e)=>{handleDobChange(e,"emp_age")}} max={new Date().toISOString().split("T")[0]} />
                            <ErrorMessage name="emp_dob" component="div" className="text-error" />
                            <span>{healthformIk?.values?.emp_age && `Age: ${healthformIk.values.emp_age}`}</span>
                            {console.log("test age",healthformIk.values.emp_age)}
                        </jnb.InputGroup>

                    </jnb.Col>

                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee Gender:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="emp_gender" value="M" />
                            &nbsp;Male  &nbsp;
                            <Field type="radio" name="emp_gender" value="F" />
                            &nbsp;Female
                            <ErrorMessage name="emp_gender" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee UID<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="emp_aadhar" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }} />
                            <ErrorMessage name="emp_aadhar" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Marital Status<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="marital_status" value="Y"  />
                            &nbsp;Yes  &nbsp;
                            <Field type="radio" name="marital_status" value="N" onChange={(e) => {
                                clearMaritalStatusatHealth()
                            }}/>
                            &nbsp;No
                            <ErrorMessage name="marital_status" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                   
                    
                    {healthformIk?.values?.marital_status === "Y" ? (<>

                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Spouse Name</b></span>
                                <Field type="text" name="spouse_name" className="form-control" maxLength="90" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </jnb.InputGroup>
                        </jnb.Col>

                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Spouse DOB</b></span>
                                <Field type="date" name="spouse_dob" className="form-control" onChange={(e)=>{handleDobChange(e,"spouse_age")}} max={new Date().toISOString().split("T")[0]} />
                            </jnb.InputGroup>
                            <p>{healthformIk?.values?.spouse_age && `Age: ${healthformIk.values.spouse_age}`}</p>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Spouse UID</b></span>
                                <Field type="text" name="spouse_aadhar" maxLength="12" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }}  />
                            </jnb.InputGroup>
                        </jnb.Col>
                    </>) : (<></>)}
                    {healthformIk?.values?.marital_status === "Y" ? (<>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">

                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Children<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                                <Field type="radio" name="is_child" value="Y" 
                                    onChange={(e) => {
                                        healthformIk.setFieldValue("is_child", e.target.value);
                                        healthformIk.setFieldValue("childEntity", [{ child_name: "", child_gender: "", child_dob: "", child_uid: "" }]);
                                    }} 
                                />
                                &nbsp;Yes  &nbsp;
                                <Field type="radio" name="is_child" value="N" 
                                    onChange={(e) => {
                                        healthformIk.setFieldValue("is_child", e.target.value);
                                        ClearChildData();
                                    }} 
                                />
                                &nbsp;No
                            </jnb.InputGroup>
                        </jnb.Col>
                      
                        </>) : (<></>)}
                    {healthformIk?.values?.marital_status === "Y" ? (<>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Parents<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                                <Field type="radio" value="Father/Mother" name="Parents" onChange={(e) => {
                                    ClearParentsData()
                                }} />
                                &nbsp;<b style={{fontSize:"13px"}}>Father/Mother  </b>&nbsp;
                                <Field type="radio" value="Father-in-law/Mother-in-Law" name="Parents"
                                onChange={(e) => {
                                    ClearParentsData()
                                }}  />
                               <b style={{fontSize:"13px"}}>Father-in-law/Mother-in-Law</b>
                            </jnb.InputGroup>
                        </jnb.Col></>) : (<></>)}

                    {(healthformIk?.values?.marital_status === "Y" && healthformIk?.values?.Parents === "Father/Mother")|| healthformIk?.values?.marital_status === "N" ? (<>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father Name</b></span>
                                <Field type="text" name="father_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father DOB</b></span>
                                <Field type="date" name="father_dob" className="form-control" onChange={(e)=>{handleDobChange(e,"father_age")}} max={new Date().toISOString().split("T")[0]} />
                            </jnb.InputGroup>
                            <p>{healthformIk?.values?.father_age && `Age: ${healthformIk.values.father_age}`}</p>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father UID</b></span>
                                <Field type="text" name="father_aadhar" maxLength="12" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }}  />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother Name</b></span>
                                <Field type="text" name="mother_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother DOB</b></span>
                                <Field type="date" name="mother_dob" className="form-control"  onChange={(e)=>{handleDobChange(e,"mother_age")}} max={new Date().toISOString().split("T")[0]} />
                            </jnb.InputGroup>
                            <p>{healthformIk?.values?.mother_age && `Age: ${healthformIk.values.mother_age}`}</p>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother UID</b></span>
                                <Field type="text" name="mother_aadhar" maxLength="12" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }}  />
                            </jnb.InputGroup>
                        </jnb.Col>
                    </>) : (<></>)}
                    {healthformIk?.values?.marital_status === "Y" && healthformIk?.values?.Parents === "Father-in-law/Mother-in-Law" ? (<>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father-in-law Name </b></span>
                                <Field type="text" name="father_in_law_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father-in-law DOB</b></span>
                                <Field type="date" name="father_in_law_dob" className="form-control" onChange={(e)=>{handleDobChange(e,"father_in_law_age")}} max={new Date().toISOString().split("T")[0]} />
                            </jnb.InputGroup>
                            <p>{healthformIk?.values?.father_in_law_age && `Age: ${healthformIk.values.father_in_law_age}`}</p>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Father-in-law UID</b></span>
                                <Field type="text" name="father_in_law_aadhar"maxLength="12" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }}  />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother-in-law Name</b></span>
                                <Field type="text" name="mother_in_law_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother-in-law DOB </b></span>
                                <Field type="date" name="mother_in_law_dob" className="form-control" onChange={(e)=>{handleDobChange(e,"mother_in_law_age")}} max={new Date().toISOString().split("T")[0]} />
                            </jnb.InputGroup>
                            <p>{healthformIk?.values?.mother_in_law_age && `Age: ${healthformIk.values.mother_in_law_age}`}</p>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pt-3">
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Mother-in-law UID</b></span>
                                <Field type="text" name="mother_in_law_aadhar"maxLength="12" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }}  />
                            </jnb.InputGroup>
                        </jnb.Col>
                    </>) : (<></>)}
                    {healthformIk?.values?.marital_status === "Y" && healthformIk?.values?.is_child === "Y" ? (<>
                       
            <table className="table table-condensed table-bordered table-striped table-responsive" style={{ width: "60%" }}>
    <thead>
        <tr>
            <th>SNO</th>
            <th>Child Name </th>
            <th>Gender</th>
            <th>Child DOB</th>
            <th>UID</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <FieldArray name="childEntity">
            {(healthformikFieldArray) => {
                return healthformikFieldArray.form.values.childEntity.slice(0, 5).map((p, childindex) => { 
                    return (
                        <tr key={childindex}>
                            <td>{childindex + 1}</td>
                            <td>
                                <Field type="text" name={`childEntity[${childindex}].child_name`}
                                    className="form-control form-control-sm" maxLength="50" 
                                    onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                            </td>
                            <td>
                                <Field type="radio" name={`childEntity[${childindex}].child_gender`} value="M" />
                                &nbsp;Male&nbsp;
                                <Field type="radio" name={`childEntity[${childindex}].child_gender`} value="F" />
                                &nbsp;Female
                            </td>
                            <td>
                                <Field type="date" name={`childEntity[${childindex}].child_dob`} 
                                    className="form-control" 
                                    onChange={(e) => handleChildDobChange(childindex, e, healthformikFieldArray)}
                                    max={new Date().toISOString().split("T")[0]} />
                                  <span>
                                {healthformikFieldArray.form.values.childEntity[childindex].child_age && `Age: ${healthformikFieldArray.form.values.childEntity[childindex].child_age}`}
                            </span>
                            </td>
                            <td>
                                <Field type="text" className="form-control" 
                                    name={`childEntity[${childindex}].child_uid`} maxLength="12"
                                    onKeyPress={(e) => { allowNumbersOnly(e); }} />
                            </td>
                            <td align="center" className="col-md-1">
                                {(healthformikFieldArray.form.values.childEntity.length > 1) &&
                                    <button type="button" className="button-titile btn btn-sm btn-danger"
                                        onClick={() => { deleteAlert(childindex, healthformikFieldArray); }}>
                                        <BiTrash />
                                    </button>
                                }
                            </td>
                        </tr>
                    );
                });
            }}
        </FieldArray>
        {healthformIk?.values?.childEntity?.length === 5 ? (<></>):(<> 
        <tr>
            <td align="right" colSpan={6} className='pe-3'>
                <FieldArray name="childEntity">
                    {(healthformikFieldArray) => (
                        <>
                            {(healthformikFieldArray.form.values.childEntity.length < 5) && // Display add button only if less than 5 rows
                                <button type="button" className="button-titile btn btn-sm btn-primary me-4"
                                    onClick={() => {
                                        healthformikFieldArray.push({ child_name: "", child_gender: "", child_dob: "", child_uid: "" });
                                    }}>
                                    <VscDiffAdded />
                                </button>
                            }
                        </>
                    )}
                </FieldArray>
            </td>
        </tr>
        </>)}
    </tbody>
</table>

                            
                    </>) : (<></>)}
                </jnb.Row>
                <jnb.Row className="px-3 pt-4">
                    <div className='d-flex flex-row justify-content-end pb-1'>
                        <button type="submit" className="btn rounded-0 btn-success btn-sm">Submit</button>
                    </div>
                </jnb.Row>
                </>) : null}

                {healthformReportDatafound === "Data Found" ? (
                    <div className="border ">
                        < jnb.Row className="px-2 pt-4">
                            <table class="table table-condensed table-bordered table-striped table-responsive"
                                style={{ border: '3px solid lightblue', width: '87%', marginLeft: '55px', borderCollapse: 'collapse', }}>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Relation</th>
                                <th>Name</th>
                                <th>DOB</th>
                                <th>Gender</th>
                                <th>Aadhaar </th>
                            </tr>
                        </thead>
                        <tbody>
                            {GetHealthFormData && GetHealthFormData?.map((healthdata, healthindex) => (
                                            <tr key={healthindex}>
                                                <td>{healthindex+1}</td>
                                                <td>{healthdata.rel_name}</td>
                                                <td>{healthdata.person_name}</td>
                                                <td>{healthdata.dob !== null  ? (moment(healthdata.dob).format('DD-MM-YYYY')) :""}</td>
                                                <td>{ healthdata.gender !== null ?  (healthdata.gender === "F" ? "Female" : "Male") :""}</td>
                                                <td>{healthdata.aadhaar}</td>
                                            </tr>
                                        ))}

                        </tbody>
                        </table></jnb.Row>
                    </div>
                ) :null}
            </Form>
        </FormikProvider>
    </>)
}
