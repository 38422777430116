
import axios from "axios";
export default function getPDFView(empfile) {
    axios("https://swapi.dev.nidhi.apcfss.in/socialwelfaredms/user-defined-path/file-download/" + empfile, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    }).then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }).catch(error => { console.log(error); });


}