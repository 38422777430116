import { FormikProvider, useFormik, Form, Field} from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import Swal from "sweetalert2"
export default function EmployeeLopDetails(){
    const [GetLopDetails, setLopDetails] = useState([])
    const LopDetailsformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            emplopdays: [{
                no_of_days: "",
                one_day_date: "",
                more_than_day_from_date: "",
                more_than_day_to_date:"",
                lop_days:""
            }]
        },
        onSubmit: (values) => {console.log(console.log(null))},
    });
function GetLopData() {
    let Url = (config.url.COMMON_URL + "leavesdatHR");
    CommonAxiosGet(Url).then((resLop) => {
        setLopDetails(resLop?.data?.leavesdataHR);
        for (let lopindex = 0; lopindex <= resLop.data.leavesdataHR.length; lopindex++) {
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].emp_id`, resLop.data.leavesdataHR[lopindex].emp_id);
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].no_of_days`, resLop.data.leavesdataHR[lopindex].no_of_days);
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].one_day_date`, resLop.data.leavesdataHR[lopindex].one_day_date);
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].more_than_day_from_date`, resLop.data.leavesdataHR[lopindex].more_than_day_from_date);
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].more_than_day_to_date`, resLop.data.leavesdataHR[lopindex].more_than_day_to_date);
            LopDetailsformIk.setFieldValue(`emplopdays[${lopindex}].lop_days`, resLop.data.leavesdataHR[lopindex].lop_days);
        }
    })
}
    function EmpLopSave(valuesAtLop) {   
        if (valuesAtLop.no_of_days === "null" || valuesAtLop.no_of_days === "" || valuesAtLop.no_of_days === undefined) {
            Swal.fire("Please select no_of_days","info")
        }
        else if ((valuesAtLop.no_of_days !== "null" || valuesAtLop.no_of_days !== "") && valuesAtLop.no_of_days === "one_day" && valuesAtLop.one_day_date === "") {
            Swal.fire(" date is Required","info")
        }
        else if ((valuesAtLop.no_of_days !== "null" || valuesAtLop.no_of_days !== "") && valuesAtLop.no_of_days === "more_than_day" && valuesAtLop.more_than_day_from_date === "") {
            Swal.fire("From date is Required","info")
        }
        else if ((valuesAtLop.no_of_days !== "null" || valuesAtLop.no_of_days !== "") && valuesAtLop.no_of_days === "more_than_day" && valuesAtLop.more_than_day_to_date === "") {
            Swal.fire("to date is Required","info")
        }
        else if (valuesAtLop.lop_days === "null" || valuesAtLop.lop_days === "" || valuesAtLop.lop_days === undefined) {
            Swal.fire("No.Of lop_days Requirede","info")
        }
    }
    useEffect(() => {
        GetLopData()
    }, []);
    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Report of Leaves and LOP</li>
                </ol>
            </jnb.Col>
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <jnb.Row className="m-0 pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={9}>
                    <div className="head-text" style={{ display: "inline" }}>
                        <h1>Report of Leaves</h1> </div></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
        <jnb.InputGroup>
          <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off" />
        </jnb.InputGroup>
      </jnb.Col>
            </jnb.Row>
            <FormikProvider value={LopDetailsformIk}>
                <Form onSubmit={LopDetailsformIk.handleSubmit} onChange={LopDetailsformIk.handleChange} autoComplete="off"  >
                    <jnb.Row className="px-3">
                         <table className="table  table-bordered  table-responsive">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>S.No</th>
                                    <th rowSpan={2}>ID</th>
                                    <th rowSpan={2}>Name</th>
                                    <th colSpan={2}>Sick Leave</th>
                                    <th colSpan={2}>Casual Leave </th>
                                    <th colSpan={2}>Earned Leave </th>
                                    <th rowSpan={2}>Loss of Pay</th>
                                    <th rowSpan={2}>Upload</th>
                                </tr>
                                <tr>
                                <th>Total </th>
                                <th> Used</th>
                                <th>Total </th>
                                <th> Used</th>
                                <th>Total </th>
                                <th> Used</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                     {GetLopDetails && GetLopDetails?.map((dataempLop, lopEmpindex) => (
                                        <tr key={lopEmpindex}>
                                            <td>{lopEmpindex + 1}</td>
                                            <td>{dataempLop.emp_id}</td>
                                            <td>{dataempLop.emp_name}</td>
                                            <td>{dataempLop?.sl_issued === null ? (<>0</>):(<>{dataempLop?.sl_issued}</>)}</td>
                                            <td>{dataempLop?.sl_used === null ? (<>0</>):(<>{dataempLop?.sl_used}</>)}</td>
                                            <td>{dataempLop?.cl_issued === null ? (<>0</>):(<>{dataempLop?.cl_issued}</>)}</td>
                                            <td>{dataempLop?.cl_used === null ? (<>0</>):(<>{dataempLop?.cl_used}</>)}</td>
                                            <td>{dataempLop?.er_issued === null ? (<>0</>):(<>{dataempLop?.er_issued}</>)}</td>
                                            <td>{dataempLop?.er_used === null ? (<>0</>):(<>{dataempLop?.er_used}</>)}</td>
                                            <td >{dataempLop?.loss_ofpay === null ? (<>
                                                <jnb.Row className="px-3 pt-1">
                                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className='pt-1'>
                                                        <jnb.InputGroup className="mt-4">
                                                            <span className="label-text-style">No.Of Days</span>
                                                            <Field as="select" className='form-control' name={`[emplopdays[${lopEmpindex}].no_of_days`}>
                                                                <option value="">--Select--</option>
                                                                <option value="one_day">One Day</option>
                                                                <option value="more_than_day">More than one day</option>
                                                            </Field>
                                                        </jnb.InputGroup>
                                                    </jnb.Col>
                                                    {LopDetailsformIk?.values?.emplopdays[lopEmpindex]?.no_of_days ==="one_day" ? (<>
                                                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <jnb.InputGroup className="mt-4" >
                                                            <span className="label-text-style"></span>
                                                        <Field type="date" name={`[emplopdays[${lopEmpindex}].one_day_date`} className="form-control mb-2" min={new Date().toISOString().split("T")[0]}/>
                                                        </jnb.InputGroup>
                                                        </jnb.Col>
                                                    </>):null}
                                                    {LopDetailsformIk?.values?.emplopdays[lopEmpindex]?.no_of_days ==="more_than_day" ? (<>
                                                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <jnb.InputGroup className="mt-4" >
                                                            <span className="label-text-style">From Date</span>
                                                        <Field type="date" name={`[emplopdays[${lopEmpindex}].more_than_day_from_date`} className="form-control mb-2" min={new Date().toISOString().split("T")[0]}/>
                                                        </jnb.InputGroup>
                                                        </jnb.Col>
                                                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <jnb.InputGroup className="mt-4" >
                                                            <span className="label-text-style">To Date</span>
                                                            <Field type="date" className="form-control" name={`[emplopdays[${lopEmpindex}].more_than_day_to_date`} ></Field>
                                                        </jnb.InputGroup>
                                                        </jnb.Col>
                                                    </>):null}
                                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <jnb.InputGroup className="mt-4" >
                                                            <span className="label-text-style">No.Of LOP Days</span>
                                                            <Field type="text" className="form-control" name={`[emplopdays[${lopEmpindex}].lop_days`} ></Field>
                                                        </jnb.InputGroup>
                                                        </jnb.Col>
                                                </jnb.Row>
                                            </>):(<>{dataempLop?.loss_ofpay}</>)}</td>
                                            <td> 
                                                <button type="button" className="button-titile btn btn-sm btn-info rounded"
                                                onClick={() => {EmpLopSave(LopDetailsformIk.values.emplopdays[lopEmpindex]);alert("index");}}>
                                                save </button> 
                                            </td>
                                        </tr>
                                         ))}
                            </tbody>
                        </table> 
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}