import { ErrorMessage, Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
// import Header from "../../../Header/Header";
import * as jnb from "react-bootstrap";
import React, { useEffect } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { useState } from "react";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import CommonPost from "../../../CommonUtils/CommonPost";
import Swal from "sweetalert2";
import { BiTrash } from "react-icons/bi";
import { VscDiffAdded } from "react-icons/vsc";
import * as Yup from "yup";
// import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { ContactsOutlined } from "@material-ui/icons";
// import Footer from "../../../Footer/Footer";
export default function Kpsubindicators() {

    const Formvalidation = Yup.object().shape({
        kpid: Yup.string().required("Please Select KP ID").nullable(),
        kp: Yup.array().of(Yup.object().shape({
            kpIndicatorDescription: Yup.string().required("Please Enter KP Descrption").nullable(),
        })
        ),
    });
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            kpid: "",
            kp: [{
                kpId: "",
                kpIndicatorDescription: "",
            }]
        },
        onSubmit: (values) => {
            KPIsubmitAlert(values)
        },
        validationSchema: Formvalidation,
    });
    const KPIsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.kpsubindicatorPost(values.kp)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Updated ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    setSubIndicatorFalg(false);
                                    formIk.setFieldValue("kpid", "")
                                    formIk.setFieldValue("kp", []);
                                    Kpsubindicators();
                                    setReportFalg(false);
                                }
                            })
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showkpIdata, setkpIdata] = useState([]);
    const [showkeyperformanceindicatorsdata, setkeyperformanceindicatorsdata] = useState([]);
    const [showSubIndicatorFlag, setSubIndicatorFalg] = useState(false);
    const [showReportFalg, setReportFalg] = useState(false);
    function GetKeyPerformance() {
        CommonAxiosGet(config.url.COMMON_URL + "keyperformances/Y").then((res) => {
            if (res.data.scode === "01") {
                setkpIdata(res.data.data);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, "warning");
            }
        })
    }
    function Kpsubindicators() {
        CommonAxiosGet(config.url.COMMON_URL + "keyperformanceindicators").then((res) => {
           
            if (res.data.scode === "01") {
                setkeyperformanceindicatorsdata(res.data.data);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, "warning");
            }
        })
    }
    useEffect(() => { 
        GetKeyPerformance();
        Kpsubindicators();
    }, [])
    return (<>
        {/* <Header />
        <div className="main_section">  </div>
        <jnb.Container>
            <jnb.Row >
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="page-titlespacing">
                    <div className="inner-herbpage-service-title1">
                        <h1>HRMS</h1>
                    </div>
                </jnb.Col>
            </jnb.Row>
        </jnb.Container> */}
        {/* <jnb.Container className="outer-page-content-container"> */}
        {/* <MDBBreadcrumb>
            <MDBBreadcrumbItem>
            <Link to="/dashboard">Home</Link>
             
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Key Performance Sub Indicators Mapping</MDBBreadcrumbItem>
        </MDBBreadcrumb> */}
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Key Performance Sub Indicators</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"> <h1> Key Performance Sub Indicators </h1></div>
        <div className="border p-3 pb-0 mb-3">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className="px-0 mb-3 formss">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <span className="label-text mb-2 d-block"><b>Key Performance <font style={{ color: "red" }}>*</font>:</b></span>
                            <jnb.InputGroup className="mb-45">
                                <Field as="select" className='form-control' name="kpid" onChange={(e) => {
                                    setSubIndicatorFalg(true); setReportFalg(true); formIk.setFieldValue("kp[0].kpId", e.target.value)
                                }}>
                                    <option value="">--Select--</option>
                                    {showkpIdata !== undefined && showkpIdata.map((rowdata, i) => {
                                        return (
                                            <option value={rowdata.kpId} key={i}>{rowdata.kpDescription}</option>
                                        )
                                    })}
                                </Field>
                            </jnb.InputGroup>
                            <ErrorMessage name="kpid" component="div" className="text-danger" ></ErrorMessage>
                        </jnb.Col>
                    </jnb.Row>
                    {showSubIndicatorFlag && (<>
                        <div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                {/* style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }} */}
                                {/* width: '85%', marginLeft: '55px', */}
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Key Performance Sub Indicator Description<font style={{ color: "red" }}>*</font></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    <FieldArray name="kp">
                                        {(formikFieldArray) => {
                                            //  eslint-disable-next-line 
                                            const { push } = formikFieldArray;
                                            return (
                                                formikFieldArray.form.values.kp.map(
                                                    (qual, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td> <Field type="text" className="form-control" name={`[kp[${index}].kpIndicatorDescription`} ></Field>
                                                                        <ErrorMessage name={`[kp[${index}].kpIndicatorDescription`} component="div" className="text-danger" ></ErrorMessage>
                                                                    </td>
                                                                    <td width="1"> {(index) ? <>
                                                                        <button type="button" className="button-titile btn btn-sm btn-danger"
                                                                            onClick={() => { formikFieldArray.remove(index); }} >
                                                                            {/* alert((showEmployeeKpIndicatorLength-1)+index),  */}
                                                                            <BiTrash></BiTrash>
                                                                        </button> </> : ''}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                            )
                                        }}
                                    </FieldArray>
                                    <tr className='table-active'>
                                        <td align="right" colSpan={7}>
                                            <FieldArray name="kp">
                                                {(formikFieldArray) => {
                                                    return formikFieldArray.form.values.kp.map(
                                                        (LJP, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {(index === 0) ? <>
                                                                        <button type="button" className="button-titile btn btn-sm btn-success"
                                                                            onClick={() => {
                                                                                formikFieldArray.push({ kpId: formIk.values.kpid, kpIndicatorDescription: "" });
                                                                            }}
                                                                        >
                                                                            <VscDiffAdded></VscDiffAdded> </button> </> : ''}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                }}
                                            </FieldArray>
                                        </td>
                                    </tr>
                                </tbody >
                            </table>



                        </div>
                        <button type="submit" className="btn rounded-0 btn-success btn-sm" style={{ float: "right" }} >Submit</button>
                        {/* <jnb.Row className="px-3 pt-4">
                        
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11}></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                            <button type="submit" className="btn rounded btn-success btn-sm" style={{float:"right"}} >Submit</button>
                        </jnb.Col>
                    </jnb.Row> */}
                    </>
                    )}


                    {showReportFalg && (<>
                        {/* <jnb.Row >
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={1} xxl={1} ></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11} >
                            <h3 style={{ color: "lightskyblue" }}>Report</h3>
                        </jnb.Col>
                    </jnb.Row> */}
                        <legend >Report:</legend>
                        <table className="table table-condensed table-bordered table-striped table-responsive">
                            {/* style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }} */}
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Key Performance Sub Indicator Description</th>
                                    {/* <th>Status</th> */}
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                {showkeyperformanceindicatorsdata !== undefined && showkeyperformanceindicatorsdata.filter((rows) => { return rows.kpId === (formIk.values.kpid === "" ? 0 : parseInt(formIk.values.kpid)) }).map((rowdata, i) => {
                                    return (<React.Fragment key={i}>
                                        <tr>
                                            <td align="center" width="100px">{i + 1}</td>
                                            <td>{rowdata.kpIndicatorDescription}</td>
                                            {/* <td>{rowdata.active_status===true ?"Active":"Deactivated"}</td> */}
                                        </tr>
                                    </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>)}
                </Form>
            </FormikProvider>
        </div>
        {/* <Footer></Footer> */}
        {/* </jnb.Container > */}
    </>)
}
