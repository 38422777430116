import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as jnb from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import Sweetalert from "../../../../CommonUtils/SweetAlerts";
import { config } from "../../../../CommonUtils/CommonApis";
export default function PmsDashboard(){
    const PmsDashboardformIk = useFormik({
        enableReinitialize: true,
        initialValues: {zone_pms: "",},
        onSubmit: (values) => {console.log(null)},
    });
    const navigateAtPms = useNavigate();
    const [reportFlagAtPms, setReportFlagAtPms] = useState('');
    const [showReportStatusAtPms, setReportStatusAtPms] = useState(false);
    const [showEmpReportStatusAtPms, setEmpReportStatusAtPms] = useState(false);
    const [showdashboarddataAtPms, setdashboarddataatPms] = useState([]);
    const [showEmpdataatPms, setEmpdataatPms] = useState([]);
    function loadDashboardReportFirstAtPms(typeAtPms) {
        let Url = (config.url.COMMON_URL + "dashboard/" + typeAtPms + "/" + 0 + "/" + 0);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setdashboarddataatPms(res.data.data);
                setReportStatusAtPms(true);
                setEmpReportStatusAtPms(false)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setReportStatusAtPms(false);
                setEmpReportStatusAtPms(false)
            }
        })
    }
    function dashboardreporttypeAtPms(reporttypeatPms, pmstypeid, pmstype, rodesignationidatPms) {
        let Url = (config.url.COMMON_URL + "dashboard-report/" + reporttypeatPms + "/" + pmstypeid + "/" + pmstype + "/" + rodesignationidatPms);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setEmpdataatPms(res.data.data);
                setEmpReportStatusAtPms(true);
                setReportStatusAtPms(false)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setEmpReportStatusAtPms(false);
                setReportStatusAtPms(false)
            }
        })
    }
    function showEmpKPStatusReport(e, empid, pageflag) {
        navigateAtPms("/pmsempprintform", { state: { empid: empid, pageflag: pageflag } })
    }
    function getcatogorywisedataAtPms(empidAtOms) {
        let Url = (config.url.COMMON_URL + "dashboard/rodesignation/" + empidAtOms);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setReportFlagAtPms('rodesignation');
                setdashboarddataatPms(res.data.data);
                setEmpReportStatusAtPms(false);
                setReportStatusAtPms(true)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setEmpReportStatusAtPms(false);
                setReportStatusAtPms(false)
            }
        })
    }
    function getReportLabelAtPms(reportFlag11) {
        switch (reportFlag11) {
            case "zone":
                return <b>Zone Wise</b>;
            case "designation":
                return <b>Designation Wise</b>;
            case "ro":
                return <b>RO Wise</b>;
            case "rodesignation":
                return (
                    <>
                        <button
                            type="button"
                            className="btn rounded btn-primary btn-sm"
                            onClick={(e) => {
                                setReportFlagAtPms("ro");
                                loadDashboardReportFirstAtPms("ro");
                            }}
                            style={{ marginLeft: '55px' }}>
                            Back
                        </button>
                        <b> RO Designation Wise</b>
                    </>
                );
            default:
                return null;
        }
    }

    function handleLinkClick_ATPMS(id_AtPms, designationId_AtPms = 0) {
        dashboardreporttypeAtPms("total", id_AtPms, reportFlagAtPms, designationId_AtPms);
        setReportStatusAtPms(false);
    }

    function handleCountClick_AtPms(type_AtPms, idAt_Pms, designationId_pms = 0) {
        dashboardreporttypeAtPms(type_AtPms, idAt_Pms, reportFlagAtPms, designationId_pms);
        setReportStatusAtPms(false);
    }

    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Key Performance Abstract Report</li>
                </ol>
            </div>
        </div>
        <FormikProvider value={PmsDashboardformIk}>
            <jnb.Form onSubmit={PmsDashboardformIk.handleSubmit} onChange={PmsDashboardformIk.handleChange}>
                <jnb.Row className="px-4 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}> Select Type:</jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <Field as="select" className="form-control" name="zone_pms" onChange={(e) => { loadDashboardReportFirstAtPms(e.target.value); setReportFlagAtPms(e.target.value); }}>
                                <option value="0">--Select--</option>
                                <option value="zone">Zone</option>
                                <option value="designation">Designation</option>
                                <option value="ro">RO</option>
                            </Field>
                            <ErrorMessage name="zone_pms" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                {showReportStatusAtPms === true ? (<>
                        {showdashboarddataAtPms.length > 0 ? (<>
                            <h4 style={{ fontSize: "20px", marginLeft: "55px" }}>{getReportLabelAtPms(reportFlagAtPms)}&nbsp;</h4>
                            <jnb.Row className="px-3 pt-2">
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan={3}>Sl.No.</th>
                                                <th rowSpan={3}>Catogory</th>
                                                <th rowSpan={3} width="50px" >Total Employee's</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>Self</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>IO</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>RO</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                            </tr>
                                            <tr>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {showdashboarddataAtPms != undefined && showdashboarddataAtPms.map((rowAtPms, i) => {
                                                 const idPmsDashboard = reportFlagAtPms === "zone" ? rowAtPms.zone_id :
                                                 reportFlagAtPms === "designation" ? rowAtPms.designation_id :rowAtPms.ro_emp_id;
                                      const designationIdPmsDashboard = reportFlagAtPms === "rodesignation" ? rowAtPms.designation_id : 0;
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                        {reportFlagAtPms === "ro" ? <a href={() => false} className="atag" onClick={() => { getcatogorywisedataAtPms(rowAtPms.ro_emp_id); setReportStatusAtPms(false); }}>{rowAtPms.category_name} </a> : (rowAtPms.category_name)}
                                                        </td>
                                                        {/* <td>
                                                        <a href={() => false} className="atag" onClick={() => {
                                                                let id;
                                                                if (reportFlagAtPms === "zone") {
                                                                    id = rowAtPms.zone_id;
                                                                } else if (reportFlagAtPms === "designation") {
                                                                    id = rowAtPms.designation_id;
                                                                } else if (reportFlagAtPms === "ro") {
                                                                    id = rowAtPms.ro_emp_id;
                                                                } else {
                                                                    id = rowAtPms.ro_emp_id;}
                                                                let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                dashboardreporttypeAtPms("total", id, reportFlagAtPms, designationId); setReportStatusAtPms(false);
                                                            }}>{rowAtPms.total_count}</a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("SELF_A", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.self_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("SELF_B", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.self_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                            href={() => false}
                                                            className="atag"
                                                            onClick={(e) => {
                                                                let id;

                                                                if (reportFlagAtPms === "zone") {
                                                                    id = rowAtPms.zone_id;
                                                                } else if (reportFlagAtPms === "designation") {
                                                                    id = rowAtPms.designation_id;
                                                                } else if (reportFlagAtPms === "ro") {
                                                                    id = rowAtPms.ro_emp_id;
                                                                } else {
                                                                    id = rowAtPms.ro_emp_id;}

                                                                let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                dashboardreporttypeAtPms("SELF_C", id, reportFlagAtPms, designationId);
                                                                setReportStatusAtPms(false);
                                                            }}>
                                                            {rowAtPms.self_c_rating_count}
                                                        </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("SELF_D", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.self_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("SELF_PENDING", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.self_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("IO_A", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.io_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;

                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                    dashboardreporttypeAtPms("IO_B", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.io_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                    dashboardreporttypeAtPms("IO_C", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.io_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("IO_D", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.io_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}

                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("IO_PENDING", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.io_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                    dashboardreporttypeAtPms("RO_A", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.ro_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                    dashboardreporttypeAtPms("RO_B", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}
                                                            >
                                                                {rowAtPms.ro_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {

                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("RO_C", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>
                                                                {rowAtPms.ro_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {
                                                                        id = rowAtPms.zone_id;
                                                                    } else if (reportFlagAtPms === "designation") {
                                                                        id = rowAtPms.designation_id;
                                                                    } else if (reportFlagAtPms === "ro") {
                                                                        id = rowAtPms.ro_emp_id;
                                                                    } else {
                                                                        id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;

                                                                    dashboardreporttypeAtPms("RO_D", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>{rowAtPms.ro_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                        <a
                                                                href={() => false}
                                                                className="atag"
                                                                onClick={(e) => {
                                                                    let id;
                                                                    if (reportFlagAtPms === "zone") {id = rowAtPms.zone_id;} 
                                                                    else if (reportFlagAtPms === "designation") {id = rowAtPms.designation_id;} 
                                                                    else if (reportFlagAtPms === "ro") {id = rowAtPms.ro_emp_id;}
                                                                     else {id = rowAtPms.ro_emp_id;}
                                                                    let designationId = (reportFlagAtPms === "rodesignation") ? rowAtPms.designation_id : 0;
                                                                    dashboardreporttypeAtPms("RO_PENDING", id, reportFlagAtPms, designationId);
                                                                    setReportStatusAtPms(false);
                                                                }}>{rowAtPms.ro_pending_count}
                                                            </a>
                                                        </td> */}
                                                          <td>
                                                            <a href="#" className="atag" onClick={() => handleLinkClick_ATPMS(idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.total_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("SELF_A", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.self_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("SELF_B", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.self_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("SELF_C", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.self_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("SELF_D", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.self_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("SELF_PENDING", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.self_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("IO_A", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.io_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("IO_B", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.io_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("IO_C", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.io_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("IO_D", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.io_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("IO_PENDING", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.io_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("RO_A", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.ro_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("RO_B", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.ro_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("RO_C", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.ro_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("RO_D", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.ro_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick_AtPms("RO_PENDING", idPmsDashboard, designationIdPmsDashboard)}>
                                                                {rowAtPms.ro_pending_count}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr><td style={{ textAlign: "left" }} colSpan={2}>Total</td>
                                                <td>{showdashboarddataAtPms.reduce((a1, v1) => a1 + v1.total_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a2, v2) => a2 + v2.self_a_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a3, v3) => a3 + v3.self_b_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a4, v4) => a4 + v4.self_c_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a5, v5) => a5 + v5.self_d_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a6, v6) => a6 + v6.self_pending_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a7, v7) => a7 + v7.io_a_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a8, v8) => a8 + v8.io_b_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a9, v9) => a9 + v9.io_c_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a11, v11) => a11 + v11.io_d_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a22, v22) => a22 + v22.io_pending_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a33, v33) => a33 + v33.ro_a_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a44, v44) => a44 + v44.ro_b_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a55, v55) => a55 + v55.ro_c_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a66, v66) => a66 + v66.ro_d_rating_count, 0)}</td>
                                                <td>{showdashboarddataAtPms.reduce((a77, v77) => a77 + v77.ro_pending_count, 0)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Row>
                        </>) : ""}

                    </>
                ) : ("")}

                {showEmpReportStatusAtPms ? (<>
                            <button type="button" className="btn rounded btn-primary btn-sm" onClick={(e) => {
                                setReportStatusAtPms(true); setEmpReportStatusAtPms(false)
                            }} style={{ marginLeft: '55px', }}>Back</button>

                            {showEmpdataatPms.length > 0 ? (
                                <jnb.Row className="px-3 pt-2">
                                    <div className="table-responsive">
                                        <table className="table table-condensed table-bordered table-striped table-responsive"
                                            style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                            <thead>
                                                <tr>
                                                    <th>Sl.No</th>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Designation</th>
                                                    <th>Mobile Number</th>
                                                    <th>EmailId</th>

                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "center" }}>
                                                {showEmpdataatPms != undefined && showEmpdataatPms.map((iodataAtPms, pmsIndex) => {
                                                    return (<React.Fragment key={pmsIndex}>
                                                        <tr >
                                                            <td>{pmsIndex + 1}</td>
                                                            <td><a href={() => false} className="atag" onClick={(e) => { showEmpKPStatusReport(e, iodataAtPms.emp_id, "HR"); }}>{iodataAtPms.emp_id}</a></td>
                                                            <td>{iodataAtPms.emp_name}</td>
                                                            <td>{iodataAtPms.designation_name}</td>
                                                            <td>{iodataAtPms.mobile_no}</td>
                                                            <td>{iodataAtPms.email}</td>
                                                        </tr>
                                                    </React.Fragment>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </jnb.Row>
                            ) : (<center className="pt-4"><b>No Data Found </b></center>)}
                        </>) : ("")}
            </jnb.Form>
        </FormikProvider>
    </>)
}