import { ErrorMessage, Field, Formik, Form } from "formik";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import { config } from "../../../CommonUtils/CommonApis";
import React, { useEffect, useState } from "react";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import * as Yup from "yup";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import GetPreview from "../CommonPages/GetPreview";
import { Link } from "react-router-dom";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_EMPLOYEE } from "../../../CommonUtils/ApplicationRoles";
import Card from 'react-bootstrap/Card';
export default function EmployeeAppraisalForm() {
    const emplogindata = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const [showFormPage, setFormPage] = useState(false);
    const [showEmpInitialValues, setEmpInitialValues] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [showTabFilledCount, setTabFilledCount] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [showEmpData, setEmpData] = useState([]);
    const [showEmpKpData, setEmpKpData] = useState([]);
    const [showEmpKpIndicatorData, setEmpKpIndicatorData] = useState([]);
    const EmpsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let techFlag = Object.keys(values).includes("kp4");
                let techCount = Object.keys(values).includes("kp4") === true ? values["kp4"] : [];
                let techFlagCounts = {};
                techCount.forEach(el => {
                    techFlagCounts[el.rating] = (techFlagCounts[el.rating] || 0) + 1;
                })
                if (techFlag) {
                    if (techCount.length === techFlagCounts["NA"]) {
                        Sweetalert("Please give any one Technical Skill rating is needed, please check it once", 'warning');
                        return false;
                    }
                }
                CommonPost.kpAreasEmployeepost(values, ROLE_EMPLOYEE)
                    .then((res) => {

                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetApprialData();
                                }
                            })
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch((err) => { console.log("err", err) });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    function GetApprialData() {
        let Url = (config.url.COMMON_URL + "employee/appraisal/" + emplogindata?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setEmpData(res.data.employee_data);
                let b = {};
                res.data.employee_kp_data.forEach(el => {
                    b[el.kp_data_flag_status] = (b[el.kp_data_flag_status] || 0) + 1;
                })
                setTabFilledCount(b.true);
                setTabIndex(b.true);
                var result = res.data.employee_kp_data.find(({ kp_data_flag_status }) => !kp_data_flag_status);
                if (result) { result.kp_data_flag_status = true }
                setEmpKpData(res.data.employee_kp_data);
                setEmpKpIndicatorData(res.data.employee_kp_indicator_data)
                for (let i = 0; i < res.data.employee_kp_data.length; i++) {
                    if (res.data.employee_kp_data[i]?.kp_sub_indicator_flag === "Y") {
                        const formikDataYes = res.data.employee_kpstatus_data.filter((testing) => testing.kp_id === res.data.employee_kp_data[i].kp_id)
                        let kpindicatordata = res.data.employee_kp_indicator_data.filter((rowdata) => rowdata.kp_id === res.data.employee_kp_data[i].kp_id);//1,2
                        let currentArray = [];
                        for (var j = 0; j < kpindicatordata.length; j++) {
                            if (formikDataYes.length > 0) {
                                let rowObject = {
                                    rating: formikDataYes[j]?.emp_self_rating?.trim(), kpremarks: formikDataYes[j]?.emp_self_rating_remarks, kpIndicatorFlag: "Y",
                                    kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id, psmappedFlag: kpindicatordata[j]?.mandatory_kp_flag
                                };
                                currentArray.push(rowObject);
                            }
                            else {
                                let rowObject = {
                                    rating: "", kpremarks: "", kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id
                                    , psmappedFlag: kpindicatordata[j]?.mandatory_kp_flag
                                };
                                currentArray.push(rowObject);
                            }
                        }
                        let kpid = res.data.employee_kp_data[i]?.kp_id;
                        setEmpInitialValues((prevState) => ({ ...prevState, ["kp" + kpid]: currentArray }))
                    } else { console.log(null) }
                }

            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    useEffect(() => {
        handleShow();
        GetApprialData();
    }, []);

    useEffect(() => { }, [tabIndex]);

    return (<>
        <jnb.Modal size="xl" dialogClassName="my-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Formik initialValues={{ acceptFlag: false }} enableReinitialize={true}
                onSubmit={(values) => {
                    handleClose();
                    setFormPage(true);
                }}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <jnb.Modal.Header className="modal-header" >
                            <jnb.Modal.Title>  <b style={{ color: "white" }}>HRMS </b></jnb.Modal.Title>
                        </jnb.Modal.Header>
                        <jnb.Modal.Body>
                            <jnb.Card.Body >
                                <div className="table-responsive">
                                    <h4 style={{ fontSize: "16px" }}>General Instructions:</h4>
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <tbody style={{ fontSize: "12px" }}>
                                            <tr><td>1</td>
                                                <td>The Annual Performance Appraisal shall be reviewed by SELF, IMMEDIATE OFFICER and REPORTING OFFICER for the period 1st April 2022 - 31st March 2023.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>All the Employees or the Trainees who become confirmed on or before 15th March of the Performance Appraisal Year shall be
                                                    considered as Eligible Employees for performance appraisal. Employees joining or the Trainees who get confirmed during
                                                    the period from 1st October to 15th March of the year will get a Default C rating and shall be eligible for a pro-rata
                                                    increment for the number of months worked in that appraisal year.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>The Key Result Areas shall be carried out for measuring the individual performance / achievement in 6 perspectives with
                                                    total weightage of 100 marks -  1. Customer / Project Management (Weightage 25) / 2. Internal Process Improvement (Weightage 25) /
                                                    3. Learning and Personal Development ( Weightage 20) /
                                                    4. Technical Skills (Weightage 15) and 5. Behavioural Skills (Weightage 15).
                                                    The references are attached under each perspective for ease of reference.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>The annual appraisal proforma is formula enabled and scores shall  automatically populate based on the inputs entered.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Employees, if need be, may adjust the weightage score only for the perspective - "Customer / Project Management"
                                                    and "Internal Process Improvement" basing  on the priority and importance of the functions / activities carried out by the
                                                    respective team members during the year 2022-2023 and other perspectives from 4 to 6 are fixed and cannot be modified.
                                                    Nevertheless, it shall be ensured that the total weightage score shall be completed against 100.</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Employees who are working on Contractual Basis or termed as Consultant are not eligible for
                                                    Annual Appraisal Process for the year 2022-23. </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>The following process shall be followed for effective method of finalizing the rating scale to avail Annual Increment for
                                                    the year 2022-2023.<br />a) Employee should discuss with Immediate officer / Reporting officer to fill up the Key result area under different perspectives with weightage of marks, whichever applicable
                                                    <br />to the Designated Role of the respective functions together with Functional and Behavioural Skills. <br />
                                                    b) An employee shall initiate his /her assessment against each KRA by mentioning the activities carried out during the period and assigning self-rating together with remarks.
                                                    <br /> The employee scores will sum up to an average under each perspective. <br />
                                                    c) The Immediate Officer upon receipt of ratings from the concerned employee shall review the Employees’ self-rating together with remarks and submit the
                                                    <br />IO rating together with remarks for each perspective. The IO scores will sum up to an average of each perspective. <br />
                                                    d) The employee rating and IO rating will be calculated based on the following range
                                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                                        style={{ border: '1px solid', width: '50%', fontSize: "10px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Rating Scale</th>
                                                                <th>Definition</th>
                                                                <th>% of Achievement of the KRA</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ textAlign: "center" }}>
                                                            <tr><td>1</td>
                                                                <td>A</td>
                                                                <td>Exceptional Performance</td>
                                                                <td>More than 90% and up to 100%</td></tr>
                                                            <tr><td>2</td>
                                                                <td>B</td>
                                                                <td>Very Good Performance</td>
                                                                <td>More than 80% and up to 90%</td></tr>
                                                            <tr><td>3</td>
                                                                <td>C</td>
                                                                <td>Fair Performance</td>
                                                                <td>More than 60% and up to 80%</td></tr>
                                                            <tr><td>4</td>
                                                                <td>D</td>
                                                                <td>Under Performance</td>
                                                                <td>Less than 60%</td></tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>In case of any queries, please contact your Project Manager / HR team </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Field type="checkbox" name="acceptFlag" />&nbsp;I Accept all
                            </jnb.Card.Body>
                        </jnb.Modal.Body>
                        <jnb.Modal.Footer> <div className="d-grid">
                            {props.values.acceptFlag === true && (<button type="submit" className="btn btn-success">Proceed</button>)}
                        </div>
                        </jnb.Modal.Footer>
                    </Form>)}
            </Formik>
        </jnb.Modal>
        {showFormPage && (
            <>
                <div className="card">
                    <div className="card-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/homepage">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                        </ol>
                    </div>
                </div>
                <div className="head-text mt-4"> <h1> Appraisal Form </h1></div>
                <div className="  mb-3" >
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  > {showEmpData[0]?.emp_name}({showEmpData[0]?.emp_id})</Card.Title>
                                    <Card.Text>
                                        Name (ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  >{showEmpData[0]?.doj?.split("-")?.reverse()?.join("/")}</Card.Title>
                                    <Card.Text>
                                        Date of Joining
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  >{showEmpData[0]?.zone_name}</Card.Title>
                                    <Card.Text>
                                        Wing
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-success text-white">
                                <Card.Body>
                                    <Card.Title>{showEmpData[0]?.io_emp_name}({showEmpData[0]?.io_emp_id})</Card.Title>
                                    <Card.Text>
                                        IO Name(ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-primary text-white">
                                <Card.Body>
                                    <Card.Title  > {showEmpData[0]?.ro_emp_name}({showEmpData[0]?.ro_emp_id}) </Card.Title>
                                    <Card.Text>
                                        RO Name(ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-secondary text-white">
                                <Card.Body>
                                    <Card.Title  > {showEmpData[0]?.designation_name} </Card.Title>
                                    <Card.Text>
                                        Designation
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                    </jnb.Row>
                    <br />
                </div>
                <Tabs onSelect={(index) => setTabIndex(index)} style={{ fontSize: "12px" }} >
                    <TabList   >
                        {showEmpKpData !== undefined && showEmpKpData.map((row, i) => {
                            return (<React.Fragment key={i}>
                                <Tab key={i} disabled={row.kp_data_flag_status === true ? false : true} >{row.kp_short_description} </Tab>
                            </React.Fragment>)
                        })}
                        <Tab disabled={showTabFilledCount === showEmpKpData.length ? false : true}>Preview</Tab>
                    </TabList>
                    <div className="border p-3 pb-0 mb-3">
                        {showEmpKpData !== undefined && showEmpKpData.map((row, i) => {
                            return (<React.Fragment key={i}>
                                <TabPanel>
                                    {row.kp_sub_indicator_flag === "Y" ? (
                                        <React.Fragment>
                                            <Formik initialValues={
                                                {
                                                    empId: emplogindata?.username,
                                                    userId: emplogindata?.username,
                                                    [`kp${row.kp_id}`]: showEmpInitialValues[`kp${row.kp_id}`],
                                                }
                                            }
                                                validationSchema={Yup.object().shape({
                                                    [`kp${row.kp_id}`]: Yup.array().of(Yup.object().shape({
                                                        kpremarks: Yup.string().required("Please Enter Remarks").trim().min(4, 'Minimum 4 Character required').max(500, 'Maximum 500 Characters allowed').nullable(),
                                                        rating: Yup
                                                            .string()
                                                            .when('psmappedFlag', {
                                                                is: (psmappedFlag) => {
                                                                    return psmappedFlag;
                                                                },
                                                                then: Yup.string()
                                                                    .test('psmappedFlag', 'Please Select Rating, skill has already mapped as primary, it should not be empty and NA', (value) => {
                                                                        return value !== 'NA' && value?.trim() !== '' && value !== undefined;
                                                                    }),
                                                                otherwise: Yup.string().required("Please Select Rating").nullable(),
                                                            })
                                                    }),
                                                    ),
                                                })}
                                                onSubmit={(values) => { EmpsubmitAlert(values); }}>
                                                {props => (
                                                    <Form onSubmit={props.handleSubmit}>
                                                        <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                                                        {(showEmpKpIndicatorData[0]?.ps_mapped_count === 0 && row.kp_id === 4) ? (<><br /><br /><span><b style={{ color: "red", fontSize: "large" }}>Your Primary Skill(Technical Skills) has to be give/update by your Reporting Officer.<br /><br />Please contact your Reporting Officer.</b></span><br /><br /></>) : (<>
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered table-striped table-responsive">
                                                                    <thead>
                                                                        <tr>
                                                                            <th align="center" width="1">S.No</th>
                                                                            <th width="150" >Key Performance Areas Description</th>
                                                                            <th width="50">Rating <font style={{ color: "red" }}>*</font></th>
                                                                            <th width="50">Remarks <font style={{ color: "red" }}>*</font></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ textAlign: "left" }}>
                                                                        {
                                                                            showEmpKpIndicatorData !== undefined && showEmpKpIndicatorData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                                                                return (
                                                                                    <React.Fragment key={k}>
                                                                                        <tr>
                                                                                            <td >{k + 1}
                                                                                            </td>
                                                                                            <td> {kpidata.kp_indicator_description}
                                                                                                {kpidata.mandatory_kp_flag === true ? <font style={{ color: "red" }}>*</font> : ""}
                                                                                            </td>
                                                                                            <td width="50" >
                                                                                                <Field as="select" className='form-control' name={`kp${row.kp_id}[${k}].rating`}>
                                                                                                    <option value="">--Select--</option>
                                                                                                    <option value="A">A</option>
                                                                                                    <option value="B">B</option>
                                                                                                    <option value="C">C</option>
                                                                                                    <option value="D">D</option>
                                                                                                    {row.kp_description === "Technical Skills" && (<><option value="NA">NA</option></>)}
                                                                                                </Field>
                                                                                                <ErrorMessage name={`kp${row.kp_id}[${k}].rating`} component="div" className="text-danger" ></ErrorMessage>
                                                                                            </td>
                                                                                            <td >
                                                                                                <Field as="textarea" className="form-control" name={`kp${row.kp_id}[${k}].kpremarks`} maxLength="500"></Field>
                                                                                                <ErrorMessage name={`kp${row.kp_id}[${k}].kpremarks`} component="div" className="text-danger" ></ErrorMessage></td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                {showEmpData[0]?.self_confirmation_status ? null : (
                                                                    <div className="text-end mb-3">
                                                                        <button type="submit" className="btn rounded-0 btn-success btn-sm">Save & continue</button>
                                                                    </div>)}
                                                            </div>
                                                        </>)}
                                                    </Form>
                                                )}
                                            </Formik>
                                        </React.Fragment>
                                    ) : (<>
                                    </>)}
                                </TabPanel>
                            </React.Fragment>)
                        })}
                        <TabPanel>
                            <GetPreview previewempid={emplogindata?.username} type={ROLE_EMPLOYEE} />
                        </TabPanel>
                    </div>
                </Tabs >
            </>
        )
        }
    </>)
}
